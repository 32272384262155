import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { getLastUpdatedDate, adjustHexBrightness, adjustHSLABrightness  } from "../../utils";
import interpolatePuOr from "../../../assets/interpolatePuOr.png";


export function qualityReferences(input: string, global: any = null) {

  if (input === "Wise") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        WISE Water Framework Directive Database
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        The WISE Water Framework Directive database contains data from the River Basin Management Plans reported by EU Members States, Iceland, Norway and the United Kingdom according to article 13 of the Water Framework Directive (WFD). The database includes information about surface water bodies (number and size, water body category, ecological status or potential, chemical status, significant pressures and impacts, and exemptions) and about groundwater bodies (number and size, quantitative status, chemical status, significant pressures and impacts, and exemptions). The information is presented by country, river basin district (RBD) and river basin district sub-unit (where applicable).{'<'}100 ft depth) groundwater observation wells at each site, from which a subset of four wells are sampled.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://www.eea.europa.eu/en/datahub/datahubitem-view/dc1b1cdf-5fa0-4535-8c89-10cc051e00db"} target={"_blank"}>
            {" "}
            WISE Water Framework Directive Database (europa.eu)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "HBA") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        African Hydrobasins
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        HydroBASINS represents a series of vectorized polygon layers that depict sub-basin boundaries at a global scale. This layer provides a seamless global coverage of consistently sized and hierarchically nested sub-basins at different scales (from tens to millions of square kilometers), supported by a coding scheme that allows for analysis of catchment topology such as up- and downstream connectivity.{'<'}100 ft depth) groundwater observation wells at each site, from which a subset of four wells are sampled.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://www.hydrosheds.org/products/hydrobasins"} target={"_blank"}>
            {" "}
            HydroBASINS (hydrosheds.org)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "GGDE") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Global Groundwater Dependent Ecosystems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Source Description: The teal areas on this map show the likely locations of groundwater dependent ecosystems (GDEs) in 
        dry climate zones across the globe, while the brown areas are not likely GDEs. GDEs are important because they are often 
        biodiversity hotspots that support rare and endemic species, but also hold cultural and socio-economic significance to people. 
        These ecosystems rely on groundwater and can be lost if groundwater is not managed sustainably.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `#018571`, opacity: global.USGSWUCOpacity }}
                />
              </td>
              <td> {" - "} Likely GDE</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `#A6611A`, opacity: global.USGSWUCOpacity }}
                />
              </td>
              <td> {" - "} Not GDE</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `#C6C6C6`, opacity: global.USGSWUCOpacity }}
                />
              </td>
              <td> {" - "} Not Analyzed</td>
            </tr>
            <p style={{ fontSize: "12px", marginBottom: "0.5rem", marginTop: "0.5rem" }}>
            GDE Certainty
            </p>
            <tr>
              <div className="gradient-wrapper">
                <div className="gradient-rectangle"></div>
                <div className="metric-box">
                  <strong>0%</strong>
                  <strong>50%</strong>
                  <strong>100%</strong>
                </div>
            </div>
            </tr>
          </tbody>
        </table>
        <hr/>
        <h5 className="legend-source">
          Source:
          <a href={"https://codefornature.projects.earthengine.app/view/global-gde"} target={"_blank"}>
            {" "}
            The Nature Conservancy and the Desert Research Institute. Global Groundwater Dependent Ecosystem Map, Version 1.2.0. August 2024
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "MEC" || input === "MECReportMounted") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Manuels Branch E.coli Trends
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Source Description: Manuel Branch is a 2.5 mile stream.  This waterbody is impaired according to the Florida Dept. of Environmental Protection's (FDEP) implementation of the Impaired Waters Rule (IWR). The FDEP evaluates whether waters meet their designated uses, which include aquatic life use support, primary contact and recreation use support, fish and shellfish consumption use support, and drinking water use support.  This dataset is looking at E.coli values over time.  
        </p>
        <h5 className="legend-source">
          Source:
          <a href={"https://chnep.wateratlas.usf.edu/waterbodies/rivers/9000183/"} target={"_blank"}>
            {" "}
            Manuel Branch | River Details - CHNEP.WaterAtlas.org (usf.edu)
          </a>
          {"  &  "}
          <a href={"https://calusawaterkeeper.org/"} target={"_blank"}>
            Calusa Waterkeeper
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "NSG") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Neon Science Groundwater Layer
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Source Description: Grab samples of groundwater chemistry including general chemistry, anions, cations, and nutrients.  Grab samples of groundwater are collected twice per year at NEON aquatic sites from permanently installed groundwater wells. There are up to eight small (2-in diameter) shallow ({'<'}100 ft depth) groundwater observation wells at each site, from which a subset of four wells are sampled.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://data.neonscience.org/data-products/DP1.20092.001"} target={"_blank"}>
            {" "}
            NEON | Chemical properties of groundwater (neonscience.org)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "MBFIBDT" || input === "FIBReportMounted") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Name: Manuel's Branch FIB Sampling Data and Trends
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Fecal Indicator Bacteria Samples for Manuel's Branch  
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://calusawaterkeeper.org/"} target={"_blank"}>
            {" "}
            Southwest Florida Water Quality Monitoring - Calusa Waterkeeper
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "FBFIB report") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Calusa Waterkeeper: Fecal Indicator Bacteria (FIB) Sampling 
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        This layer includes Calusa Waterkeeper test sites and publicly available data from the Florida Department of Health (FDOH).  
        It should be noted that the data is not measuring or observing fecal matter in the water. These tests measure for fecal indicator bacteria, 
        specifically enterococci. Bacteria sampling results show occurrences of serious contamination.   
        </p>
        <table id="qiTable">
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: '#92D051' }}
                />
              </td>
              <td> {"   "} Samples between 0 - 35 MPN are good</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: '#FEFF00' }}
                />
              </td>
              <td> {"   "} Samples between 36 - 70 MPN are moderate</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: '#FF0000' }}
                />
              </td>
              <td> {"   "} Samples between 71 - 250 MPN are poor</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: '#CB019A' }}
                />
              </td>
              <td> {"   "} Samples over 251 MPN are extremely poor</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://calusawaterkeeper.org/issues/bacteria-monitoring/"} target={"_blank"}>
            {" "}
            Bacteria Monitoring - Calusa Waterkeeper
          </a>
        </h5>
        <hr />
      </div>
    );
  }


  if (input === "OWQPDemo") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Oregon Water Quality Portal
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Sample Well Data for the Umatilla Region of Oregon
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://www.oregon.gov/deq/wq/pages/owdp.aspx"} target={"_blank"}>
            {" "}
            Department of Biological & Ecological Engineering at the College of Agricultural Sciences from Oregon State University
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "FBFIB") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Calusa Waterkeeper: Fecal Indicator Bacteria (FIB) Sampling 
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        This layer includes Calusa Waterkeeper test sites and publicly available data from the Florida Department of Health (FDOH).  
        It should be noted that the data is not measuring or observing fecal matter in the water. These tests measure for fecal indicator bacteria, 
        specifically enterococci. Bacteria sampling results show occurrences of serious contamination.   
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://calusawaterkeeper.org/issues/bacteria-monitoring/"} target={"_blank"}>
            {" "}
            Bacteria Monitoring - Calusa Waterkeeper
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "FHBP") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Florida Healthy Beaches Program
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        This layer displays information from beach samples about the results from routine bacterial monitoring.    
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://www.floridahealth.gov/environmental-health/beach-water-quality/county-detail.html?County=Collier&Zip=34112-4961"} target={"_blank"}>
            {" "}
            Florida Healthy Beaches Program | Florida Department of Health
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "CWALayer") {
    return (
      <div className="quality-references">
        <h5>Lake Erie Water Data collected from near shore weather stations and in-situ buoys</h5>
        <table id="qiTable">
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FCA502", global.CWALayerBrightness)}`, opacity: global.CWALayerOpacity }}
                />
              </td>
              <td> {" - "} Bouy</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FF4433", global.CWALayerBrightness)}`, opacity: global.CWALayerOpacity }}
                />
              </td>
              <td> {" - "} Shoreline Sensor</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Data courtesy of Cleveland Water Alliance
          <a href={"https://www.clevelandwateralliance.org/smart-lake-erie-watershed-initiative"} target={"_blank"}>
            {" "}
            Smart Lake Erie Watershed Initiative.
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if(input === "CEJST") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Climate and Economic Justice Screening (CJST) Current as of Nov 22nd,  2022
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        The tool uses census tracts. Census tracts are a small unit of geography. They generally have populations a people icon informing the user 
        of communities of between 1,200 - 8,000 people. Communities that are disadvantaged live in tracts that experience burdens. 
        The tool highlights disadvantaged census tracts across all 50 states, the District of Columbia, and the U.S. territories. Communities are considered disadvantaged:
          -If they are in census tracts that meet the thresholds for at least one of the tool’s categories of burden, or
          -If they are on land within the boundaries of Federally Recognized Tribes
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        At risk tracts are flagged below, 90th percentile and above
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#00FFFF", global.USGSHuc12Brightness)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" - "} 90th (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#008000", global.USGSHuc12Brightness)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" - "} 91st (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#00FF00", global.USGSHuc12Brightness)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" - "} 92nd (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ADFF2F", global.USGSHuc12Brightness)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" - "} 93rd (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#32CD32", global.USGSHuc12Brightness)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" - "} 94th (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#7FFF00", global.USGSHuc12Brightness)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" - "} 95th (Percentile)</td>
            </tr>
           <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFFF00", global.CEJSTBrightness)}`, opacity: global.CEJSTOpacity }}
                />
              </td>
              <td> {" - "} 96th (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFD700", global.CEJSTBrightness)}`, opacity: global.CEJSTOpacity }}
                />
              </td>
              <td> {" > "} 97th (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFA500", global.CEJSTBrightness)}`, opacity: global.CEJSTOpacity }}
                />
              </td>
              <td> {" > "} 98th (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FF4500", global.CEJSTBrightness)}`, opacity: global.CEJSTOpacity }}
                />
              </td>
              <td> {" > "} 99th (Percentile)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FF0000", global.CEJSTBrightness)}`, opacity: global.CEJSTOpacity }}
                />
              </td>
              <td> {" > "} 100th (Percentile)</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:
          <a href={"https://screeningtool.geoplatform.gov/en/#4.72/38.99/-91.12"} target={"_blank"}>
            {" "}
            Climate and Economic Justice Screening
          </a>
        </h5>
        <hr />
      </div>
    );
  
  }

  if (input === "apple") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Apple Organization Location Data
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Sites of various Apple Organization Locations, to include Data Centers.   
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={""} target={"_blank"}>
            {" "}
            Apple
          </a>
        </h5>
        <hr />
      </div>
    );
  }
                          
  if (input === "SkyFiWhiting" || input === "SkyFiAustin") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Skyfi Sample Layer
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        SkyFi is a leading provider of Earth observation data, empowering individuals and businesses with seamless access to near real-time visual insights.  Sample Satellite Image Layers include 30cm Resolution Austin TX or 1m Resolution Whiting IN
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://www.skyfi.com/"} target={"_blank"}>
            {" "}
            Earth Observation, Made Easy | SkyFi
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "USGSHuc12") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        USGS Monthly Public Water Usage (By HUC12) - 2016-2020
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        This water-use reanalysis supports the Water Availability and Use Science Program goals of determining the quantity and quality of water that is available for human and ecological uses, now and in the future and helps to identify where and when the Nation may have challenges meeting its demand for water because of insufficient water quantity or quality. National data, consistently estimated, that accounts for water removed (withdrawal) and consumed, respectively, from a groundwater or surface-water source provides needed information of how water is used for public supply and can be used to evaluate the balance between supply and demand. This data release contains data used in a machine learning model to estimate monthly water use for communities that are supplied by public-supply water systems in the conterminous United States for 2000-2020. The scale below represents water usage in Million Gallons per Day (MGD).
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3366CC", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 0.05 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3255c9", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 0.1 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3425db", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 0.5 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#8A2BE2", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 1 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#9932CC", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 5 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#800080", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 10 (MGD)</td>
            </tr>
           <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#9400D3", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 15 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#d400ff", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 20 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#00FF00", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 30 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#80FF00", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 60 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFFF00", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 90 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FF8000", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 120 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FF0000", global.USGSHuc12Brightness, 10)}`, opacity: global.USGSHuc12Opacity }}
                />
              </td>
              <td> {" > "} 150 (MGD)</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:
          <a href={"https://www.usgs.gov/data/public-supply-water-use-reanalysis-2000-2020-period-huc12-month-and-year-conterminous-united"} target={"_blank"}>
            {" "}
            Public supply water use reanalysis for the 2000-2020 period by HUC12, month, and year for the conterminous United States | U.S. Geological Survey (usgs.gov)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "USGSWUC") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        USGS Monthly Public Water Usage (MGD) by City 2016-2020
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        This water-use reanalysis supports the Water Availability and Use Science Program goals of determining the quantity and quality of water that is available for human and ecological uses, now and in the future and helps to identify where and when the Nation may have challenges meeting its demand for water because of insufficient water quantity or quality. National data, consistently estimated, that accounts for water removed (withdrawal) and consumed, respectively, from a groundwater or surface-water source provides needed information of how water is used for public supply and can be used to evaluate the balance between supply and demand.
        This data release contains data used in a machine learning model to estimate monthly water use for communities that are supplied by public-supply water systems in the conterminous United States for 2000-2020.
        The scale below represents water usage in Million Gallons per Day (MGD).
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#00FF00", global.USGSWUCBrightness)}`, opacity: global.USGSWUCOpacity }}
                />
              </td>
              <td> {" - "} 50-200 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#80FF00", global.USGSWUCBrightness)}`, opacity: global.USGSWUCOpacity }}
                />
              </td>
              <td> {" - "} 250-400 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFFF00", global.USGSWUCBrightness)}`, opacity: global.USGSWUCOpacity }}
                />
              </td>
              <td> {" - "} 450-650 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FF8000", global.USGSWUCBrightness)}`, opacity: global.USGSWUCOpacity }}
                />
              </td>
              <td> {" - "} 700-900 (MGD)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness)}`, opacity: global.USGSWUCOpacity }}
                />
              </td>
              <td> {" - "}  {'> '}950 (MGD)</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:
          <a href={"https://www.usgs.gov/data/public-supply-water-use-reanalysis-2000-2020-period-huc12-month-and-year-conterminous-united"} target={"_blank"}>
            {" "}
            Public supply water use reanalysis for the 2016-2020 period by HUC12, month, and year for the conterminous United States | U.S. Geological Survey (usgs.gov)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABSCA") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Canadian Stream Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major Stream Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABSPK") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Pakistan Stream Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major Stream Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABSSG") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Singapre Stream Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major Stream Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABSID") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Indonesian Stream Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major Stream Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABSPH") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Philippine Stream Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major Stream Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABRSG") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Singapore River Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major River Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABRMX") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Mexican River Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major River Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABRCA") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Canadian River Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
           Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major River Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABRPK") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Pakistan River Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
           Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major River Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABRPH") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Philippines River Systems
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
           Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major River Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABRID") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Indonesia River Systems 
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
           Reliable, easy-to-use, and interoperable open map data.  This layer displays land use data in the form of Major River Systems for entire Nation.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OGWTD") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
        Overture Global Water Treatment Data
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
        Reliable, easy-to-use, and interoperable open map data.  This layer displays point based water treatment equipment and service lines for the enitre globe.  
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }
  if (input === "OABID") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Overture Administrative Boundaries for Indonesia
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Reliable, easy-to-use, and interoperable open
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }
  if (input === "OABUS") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Overture Administrative Boundaries for the United States
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Reliable, easy-to-use, and interoperable open
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABPH") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Overture Administrative Boundaries for the Philippines
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Reliable, easy-to-use, and interoperable open
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "OABMX") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Overture Administrative Boundaries for Mexico
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Reliable, easy-to-use, and interoperable open
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a href={"https://overturemaps.org/"} target={"_blank"}>
            {" "}
            Overture Maps Foundation – Linux Foundation Project
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "WaterConflict") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Water Conflict
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Water resources and water infrastructure are not only triggers of
          conflict—they can be a casualties of conflict or used as weapons as
          well. In regions where water scarcity is becoming an ever-increasing
          concern, water may become a driver of conflict and violence. And in
          parts of the world where violent conflict is occurring, vital water
          infrastructure can be damaged or destroyed, in some cases
          purposefully, to cause harm to the opposing side. In response to these
          critical issues, the Pacific Institute is leading the effort to
          identify, track, and analyze water-related conflict from around the
          world.
        </p>
        <br />
        <h5 className="legend-source">
          Source:
          <a
            href={"https://pacinst.org/water-conflict-chronology/"}
            target={"_blank"}
          >
            {" "}
            Water Conflict Chronology - Pacific Institute (pacinst.org)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "GBIF") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Global Biodiversity Information Facility (GBIF)
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Source Description for Legend: GBIF is an international network and
          data infrastructure funded by the world's governments, providing
          global data that document the occurrence of species. GBIF currently
          integrates datasets documenting over 1.6 billion species occurrences.
          The GBIF occurrence dataset combines data from a wide array of
          sources, including specimen-related data from natural history museums,
          observations from citizen science networks, and automated
          environmental surveys.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#440154", global.GBIFBrightness)}` }}
                />
              </td>
              <td> {" > "} 1 - 341</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3B528B", global.GBIFBrightness)}` }}
                />
              </td>
              <td> {" > "} 341 - 1167</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#22918C", global.GBIFBrightness)}` }}
                />
              </td>
              <td> {" > "} 1167 - 4986</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#5EC962", global.GBIFBrightness)}` }}
                />
              </td>
              <td> {"  "} 4986 - 4986</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FCE725", global.GBIFBrightness)}` }}
                />
              </td>
              <td> {"  "} 10292 - 12248</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 className="legend-source">
          Source:
          <a
            href={"https://planetarycomputer.microsoft.com/dataset/gbif"}
            target={"_blank"}
          >
            {" "}
            Global Biodiversity Information Facility (GBIF) | Planetary Computer
            (microsoft.com)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "MoBiPI") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Map of Biodiversity Importance (MoBi) - Species Richness for
          Pollinators
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Selected pollinators include bumblebees, butterflies, and skippers.
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          This MoBI layer is a raster map that combine habitat information for
          2,216 imperiled species occurring in the conterminous United States,
          using weightings based on range size and degree of protection to
          identify areas of high importance for biodiversity conservation. This
          layer is intended to identify areas of high potential value for
          on-the-ground biodiversity protection efforts. As a synthesis of
          predictive models, they cannot guarantee either the presence or
          absence of imperiled species at a given location. These data layers 
          are intended to identify areas of high potential value for on-the-ground 
          biodiversity protection efforts. As a synthesis of predictive models, 
          they cannot guarantee either the presence or absence of imperiled species 
          at a given location
        </p>
        <br />
        <div className="scale-container scale-container-mobi">
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "8px",
            }}
          >
            Most recent (2020) | Species Richness for Pollinators
          </p>
          <div className="span-wrapper">
            <div
              className="cvi-scale mobi-scale"
              style={{
                background:
                  "linear-gradient(to right, rgb(51, 51, 153), rgb(1, 204, 102), rgb(254, 254, 152),rgb(129, 94, 86), rgb(255, 255, 255))",
              }}
            ></div>
          </div>
          <div className="mobi-scale-num">
            <p>0</p>
            <p>2</p>
            <p>4</p>
          </div>
        </div>
        <br />
        <h5 className="legend-source">
          Source:
          <a
            href={"https://planetarycomputer.microsoft.com/dataset/mobi"}
            target={"_blank"}
          >
            {" "}
            MoBI: Map of Biodiversity Importance | Planetary Computer
            (microsoft.com)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "MoBiSRA") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Map of Biodiversity Importance (MoBi) - Species Richness for Aquatic
          Invertebrates
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Includes selected aquatic invertebrates such as freshwater mussels and
          crayfish.
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          This MoBI layer is a raster map that combine habitat information for
          2,216 imperiled species occurring in the conterminous United States,
          using weightings based on range size and degree of protection to
          identify areas of high importance for biodiversity conservation. This
          layer is intended to identify areas of high potential value for
          on-the-ground biodiversity protection efforts. As a synthesis of
          predictive models, they cannot guarantee either the presence or
          absence of imperiled species at a given location. These data layers 
          are intended to identify areas of high potential value for on-the-ground 
          biodiversity protection efforts. As a synthesis of predictive models, 
          they cannot guarantee either the presence or absence of imperiled species 
          at a given location
        </p>
        <br />
        <div className="scale-container scale-container-mobi">
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "8px",
            }}
          >
            Most recent (2020) | Species Richness for Aquatic Invertebrates
          </p>
          <div className="span-wrapper">
            <div
              className="cvi-scale mobi-scale"
              style={{
                background:
                  "linear-gradient(to right, rgb(51, 51, 153), rgb(34, 86, 188), rgb(16, 120, 222),rgb(0, 156, 222), rgb(0, 182, 166), rgb(13,207,104), rgb(65,217,115), rgb(121, 228,126), rgb(173,239,137), rgb(229,250,148), rgb(242,238,146), rgb(214,203,131), rgb(188,169,117),rgb(160,133,102), rgb(134,100,88), rgb(149,119,113), rgb(175,153,148), rgb(203,188,185),rgb(229,222,220), rgb(255,255,255))",
              }}
            ></div>
          </div>
          <div className="mobi-scale-num">
            <p>0</p>
            <p>9.5</p>
            <p>19</p>
          </div>
        </div>
        <br />
        <h5 className="legend-source">
          Source:
          <a
            href={"https://planetarycomputer.microsoft.com/dataset/mobi"}
            target={"_blank"}
          >
            {" "}
            MoBI: Map of Biodiversity Importance | Planetary Computer
            (microsoft.com)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "MoBiV") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Map of Biodiversity Importance (MoBi) - Vertebrates Species Richness
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Vertebrates include birds, mammals, amphibians, reptiles, turtles,
          crocodilians, and freshwater and anadromous fishes.
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          This MoBI layer is a raster map that combine habitat information for
          2,216 imperiled species occurring in the conterminous United States,
          using weightings based on range size and degree of protection to
          identify areas of high importance for biodiversity conservation. This
          layer is intended to identify areas of high potential value for
          on-the-ground biodiversity protection efforts. As a synthesis of
          predictive models, they cannot guarantee either the presence or
          absence of imperiled species at a given location. These data layers 
          are intended to identify areas of high potential value for on-the-ground 
          biodiversity protection efforts. As a synthesis of predictive models, 
          they cannot guarantee either the presence or absence of imperiled species 
          at a given location
        </p>
        <br />
        <div className="scale-container scale-container-mobi">
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "8px",
            }}
          >
            Most recent (2020) | Vertebrates Species Richness
          </p>
          <div className="span-wrapper">
            <div
              className="cvi-scale mobi-scale"
              style={{
                background:
                  "linear-gradient(to right, rgb(51,51,153), rgb(20,112,214), rgb(0,169,209),rgb(21,208,106), rgb(117,227,125), rgb(209,246,144), rgb(232,226,141), rgb(186,167,116),rgb(138,105,90), rgb(163,137,132), rgb(209,196,193), rgb(255,255,255))",
              }}
            ></div>
          </div>
          <div className="mobi-scale-num">
            <p>0</p>
            <p>5.5</p>
            <p>11</p>
          </div>
        </div>
        <br />
        <h5 className="legend-source">
          Source:
          <a
            href={"https://planetarycomputer.microsoft.com/dataset/mobi"}
            target={"_blank"}
          >
            {" "}
            MoBI: Map of Biodiversity Importance | Planetary Computer
            (microsoft.com)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "MoBiSRAll") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Map of Biodiversity Importance (MoBi) - All Species Richness
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Composite layer showing all four categories: Vascular Plants, Aquatic
          Invertebrates, Pollinators and Vertebrates
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          This MoBI layer is a raster map that combine habitat information for
          2,216 imperiled species occurring in the conterminous United States,
          using weightings based on range size and degree of protection to
          identify areas of high importance for biodiversity conservation. This
          layer is intended to identify areas of high potential value for
          on-the-ground biodiversity protection efforts. As a synthesis of
          predictive models, they cannot guarantee either the presence or
          absence of imperiled species at a given location. These data layers 
          are intended to identify areas of high potential value for on-the-ground 
          biodiversity protection efforts. As a synthesis of predictive models, 
          they cannot guarantee either the presence or absence of imperiled species 
          at a given location
        </p>
        <br />
        <div className="scale-container scale-container-mobi">
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "8px",
            }}
          >
            Most recent (2020) | All Species Richness
          </p>
          <div className="span-wrapper">
            <div
              className="cvi-scale mobi-scale"
              style={{
                background:
                  "linear-gradient(to right, rgb(51,51,153), rgb(27,99,201), rgb(3,147,249),rgb(0,185,161), rgb(37,211,109), rgb(109,225,124), rgb(181,240,138),rgb(254,254,152), rgb(218,208,133), rgb(182,162,114),rgb(146,115,94), rgb(147,117,110), rgb(183,163,159), rgb(219,209,207), rgb(255,255,255))",
              }}
            ></div>
          </div>
          <div className="mobi-scale-num">
            <p>0</p>
            <p>7</p>
            <p>14</p>
          </div>
        </div>
        <br />
        <h5 className="legend-source">
          Source:
          <a
            href={"https://planetarycomputer.microsoft.com/dataset/mobi"}
            target={"_blank"}
          >
            {" "}
            MoBI: Map of Biodiversity Importance | Planetary Computer
            (microsoft.com)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "MoBiPSR") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Map of Biodiversity Importance (MoBi) - Plant Species Richness
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Vascular plants include but not limited to trees, shrubs, ferns,
          herbaceous plants, grasses, and vines.
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          This MoBI layer is a raster map that combine habitat information for
          2,216 imperiled species occurring in the conterminous United States,
          using weightings based on range size and degree of protection to
          identify areas of high importance for biodiversity conservation. This
          layer is intended to identify areas of high potential value for
          on-the-ground biodiversity protection efforts. As a synthesis of
          predictive models, they cannot guarantee either the presence or
          absence of imperiled species at a given location. These data layers 
          are intended to identify areas of high potential value for on-the-ground 
          biodiversity protection efforts. As a synthesis of predictive models, 
          they cannot guarantee either the presence or absence of imperiled species 
          at a given location
        </p>
        <br />
        <div className="scale-container scale-container-mobi">
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "8px",
            }}
          >
            Most recent (2020) | Species Richness for Vascular Plants
          </p>
          <div className="span-wrapper">
            <div
              className="cvi-scale mobi-scale"
              style={{
                background:
                  "linear-gradient(to right, rgb(51,51,153), rgb(31,91,193), rgb(11,131,233),rgb(0,167, 215), rgb(0,197,125), rgb(45,213,111), rgb(105,225,123),rgb(165,237,135), rgb(225,249,147), rgb(240,236,145),rgb(210,197,129), rgb(180,159,113), rgb(150,121,96), rgb(135,101,94),rgb(165,140,134), rgb(195,178,175), rgb(225,217,215), rgb(255,255,255))",
              }}
            ></div>
          </div>
          <div className="mobi-scale-num">
            <p>0</p>
            <p>8.5</p>
            <p>17</p>
          </div>
        </div>
        <br />
        <h5 className="legend-source">
          Source:
          <a
            href={"https://planetarycomputer.microsoft.com/dataset/mobi"}
            target={"_blank"}
          >
            {" "}
            MoBI: Map of Biodiversity Importance | Planetary Computer
            (microsoft.com)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "indoHucs") {
    return (
      <div className="quality-references">
        <h5 className="legend-source">
          Source:
          <a href={"https://ws-indonesia.org/"} target={"_blank"}>
            {" "}
            Indonesia Watershed boundaries.
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "indoWatersheds") {
    return (
      <div className="quality-references">
        <h5 className="legend-source">
          Source:
          <a href={"https://ws-indonesia.org/"} target={"_blank"}>
            {" "}
            Source Legend Description: Watershed and Catchment boundaries for
            Indonesia.
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "cvi") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Climate Vulnerability Index (CVI)
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          This layer characterizes vulnerabilities to Climate Change across the
          United States to help people better understand the intersection of
          vulnerability and climate change risks. This layer incorporates
          community stakeholder inputs, identified and integrated available
          public health, social, economic, environmental, and climate data in
          the United States (U.S.), comprising 184 indicators, to develop a
          Climate Vulnerability Index (CVI) composed of four baseline
          vulnerabilities (health, social/economic, infrastructure, and
          environment) and three climate change risks (health, social/economic,
          extreme events).
        </p>

        <br />
        <h5 className="legend-source">
          Source:
          <a
            href={"https://climatevulnerabilityindex.org/methodology/"}
            target={"_blank"}
          >
            {" "}
            Characterizing vulnerabilities to climate change across the United
            States - ScienceDirect
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "rainfallForecast") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Excessive Rainfall Forecast
        </p>
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          This layer depicts the Excessive Rainfall Outlook (ERO). In the ERO,
          the Weather Prediction Center (WPC) forecasts the probability that
          rainfall will exceed flash flood guidance (FFG) within 40 kilometers
          (25 miles) of a point.
        </p>
        <h5>Last updated: {getLastUpdatedDate(5, 1)}</h5>
        <br />
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#059e17", global.rainfallForecastBrightness)}` }}
                />
              </td>
              <td> - Marginal (At Least 5%)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#f5f105", global.rainfallForecastBrightness)}` }}
                />
              </td>
              <td> - Slight (At Least 15%)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#a30707", global.rainfallForecastBrightness)}` }}
                />
              </td>
              <td> - Moderate (At Least 40%)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ba0468", global.rainfallForecastBrightness)}` }}
                />
              </td>
              <td> - High (At Least 70%)</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:
          <a
            href={
              "https://www.wpc.ncep.noaa.gov/qpf/excessive_rainfall_outlook_ero.php"
            }
            target={"_blank"}
          >
            {" "}
            Excessive Rainfall Outlook (ERO) Interactive Display (noaa.gov)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "toggleHighwayStyle") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Bridges and Highways
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#D88B47" }}
                />
              </td>
              <td> - Freeways/Interstates</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "hsla(26, 100%, 78%, 0.7)" }}
                />
              </td>
              <td> - Major Highways</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#B4B6A6" }}
                />
              </td>
              <td> - Minor Highways</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "hsla(291, 71%, 42%, 0.7)" }}
                />
              </td>
              <td> - Bridges</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (input === "allFireData") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Historical Wildland fire data in the United States.
        </p>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          <strong>Credits:</strong> US Department of Interior Office of Wildland
          Fire: IRWIN, USDA Forest Service, National Park Service, Fish and
          Wildlife Service, Bureau of Indian Affairs, and Bureau of Land
          Management, National Association of State Foresters, US Fire
          Administration, National Wildfire Coordinating Group
        </p>
        <h5 className="legend-source">
          Source:
          <a
            href={"https://www.nifc.gov/fire-information/maps"}
            target={"_blank"}
          >
            {" "}
            NIFC Maps
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "wildFireBoundaries") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          Best available perimeters for recent and ongoing wildland fires in the
          United States.
        </p>
        <h5>Last updated: {getLastUpdatedDate(5, 1)}</h5>
        <br />
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          <strong>Credits:</strong> US Department of Interior Office of Wildland
          Fire: IRWIN, USDA Forest Service, National Park Service, Fish and
          Wildlife Service, Bureau of Indian Affairs, and Bureau of Land
          Management, National Association of State Foresters, US Fire
          Administration, National Wildfire Coordinating Group
        </p>

        <h5 className="legend-source">
          Source:
          <a
            href={"https://www.nifc.gov/fire-information/maps"}
            target={"_blank"}
          >
            {" "}
            NIFC Maps
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EPOLayer") {
    return (
      <div className="quality-references">
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness('#067A01', global.EPOLayerBrightness)}` }}
                />
              </td>
              <td> - Low to no contamination</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness('#EEF004', global.EPOLayerBrightness)}` }}
                />
              </td>
              <td> - Low contamination</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness('#EF9E03', global.EPOLayerBrightness)}` }}
                />
              </td>
              <td> - Moderate contamination</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness('#EE0903', global.EPOLayerBrightness)}` }}
                />
              </td>
              <td> - High contamination</td>
            </tr>
          </tbody>
        </table>
        {/* <h5 className="legend-source">Source:
          <a href={'https://waterwatch.usgs.gov/'} target={'_blank'}>
            {' '}USGS WaterWatch Sites
          </a>
        </h5> */}
        <hr />
      </div>
    );
  }

  if (input === "waterWatchSites") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          USGS WaterWatch provides real-time, recent, and past streamflow
          conditions for the United States
        </p>
        <h5 className="legend-source">
          Source:
          <a href={"https://waterwatch.usgs.gov/"} target={"_blank"}>
            {" "}
            USGS WaterWatch Sites
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  <a
    href={
      "https://data-wi-dnr.opendata.arcgis.com/datasets/wi-dnr::303d-impaired-lakes-listed/about"
    }
    target={"_blank"}
  >
    {" "}
    Impaired Waters List - 303D
  </a>;

  if (input === "NationalWaterImpairements") {
    return (
      <div className="quality-references">
        <h5>National 303D Impaired Waters Layer</h5>
        <br />
        <p
          style={{
            fontSize: "12px",
            marginBottom: "0.5rem",
            fontWeight: "normal",
          }}
        >
          Impaired Waters listings reflect those waters that have been evaluated
          and determined that they are not meeting state water quality
          standards.
        </p>
        <br />
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#B73D0B", global.NationalWaterImpairementsBrightness)}` }}
                />
              </td>
              <td> {" - "} Is Impaired</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#E4DB41", global.NationalWaterImpairementsBrightness)}` }}
                />
              </td>
              <td> {" - "} Is Threatened</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#2D9806", global.NationalWaterImpairementsBrightness)}` }}
                />
              </td>
              <td> {" - "} Is Not Impaired</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#A43CBA", global.NationalWaterImpairementsBrightness)}` }}
                />
              </td>
              <td> {" - "} Is Not Assessed</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:
          <a
            href={
              "https://www.epa.gov/waterdata/fact-sheet-303d-listed-impaired-waters-state-watershed-and-national-geospatial-datasets"
            }
            target={"_blank"}
          >
            {" "}
            Impaired Waters List - 303D
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "wrfcRisk") {
    return (
      <div className="quality-references">
        <h5>WWF - WRF Country Layer</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          The WWF Water Risk Filter scenarios are based on the combination of
          the most relevant climate scenarios (IPCC Representative Concentration
          Pathways – RCP) and socio-economic scenarios (IIASA Shared
          Socioeconomic Pathways – SSP). Accordingly, Water Risk Filter
          scenarios for 2030 and 2050 contain three different pathways:
          Optimistic: RCP2.6 - 4.5 & SSP1, Current trend: RCP4.5 - 6.0 & SSP2,
          and Pessimistic: RCP6.0 - 8.5 & SSP3
        </p>
        {global && global.wrfcCategory === "Physical Risk" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Basin Physical Risk: represents both natural and human-induced
            conditions of river basins. It comprises the risk categories: 1)
            Water Scarcity, 2) Flooding, 3) Water Quality, and 4) Ecosystem
            Services Status. Therefore, physical risks account for if water is
            too little, too much, unfit for use, and/or the surrounding
            ecosystems are degraded, and in turn, negatively impacting water
            ecosystem services.
          </p>
        )}
        {global && global.wrfcCategory === "Regulatory Risk" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Basin Regulatory Risk: Regulatory water risk is heavily tied to the
            concept of good governance and that businesses thrive in a stable,
            effective and properly implemented regulatory environment. It is
            aligned to the UN Sustainable Development Goal Target 6.5 (SDG
            6.5.1), and comprises the risk categories: 5) Enabling Environment
            (largely concerned with laws & policies), 6) Institutions &
            Governance (concerned with the ability to convene and engage), 7)
            Management Instruments (concerned with data & enforcement), and 8)
            Infrastructure & Finance (concerned with whether funds are
            accessible to build critical water-related infrastructure).
          </p>
        )}
        {global && global.wrfcCategory === "Reputational Risk" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Basin Reputational Risk: While a considerable amount of reputational
            water risk is operational (not basinrelated), there are some basin
            pre-conditions that make reputational water risk more likely to
            manifest. Reputational risk represents stakeholders’ and local
            communities' perceptions on whether companies conduct business
            sustainably or responsibly with respect to water. It comprises the
            risk categories: 9) Cultural Importance (of water to local
            communities), 10) Biodiversity Importance (freshwater biodiversity),
            11) Media Scrutiny (coverage of water related issues), and 12)
            Conflict (risk of hydro-political conflicts in the river basins).
          </p>
        )}
        {global && global.wrfcRiskCategory === "Basin Physical Risk" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Basin Physical Risk: represents both natural and human-induced
            conditions of river basins. It comprises the risk categories: 1)
            Water Scarcity, 2) Flooding, 3) Water Quality, and 4) Ecosystem
            Services Status. Therefore, physical risks account for if water is
            too little, too much, unfit for use, and/or the surrounding
            ecosystems are degraded, and in turn, negatively impacting water
            ecosystem services.
          </p>
        )}
        {global && global.wrfcRiskCategory === "Basin Regulatory Risk" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Basin Regulatory Risk: Regulatory water risk is heavily tied to the
            concept of good governance and that businesses thrive in a stable,
            effective and properly implemented regulatory environment. It is
            aligned to the UN Sustainable Development Goal Target 6.5 (SDG
            6.5.1), and comprises the risk categories: 5) Enabling Environment
            (largely concerned with laws & policies), 6) Institutions &
            Governance (concerned with the ability to convene and engage), 7)
            Management Instruments (concerned with data & enforcement), and 8)
            Infrastructure & Finance (concerned with whether funds are
            accessible to build critical water-related infrastructure).
          </p>
        )}
        {global && global.wrfcRiskCategory === "Basin Reputational Risk" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Basin Reputational Risk: While a considerable amount of reputational
            water risk is operational (not basinrelated), there are some basin
            pre-conditions that make reputational water risk more likely to
            manifest. Reputational risk represents stakeholders’ and local
            communities' perceptions on whether companies conduct business
            sustainably or responsibly with respect to water. It comprises the
            risk categories: 9) Cultural Importance (of water to local
            communities), 10) Biodiversity Importance (freshwater biodiversity),
            11) Media Scrutiny (coverage of water related issues), and 12)
            Conflict (risk of hydro-political conflicts in the river basins).
          </p>
        )}
        {global && global.wrfcRiskCategory === "1. Water Scarcity" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Risk Category 1. Water Scarcity Water scarcity refers to the
            physical abundance or lack of freshwater resources, which can
            significantly impact business such as production/supply chain
            disruption, higher operating costs, and growth constraints. Water
            scarcity is human-driven and can be aggravated by natural conditions
            (e.g. aridity, drought periods), and it is generally calculated as a
            function of the volume of water use/demand relative to the volume of
            water available in a given area. The Water Risk Filter risk category
            water scarcity is a comprehensive and robust metric as it integrates
            a total of 7 best available and peer-reviewed datasets covering
            different aspects of scarcity as well as different modelling
            approaches: aridity index, water depletion, baseline water stress,
            blue water scarcity, available water remaining, drought frequency
            probability, and projected change in drought occurrence.
            <a
              href={
                "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
              }
              target={"_blank"}
            >
              To learn more about these 7 datasets follow this link
            </a>
          </p>
        )}
        {global && global.wrfcRiskCategory === "2. Flooding" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Risk Category 2. Flooding Flooding is when there is an overflowing
            of water onto land that is normally dry. Floods can happen due to
            overflowing rivers, lakes, or oceans, and are often caused by heavy
            rainfall, rapid snowmelt, when dams or levees break, or a storm
            surge from a tropical cyclone or tsunami in coastal areas. Flood
            events can impact businesses’ operations as well as across their
            value chain by causing closure of operations, supply chain
            disruptions and transportation or increased capital costs. The Water
            Risk Filter risk category flooding considers historical patterns and
            future trends. The historical patterns are based on empirical
            evidence of large flood events since 1985 to present, derived from a
            wide variety of news, governmental, instrumental, and remote sensing
            sources; Estimated Flood Occurrence . Future trends are based on
            ensemble projections that apply both global climate and hydrological
            models to compute projected changes in frequency of floods in a 2°C
            scenario; Projected Change in Flood Occurrence.{" "}
            <a
              href={
                "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
              }
              target={"_blank"}
            >
              To learn more about these 7 datasets follow this link
            </a>
          </p>
        )}
        {global && global.wrfcRiskCategory === "3. Water Quality" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Risk Category 3. Water Quality Water quality indicates whether water
            resources are fit for human use and ecosystems alike. Poor water
            quality – water pollution – can impact business indirectly by
            causing ecosystems destabilization or serious health issues as well
            as directly through increased operating costs and as reduction in
            production or growth. The Water Risk Filter risk category water
            quality considers parameters for a Surface Water Quality Index with
            well documented direct and indirect negative effects on water
            security for both humans and freshwater biodiversity. These are
            aligned to the Sustainable Development Goal (SDG) 6.3.2: biological
            oxygen demand (BOD) as a widely used umbrella proxy for overall
            water quality; electrical conductivity (EC) as proxy for salinity
            balance and pH alteration; and nitrogen, to capture nutrient loading
            in water bodies.{" "}
            <a
              href={
                "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
              }
              target={"_blank"}
            >
              To learn more about these 7 datasets follow this link
            </a>
          </p>
        )}
        {global &&
          global.wrfcRiskCategory === "4. Ecosystem Services Status" && (
            <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
              Risk Category 4. Ecosystem Services Status Ecosystems provide
              business, people and communities with a wide range of goods and
              services such as climate and streamflow regulation, water
              purification, species habitats maintenance, balance of soil
              biodiversity, pests and diseases, among many others. Therefore,
              the degradation of ecosystems can result in businesses having
              restricted access in the long-term to the quantity and quality of
              water needed for their activities as well as other ecosystem
              services they rely on. The Water Risk Filter risk category
              ecosystem services status is informed by indicators of
              fragmentation status of rivers (i.e. Connectivity Status Index –
              CSI); catchment degradation (i.e. forest loss, as forests play an
              important role in terms of water regulation, supply and pollution
              control); and projected change in freshwater fish extinction or
              freshwater biodiversity.{" "}
              <a
                href={
                  "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
                }
                target={"_blank"}
              >
                To learn more about these 7 datasets follow this link
              </a>
            </p>
          )}
        {global &&
          global.wrfcRiskCategory === "6. Institutions & Governance" && (
            <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
              Risk Category 6. Institutions & Governance Institutions &
              Governance measures the range and roles of political, social,
              economic and administrative institutions, and the ability to
              convene and engage other stakeholder groups that help to support
              IWRM implementation. Unstable and ineffective institutions &
              governance can potentially undermine business viability. The Water
              Risk Filter risk category institutions & governance is informed by
              three indicators: the corruption perceptions index; the freedom in
              the world index; and the Sustainable Development Goal (SDG) 6.5.1
              indicator on private sector participation in water management.{" "}
              <a
                href={
                  "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
                }
                target={"_blank"}
              >
                To learn more about these 7 datasets follow this link
              </a>
            </p>
          )}
        {global && global.wrfcRiskCategory === "7. Management Instruments" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Risk Category 7. Management Instruments Management instruments
            measures data availability, tools and activities that enable
            decision-makers and users to make rational and informed choices
            between alternative actions that help to support IWRM
            implementation. Ineffective and poorly implemented management
            instruments can potentially undermine business viability. The Water
            Risk Filter risk category management instruments is informed by
            three indicators: Sustainable Development Goal (SDG) 6.5.1 indicator
            on sustainable and efficient water use management; groundwater
            monitoring data availability and management; and density of runoff
            monitoring stations.{" "}
            <a
              href={
                "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
              }
              target={"_blank"}
            >
              To learn more about these 7 datasets follow this link
            </a>
          </p>
        )}
        {global &&
          global.wrfcRiskCategory === "8. Infrastructure & Finance" && (
            <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
              Risk Category 8. Infrastructure & Finance Infrastructure & Finance
              measures access to clean water and sanitation as well as existing
              budgeting and financing made available and used for water
              resources development and management. Low funding for water
              resources development and water infrastructure can potentially
              undermine business viability. The Water Risk Filter risk category
              infrastructure & finance is informed by three indicators:
              percentage of population with access to safe drinking water;
              access to basic sanitation services; and the Sustainable
              Development Goal (SDG) 6.5.1 indicator on financing for water
              resource development and management.{" "}
              <a
                href={
                  "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
                }
                target={"_blank"}
              >
                To learn more about these 7 datasets follow this link
              </a>
            </p>
          )}
        {global && global.wrfcRiskCategory === "9. Cultural Importance" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Risk Category 9. Cultural Importance Water is a social and cultural
            good for local communities, indigenous and traditional people in
            their daily life, religion and culture. Businesses can potentially
            face reputational risk if a cultural good is perceived as negatively
            impacted or violated. The Water Risk Filter risk category cultural
            importance considers the diversity of ethnolinguistic groups
            globally, as a proxy of cultural diversity.{" "}
            <a
              href={
                "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
              }
              target={"_blank"}
            >
              To learn more about these 7 datasets follow this link
            </a>
          </p>
        )}
        {global &&
          global.wrfcRiskCategory === "10. Biodiversity Importance" && (
            <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
              Risk Category 10. Biodiversity Importance Biodiversity importance
              indicates whether a basin is home to a rich, diverse and healthy
              ecosystem. Businesses operating in basins of high biodiversity
              importance are likely to be exposed to higher reputational risks.
              The Water Risk Filter risk category biodiversity importance is
              informed by two indicators from the WWF and TNC work Freshwater
              Ecoregions of the World (FEOW): freshwater endemism, and
              freshwater biodiversity richness.{" "}
              <a
                href={
                  "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
                }
                target={"_blank"}
              >
                To learn more about these 7 datasets follow this link
              </a>
            </p>
          )}
        {global && global.wrfcRiskCategory === "11. Media Scrutiny" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Risk Category 11. Media Scrutiny Media scrutiny indicates how aware
            stakeholders and local communities typically are about water related
            issues due to national and international media coverage. Businesses
            can potentially face reputational risk when operating in countries
            with high media coverage. The Water Risk Filter risk category media
            scrutiny is informed by two indicators developed by WWF and Tecnoma:
            one representing national media coverage, and one representing
            international (global) media coverage.{" "}
            <a
              href={
                "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
              }
              target={"_blank"}
            >
              To learn more about these 7 datasets follow this link
            </a>
          </p>
        )}
        {global && global.wrfcRiskCategory === "12. Conflict" && (
          <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
            Risk Category 12. Conflict Conflict indicates whether there has been
            documented negative news (e.g. incidents, criticism and
            controversies) that can affect a company’s reputational risk as well
            as historical political conflicts due to competition over limited
            water resources. The Water Risk Filter risk category is informed by
            two indicators: Conflict News Event (RepRisk’s country weighted
            score of negative news); and Hydro-Political Likelihood (an index of
            hydro-political issues magnitude).{" "}
            <a
              href={
                "https://cdn.kettufy.io/prod-fra-1.kettufy.io/documents/riskfilter.org/WRF_Source_Descriptions_Links_Global.pdf"
              }
              target={"_blank"}
            >
              To learn more about these 7 datasets follow this link
            </a>
          </p>
        )}
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#E6F8C3', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
                <br />
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#E1F67B', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
              </td>
              <td> {" >"}- Very Low Risk</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#F7F966', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
                <br />
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#E8D23A', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
              </td>
              <td> {" >"}- Low Risk</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#F5D229', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
                <br />
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#F4AD23', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
              </td>
              <td> {" >"}- Medium Risk</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#F08C1E', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
                <br />
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#F35E1D', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
              </td>
              <td> {" >"}- High Risk</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#E91A15', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
                <br />
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#D91915', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
              </td>
              <td> {" >"}- Very High Risk</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#B31715', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
                <br />
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{
                    color: `${adjustHexBrightness('#841814', global.wrfcRiskBrightness)}`,
                    opacity: `${global.wrfcRiskOpacity}`,
                  }}
                />
              </td>
              <td> {" >"}- Extreme Risk</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:
          <a href={"https://riskfilter.org/"} target={"_blank"}>
            {" "}
            WWF - WRF Country Layer
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "eddi") {
    return (
      <div className="quality-references eddi">
        <h5>Evaporative Demand Drought Index (EDDI)</h5>
        <p>
          Examines the atmospheric evaporative demand (E0; also known as "the
          thirst of the atmosphere"). How anomalous is the evaporative demand
          for a given location across a period of time.
        </p>
        <p>
          Brown colors indicate developing drought conditions. Green colors
          indicate improving drought conditions. Deeper shades indicate greater
          changes across the period.
        </p>
        <p>Mapped data for one month timescales from 2022 to 1980.</p>
        <div>
          <div className="eddi-units">
            <span>Wetting tendency</span>
            <span>Drying tendency</span>
          </div>
          <div className="eddi-swatch" />
        </div>

        <h5 className="legend-source">
          Source:
          <a href={"https://psl.noaa.gov/eddi/"} target={"_blank"}>
            {" "}
            NOAA: National Oceanic Atmospheric Association's EDDI
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "NOAAMix") {
    return (
      <div className="quality-references eddi">
        <h5>QPE: Quantitative Precipitation Estimates</h5>
        <p>
          The 13 Weather Service (NWS) River Forecast Centers (RFC) produce Quantitative Precipitation Estimates (QPE) for their individual RFC areas. The RFCs produce the data using a multi-sensor approach utilizing NWS 88D radar estimates of precipitation, automated and manual precipitation gauges and satellite estimates of precipitation. These QPEs are used as input into their hydrologic models to produce NWS river forecasts and guidance products. The QPEs from each RFC are combined into a single mosaic. These QPE 's measuring units are in inches. The data are on an approximate 4km x 4km grid cell scale.
        </p>
        <p>

        </p>
        <p>
          RFCs derive the "Observed" precipitation field using a multisensor approach. Hourly precipitation estimates from WSR-88D NEXRAD are compared to ground rainfall gauge reports, and a bias (correction factor) is calculated and applied to the radar field. The radar and gauge fields are combined into a "multisensor field", which is quality controlled on an hourly basis. In areas where there is limited or no radar coverage, satellite precipitation estimates (SPE) can be incorporated into this multisensor field. The SPE can also be biased against rain gauge reports.
        </p>
        <h5 className="legend-source">
          Source:
          <a href={"https://water.weather.gov/ahps/"} target={"_blank"}>
            {" "}
            NOAA: Advanced Hydrologic Prediction Service AHPS
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "nwshazard") {
    return (
      <div className="quality-references eddi">
        <h5>NOAA All Hazards</h5>
        <p>
          NOAA's National Weather Service provides comprehensive
weather and emergency information. In conjunction with Federal, State, and Local Emergency Managers and other public officials. Warning and post-event information for all types of hazards – including natural (such as earthquakes or avalanches), environmental (such as chemical releases or oil spills), and public safety (such as AMBER alerts or 911 Telephone outages).
        </p>
        <p>

        </p>

        <h5 className="legend-source">
          Source:
          <a href={"https://www.weather.gov/"} target={"_blank"}>
            {" "}
            NOAA's National Weather Service All Hazards
          </a>
        </h5>
        <hr />
      </div>
    );
  }


  if (input === "waterQualityPortal") {
    return (
      <div className="quality-references wqp">
        <h5 style={{ fontSize: "1.2em" }}>Water Quality Portal (WQP)</h5>
        <p style={{ fontSize: "1em" }}>
          Multi-Agency Water Quality Data submitted from the EPA and USGS plus
          over 900 federal, state and tribal agencies, watershed organizations
          and other groups.
        </p>
        <br />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div
            className="eddi-units"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Fewer Results</span>
            <span>More results</span>
          </div>
          <img
            src={interpolatePuOr}
            style={{ width: "100%", height: 20, transform: "rotateY(180deg)" }}
          />
        </div>
        <br />
        <hr />
        <p style={{ marginTop: 22, fontSize: "1em" }}>
          NWIS (USGS) - Water-resources data collected from approximately 1.5
          million sites in all 50 states and overseen territories. NWIS (USGS)
          is updated every 24 hours.
        </p>
        <br />
        <p style={{ fontSize: "1em" }}>
          WQX (EPA) - A data warehouse for water quality, biological, and
          physical data used by state environmental agencies, the EPA, other
          federal agencies, universities, private citizens, and others. WQX
          (EPA) is updated weekly on Thursday evening.
        </p>

        <h5 className="legend-source">
          Source:
          <a href={"https://www.waterqualitydata.us/"} target={"_blank"}>
            {" "}
            Water Quality Portal
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "phFloodHazard") {
    return (
      <div className="quality-references">
        <h5>Philippine Flood Hazard Zones</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Purpose of this dataset shows flood hazard susceptibility at 1:50,000
          scale for disaster risk management.
          <table>
            <tbody>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color: `${adjustHexBrightness("#FFFF00", global.phFloodHazardBrightness)}` }}
                  />
                </td>
                <td> - Low Susceptibility</td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color: `${adjustHexBrightness("#C500FF", global.phFloodHazardBrightness)}` }}
                  />
                </td>
                <td> - Low to Moderate Susceptibility</td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color:  `${adjustHexBrightness("#A900E6", global.phFloodHazardBrightness)}` }}
                  />
                </td>
                <td> - Moderate Susceptibility</td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color: `${adjustHexBrightness("#FF0000", global.phFloodHazardBrightness)}` }}
                  />
                </td>
                <td> - Moderate Susceptibility to High Susceptibility</td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color: `${adjustHexBrightness("#EB0000", global.phFloodHazardBrightness)}` }}
                  />
                </td>
                <td> - High Susceptibility</td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color: `${adjustHexBrightness("#D20000", global.phFloodHazardBrightness)}` }}
                  />
                </td>
                <td> - Flood Prone</td>
              </tr>
            </tbody>
          </table>
        </p>
        <h5 className="legend-source">
          Source:
          <a href={"https://www.geoportal.gov.ph/"} target={"_blank"}>
            {" "}
            Flood Hazard Zones
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "contam") {
    return (
      <div className="quality-references">
        <p style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
          Yellowstone Natural Disaster Watershed Flow Path containing;
          Persistent Contaminants, Moderately Persistent Contaminants, and
          Volatile Contaminants on an hour by hour basis.
          <table>
            <tbody>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color: `${adjustHexBrightness('#087D04', global.yellowstoneLayerBrightness)}` }}
                  />
                </td>
                <td> - Low to no contamination</td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color: `${adjustHexBrightness('#EBEE03', global.yellowstoneLayerBrightness)}` }}
                  />
                </td>
                <td> - Moderate contamination</td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    style={{ color: `${adjustHexBrightness('#F5100D', global.yellowstoneLayerBrightness)}` }}
                  />
                </td>
                <td> - High contamination</td>
              </tr>
            </tbody>
          </table>
        </p>
        <h5 className="legend-source">
          Source:
          <a href={"https://www.epa.gov/wsio"} target={"_blank"}>
            {" "}
            EPA Watershed Index Online (WSIO) & True Qi AI Engine
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "philRegions") {
    return (
      <div className="quality-references">
        <h5>Philippine Region</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          The dataset is an indicative regional administrative boundary
          generated by the Philippine Geoportal System (PGS) Data Component Team
          for the Geoportal Philippines
        </p>
        <h5 className="legend-source">
          Source:
          <a href={"https://www.geoportal.gov.ph/"} target={"_blank"}>
            {" "}
            Philippine Regions
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "gemstatPH") {
    return (
      <div className="quality-references">
        <h5>United Nations (UN) Gemstat Stations</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          The Global Freshwater Quality Database GEMStat provides
          scientifically-sound data and information on the state and trend of
          global inland water quality. As operational part of the GEMS/Water
          Programme of the United Nations Environment Programme (UNEP), GEMStat
          hosts water quality data of ground and surface waters providing a
          global overview of the condition of water bodies and the trends at
          global, regional and local levels.
        </p>
        <h5 className="legend-source">
          Source:
          <a href={"https://gemstat.org/"} target={"_blank"}>
            {" "}
            GEMStat - The global water quality database
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "gemstat") {
    return (
      <div className="quality-references">
        <h5>United Nations (UN) Gemstat Stations</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          The Global Freshwater Quality Database GEMStat provides
          scientifically-sound data and information on the state and trend of
          global inland water quality. As operational part of the GEMS/Water
          Programme of the United Nations Environment Programme (UNEP), GEMStat
          hosts water quality data of ground and surface waters providing a
          global overview of the condition of water bodies and the trends at
          global, regional and local levels.
        </p>
        <h5 className="legend-source">
          Source:
          <a href={"https://gemstat.org/"} target={"_blank"}>
            {" "}
            GEMStat - The global water quality database
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "ABWDLayer") {
    return (
      <div className="quality-references">
        <h5>Australia Bore & Water Data</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Summary information about groundwater bores and their importance for
          groundwater. Water levels shown, are the locations of bores for which
          water level data is available. The water level data is collected by
          the Bureau under the water regulations (2008). The salinity data shows
          the locations of bores for which salinity data is available. The
          salinity is collected by the Bureau under the Water Regulations
          (2008). Data is available in for electrical conductivity (uS/cm) and
          total dissolved solids (mg/L), as supplied to the Bureau by the lead
          water agency in each state and territory..
        </p>
        <h5 className="legend-source">
          Source:
          <a
            href={
              "http://www.bom.gov.au/water/groundwater/insight/#/bore/locationwater"
            }
            target={"_blank"}
          >
            {" "}
            Bore information - Water levels - Australian Groundwater Insight
            (bom.gov.au)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "ANGIS") {
    return (
      <div className="quality-references">
        <h5>Australia's Principal Hydrogeology</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Delineation of the extents of the principal aquifers throughout
          Australia, and categorization into upper, middle or lower aquifer
          groupings based on age and stratigraphic position (depth).  The
          classification is indicative and designed to give only an indication
          of where complex layered aquifers occur.
        </p>
        <h5 className="legend-source">
          Source:
          <a
            href={"http://www.bom.gov.au/water/groundwater/explorer/map.shtml"}
            target={"_blank"}
          >
            {" "}
            Australia National Groundwater Information System (NGIS) Aquifers
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "ausPrinHydro") {
    return (
      <div className="quality-references">
        <h5>Australia's Principal Hydrogeology</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Groundwater resources fall into two major groups, sedimentary basin
          and fractured rock aquifers.  Smaller scale but valuable resources
          also occur in basalt and alluvial plains
        </p>
        <h5 className="legend-source">
          Source:
          <a
            href={
              "http://www.bom.gov.au/water/groundwater/insight/#/hydrogeology/principal"
            }
            target={"_blank"}
          >
            {" "}
            Hydrogeology - Principal hydrogeology - Australian Groundwater
            Insight (bom.gov.au)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "biaLayer") {
    return (
      <div className="quality-references">
        <h5>Bureau of Indian Affairs National Land Area Representation</h5>
        <h5 className="legend-source">
          Source:
          <a
            href={
              "https://catalog.data.gov/dataset/tiger-line-shapefile-2020-nation-u-s-american-indian-tribal-subdivisions"
            }
            target={"_blank"}
          >
            {" "}
            The U.S. Domestic Sovereign Nations: Land Areas of
            Federally-recognized Tribes map (commonly referred to as Indian
            lands) identifies tribal lands with the BIA Land Area Representation
            (LAR)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "PHBasins") {
    return (
      <div className="quality-references">
        <h5 className="legend-source">
          Source:
          <a href={"https://www.geoportal.gov.ph/#"} target={"_blank"}>
            {" "}
            Philippine River Basins
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "PHRivers") {
    return (
      <div className="quality-references">
        <h5 className="legend-source">
          Source:
          <a
            href={"https://data.humdata.org/dataset/philippines-water-courses"}
            target={"_blank"}
          >
            {" "}
            Philippines Water Courses (Rivers)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "outflows") {
    return (
      <div className="quality-references">
        <h5>Outflows - Water Flow Directionality</h5>
        <h5 className="legend-source">
          Source: USGS/True Elements HUC12 Outflow Vectors
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EPASuperfundSites") {
    return (
      <div className="quality-references">
        <h5>
          List of EPA Superfund Sites with links to appropriate monitoring site
          information and status.
        </h5>
        <h5 className="legend-source">
          Source:{" "}
          <a href={"https://www.epa.gov/superfund"} target={"_blank"}>
            EPA Superfund Sites
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "NOAA") {
    return (
      <div className="quality-references">
        <h5>NOAA Sea Level Rise</h5>
        <h5>
          Water levels are relative to local Mean Higher High Water Datum. Areas
          that are hydrologically connected to the ocean are shown in shades of
          blue (darker blue = greater depth).
        </h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#08C64D", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - Low</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#040E62", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 0 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#051498", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 1 foot</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#061BD3", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 2 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#0718A8", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 3 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#643EE5", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 4 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#5B6AE7", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 5 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3D7BE5", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 6 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#17ABE6", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 7 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#5FC5E8", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 8 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#8DE0E8", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 9 feet</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#B1E3E8", global.NOAASeaLevelRiseBrightness)}`, opacity: global.NOAASeaLevelRiseOpacity }}
                />
              </td>
              <td> - 10 feet</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://coast.noaa.gov/digitalcoast/tools/slr.html"}
            target={"_blank"}
          >
            Sea Level Rise Viewer (noaa.gov)
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "hucs") {
    return (
      <div className="quality-references">
        <h5>HUCs - Outlines of Watersheds</h5>
        <h5 className="legend-source">Source: USGS Hydrologic Unit Codes</h5>
        <hr />
      </div>
    );
  }

  if (input === "HUCsMexico") {
    return (
      <div className="quality-references">
        <h5>HUCs - Outlines of Mexican Watersheds</h5>
        <h5 className="legend-source">
          <a
            href={
              "https://databasin.org/datasets/5637f67d31d94a1cb165cdbddb8c4257/"
            }
            target={"_blank"}
          >
            Conservation Biology Institute
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "nhd") {
    return (
      <div className="quality-references">
        <h5>
          NHD - Network of Water Polygon Features: Streams, Lakes Rivers,
          Swamps, Marshes, etc.
        </h5>
        <h5 className="legend-source">
          Source: USGS National Hydrography Database
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "pollution") {
    return (
      <div className="quality-references">
        <h5>Toxic 100 Water Polluters layer</h5>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://peri.umass.edu/toxic-100-water-polluters-index-current"
            }
            target={"_blank"}
          >
            Political Economy Research Institute (PERI) University of
            Massachusetts Amherst
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "WDPA") {
    return (
      <div className="quality-references">
        <h5>World Database on Protected Areas (WDPA)</h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  className="green"
                  style={{
                    color: `${adjustHexBrightness("#9ACD32", global.WDPABrightness)}`
                  }}
                />
              </td>
              <td>{""} - Terrestrial and Inland Waters Protected Areas</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  className="yellow"
                  style={{
                    color: `${adjustHSLABrightness("hsla(237, 84%, 41%, 0.7)", global.WDPABrightness)}`
                  }}
                />
              </td>
              <td>{""} - Marine Protected Areas</td>
            </tr>
          </tbody>
        </table>
        <h5
          style={{ fontSize: "12px", marginLeft: "-7px" }}
          className="legend-source"
        >
          Source:
          <a
            href={
              "https://www.protectedplanet.net/en/thematic-areas/wdpa?tab=WDPA"
            }
            target={"_blank"}
          >
            World Database on Protected Areas
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "water quality") {
    return (
      <div className="quality-references">
        <h5>True QI Range - What do these scores mean?</h5>
        <table id="qiTable">
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon icon={faCircle} size="lg" className="green" />
              </td>
              <td style={{ width: "50px" }}>90 - 100</td>
              <td>No notable irregularities detected</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faCircle} size="lg" className="yellow" />
              </td>
              <td>81 - 90</td>
              <td>Potential irregularities detected within a typical range</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faCircle} size="lg" className="red" />
              </td>
              <td>70 - 80</td>
              <td>
                Potential irregularities detected, more information available
              </td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source: True Elements AI Engine Generator
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "drought") {
    return (
      <div className="quality-references">
        <h5>Drought Monitoring - What do these colors scores mean?</h5>
        <h5>Last updated: {getLastUpdatedDate(5, 1)}</h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#f4fb18", global.droughtMonitoringBrightness)}` }}
                />
              </td>
              <td> - D0 (Abnormally Dry)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color:  `${adjustHexBrightness("#f99010", global.droughtMonitoringBrightness)}` }}
                />
              </td>
              <td> - D1 (Moderate Drought)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color:  `${adjustHexBrightness("#ea631f", global.droughtMonitoringBrightness)}` }}
                />
              </td>
              <td> - D2 (Severe Drought)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#fa3f19", global.droughtMonitoringBrightness)}` }}
                />
              </td>
              <td> - D3 (Extreme Drought)</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ab0707", global.droughtMonitoringBrightness)}` }}
                />
              </td>
              <td> - D4 (Exceptional Drought)</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          <p id="multi-line">Sources:</p> <br /> National Oceanic Atmospheric
          Association (NOAA) <br />
          'United States' Department of Agriculture (USDA) <br />
          National Integrated Drought Information System (NIDIS)
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "aquifers") {
    return (
      <div className="quality-references">
        <h5>Principal Aquifers - What do these colors scores mean?</h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#8a658b", global.principalAquifersBrightness)}` }}
                />
              </td>
              <td> - Carbonate-rock aquifers</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#efa3a3", global.principalAquifersBrightness)}` }}
                />
              </td>
              <td> - Igneous and metamorphic-rock aquifers</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#f1c065", global.principalAquifersBrightness)}` }}
                />
              </td>
              <td> - Sandstone and carbonate-rock aquifers</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#633b2c", global.principalAquifersBrightness)}` }}
                />
              </td>
              <td> - Sandstone aquifers</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#2d2843", global.principalAquifersBrightness)}` }}
                />
              </td>
              <td> - Semiconsolidated sand aquifers</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#292154", global.principalAquifersBrightness)}` }}
                />
              </td>
              <td> - Unconsolidated sand and gravel aquifers</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">Source: 
        
        <a
          href={
            "https://www.sciencebase.gov/catalog/item/5b9fffe6e4b08583a5c2779e"
          }
          target={"_blank"}
        >
        {' '}USGS ScienceBase Catalog
        </a></h5>
        <hr />
      </div>
    );
  }

  if (input === "wells") {
    return (
      <div className="quality-references">
        <h5>
          Domestic Wells by Population - People using domestic supply wells per
          sq kilometer
        </h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: adjustHexBrightness("#2c8f29", global.domesticWellsbrightness)}}
                />
              </td>
              <td> {">"} 0.5</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: adjustHexBrightness("#4fc32c", global.domesticWellsbrightness)}}
                />
              </td>
              <td> {">"} 10</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: adjustHexBrightness("#ddeded", global.domesticWellsbrightness)}}
                />
              </td>
              <td> {">"} 30</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: adjustHexBrightness("#98c3f0", global.domesticWellsbrightness)}}
                />
              </td>
              <td> {">"} 50</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: adjustHexBrightness("#0b61bf", global.domesticWellsbrightness)}}
                />
              </td>
              <td> {">"} 70</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                    style={{ color: adjustHexBrightness("#254153", global.domesticWellsbrightness)}}
                />
              </td>
              <td> {">"} 90</td>
            </tr>
          </tbody>
        </table>
        <h5
        style={{ fontSize: "12px", marginLeft: "-7px" }}
        className="legend-source"
      >
        Source:
        <a
          href={
            "https://www.sciencebase.gov/catalog/item/5b9fffe6e4b08583a5c2779e"
          }
          target={"_blank"}
        >
        {' '}USGS ScienceBase Catalog
        </a>
      </h5>
      </div>
    );
  }

  if (input === "nitrates") {
    return (
      <div className="quality-references">
        <h5>
          Nitrates - Probability of nitrate contamination exceeding 4 mg/L in
          predominantly shallow, recently recharged ground waters of the 'United
          States'.
        </h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness('#01893A', global.gwNitrateAssessmentSelectedBrightness)}` }}
                />
              </td>
              <td> {">"} 0{' - '}1</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness('#FFE607', global.gwNitrateAssessmentSelectedBrightness)}` }}
                />
              </td>
              <td> {">"} 1.5</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness('#FD9C00', global.gwNitrateAssessmentSelectedBrightness)}` }}
                />
              </td>
              <td> {">"} 3</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness('#F50000', global.gwNitrateAssessmentSelectedBrightness)}` }}
                />
              </td>
              <td> {">"} 4.5</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
        <a
          href={
            "https://water.usgs.gov/nawqa/nutrients/pubs/wcp_v39_no12/"
          }
          target={"_blank"}
        >
          
          Source: USGS National Water-Quality Assessment (NAWQA) Program
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "NOAALayer") {
    return (
      <h5
        style={{ fontSize: "12px", marginLeft: "-7px" }}
        className="legend-source"
      >
        Source:
        <a
          href={
            "https://coast.noaa.gov/slr/#/layer/slr/0/-9158631.275009884/3203761.0386067335/11/satellite/none/0.8/2050/interHigh/midAccretion"
          }
          target={"_blank"}
        >
          NOAA Sea Level Rise and Coastal Flooding Impacts
        </a>
      </h5>
    );
  }

  if (input === "femaZones") {
    return (
      <div className="quality-references">
        <h5>FEMA Flood Hazard Zones</h5>
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHSLABrightness("hsla(0, 90%, 41%, 0.7)", global.femaZonesBrightness)}` }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>
                Special Flood Hazard Area (SFHA): Area that will be inundated by
                the flood event having a 1-percent chance of being equaled or
                exceeded in any given year
              </td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHSLABrightness("hsl(63, 100%, 60%)", global.femaZonesBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>
                Areas between the limits of the base flood and the
                0.2-percent-annual-chance (or 500-year) flood
              </td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHSLABrightness("hsla(123, 96%, 77%, 0.5)", global.femaZonesBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>
                Areas of minimal flood hazard: Areas outside the SFHA and higher
                than the elevation of the 0.2-percent-annual-chance flood
              </td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:
          <a
            href={"https://www.fema.gov/flood-maps/national-flood-hazard-layer"}
            target={"_blank"}
          >
            FEMA Flood Hazard Zones
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "AAV4CoastalFloodRisk") {
    return (
      <div className="quality-references">
        <h5>Aqueduct Water Risk Atlas</h5>
        <br />
        <h5>COASTAL FLOOD RISK</h5>
        <br />
        <p style={{ fontSize: "12px" }}>
          <h5>Description:</h5>
          Coastal flood risk measures the percentage of the population expected
          to be affected by coastal flooding in an average year, accounting for
          existing flood protection standards. Flood risk is assessed using
          hazard (inundation caused by storm surge), exposure (population in
          flood zone), and vulnerability.
        </p>
        <br />
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#4C1C24", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High (more than 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#782121", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (3 in 10,000 to 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#807720", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (7 in 100,000 to 3 in 10,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#69852A", global.AAV4CoastalFloodRiskBrightness)}`, }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (9 in 1,000,000 to 7 in 100,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#2C4235", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low (0 to 9 in 1,000,000)</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://www.wri.org/publication/aqueduct-30"}
            target={"_blank"}
          >
            Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
            “Aqueduct 4.0: Updated Decision- Relevant Global Water Risk
            Indicators.” Technical Note. Washington, DC: World Resources
            Institute
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "AAV4WaterStress") {
    return (
      <div className="quality-references">
        <h5>
          <strong>Aqueduct Water Risk Atlas</strong>
        </h5>
        <br />
        <h5>WATER STRESS</h5>
        <br />
        <p style={{ fontSize: "12px" }}>
          <h5>Description:</h5>
          Baseline water stress measures the ratio of total water withdrawals to
          available renewable surface and groundwater supplies. Water
          withdrawals include domestic, industrial, irrigation, and livestock
          consumptive and non-consumptive uses. Available renewable water
          supplies include the impact of upstream consumptive water users and
          large dams on downstream water availability. Higher values indicate
          more competition among users.
        </p>
        <br />
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle} 
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#623023", global.AAAV4WaterStressBrightness)}` }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High ({">"}80%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#943A24", global.AAAV4WaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (40-80%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#9C8F20", global.AAV4WaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (20-40%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#809C2D", global.AAV4WaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (10-20%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#597A3A", global.AAV4WaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low ({"<"}10%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#60675B", global.AAV4WaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Arid and Low Water Use</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://www.wri.org/publication/aqueduct-30"}
            target={"_blank"}
          >
            Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
            “Aqueduct 4.0: Updated Decision- Relevant Global Water Risk
            Indicators.” Technical Note. Washington, DC: World Resources
            Institute
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "AAV4RiverineFloodRisk") {
    return (
      <div className="quality-references">
        <h5>
          <strong>Aqueduct Water Risk Atlas</strong>
        </h5>
        <br />
        <h5>RIVERINE FLOOD RISK</h5>
        <br />
        <p style={{ fontSize: "12px" }}>
          <h5>Description:</h5>
          Riverine flood risk measures the percentage of population expected to
          be affected by Riverine flooding in an average year, accounting for
          existing flood-protection standards. Flood risk is assessed using
          hazard (inundation caused by river overflow), exposure (population in
          flood zone), and vulnerability
        </p>
        <br />
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#D61F1F", global.AAV4RiverineFloodRiskBrightness)}` }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High (more than 1 in 100)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#D46D0C", global.AAV4RiverineFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (6 in 1,000 to 1 in 100)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFD301", global.AAV4RiverineFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (2 in 1,000 to 6 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#7BB662", global.AAV4RiverineFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (1 in 1,000 to 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#006B3D", global.AAV4RiverineFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low (0 to 1 in 1,000)</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://www.wri.org/publication/aqueduct-30"}
            target={"_blank"}
          >
            Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
            “Aqueduct 4.0: Updated Decision- Relevant Global Water Risk
            Indicators.” Technical Note. Washington, DC: World Resources
            Institute
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "aqueductAtlasCoastalFlood") {
    return (
      <div className="quality-references">
        <h5>Aqueduct Water Risk Atlas</h5>
        <br />
        <h5>COASTAL FLOOD RISK</h5>
        <br />
        <p style={{ fontSize: "12px" }}>
          <h5>Description:</h5>
          Coastal flood risk measures the percentage of the population expected
          to be affected by coastal flooding in an average year, accounting for
          existing flood protection standards. Flood risk is assessed using
          hazard (inundation caused by storm surge), exposure (population in
          flood zone), and vulnerability.
        </p>
        <br />
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#4C1523", global.aqueductAtlasCoastalFloodBrightness)}` }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High (more than 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#7F1D24", global.aqueductAtlasCoastalFloodBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (3 in 10,000 to 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#867A20", global.aqueductAtlasCoastalFloodBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (7 in 100,000 to 3 in 10,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#6E8C2B", global.aqueductAtlasCoastalFloodBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (9 in 1,000,000 to 7 in 100,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#283F37", global.aqueductAtlasCoastalFloodBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low (0 to 9 in 1,000,000)</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://www.wri.org/publication/aqueduct-30"}
            target={"_blank"}
          >
            Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
            “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk
            Indicators.” Technical Note. Washington, DC: World Resources
            Institute
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "aqueductAtlasWaterStress") {
    return (
      <div className="quality-references">
        <h5>
          <strong>Aqueduct Water Risk Atlas</strong>
        </h5>
        <br />
        <h5>WATER STRESS</h5>
        <br />
        <p style={{ fontSize: "12px" }}>
          <h5>Description:</h5>
          Baseline water stress measures the ratio of total water withdrawals to
          available renewable surface and groundwater supplies. Water
          withdrawals include domestic, industrial, irrigation, and livestock
          consumptive and nonconsumptive uses. Available renewable water
          supplies include the impact of upstream consumptive water users and
          large dams on downstream water availability. Higher values indicate
          more competition among users.
        </p>
        <br />
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#632721", global.aqueductAtlasWaterStressBrightness)}` }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High ({">"}80%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#973024", global.aqueductAtlasWaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (40-80%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#9E8D20", global.aqueductAtlasWaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (20-40%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#7D992C", global.aqueductAtlasWaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (10-20%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#54763C", global.aqueductAtlasWaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low ({"<"}10%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#5B5F61", global.aqueductAtlasWaterStressBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Arid and Low Water Use</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://www.wri.org/publication/aqueduct-30"}
            target={"_blank"}
          >
            Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
            “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk
            Indicators.” Technical Note. Washington, DC: World Resources
            Institute
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "aqueductAtlasRiverine") {
    return (
      <div className="quality-references">
        <h5>
          <strong>Aqueduct Water Risk Atlas</strong>
        </h5>
        <br />
        <h5>RIVERINE FLOOD RISK</h5>
        <br />
        <p style={{ fontSize: "12px" }}>
          <h5>Description:</h5>
          Riverine flood risk measures the percentage of population expected to
          be affected by Riverine flooding in an average year, accounting for
          existing flood-protection standards. Flood risk is assessed using
          hazard (inundation caused by river overflow), exposure (population in
          flood zone), and vulnerability
        </p>
        <br />
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#CC2A23", global.aqueductAtlasRiverineBrightness)}` }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High (more than 1 in 100)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#DE7A11", global.aqueductAtlasRiverineBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (6 in 1,000 to 1 in 100)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#f1cc08", global.aqueductAtlasRiverineBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (2 in 1,000 to 6 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#0B6E3D", global.aqueductAtlasRiverineBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (1 in 1,000 to 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#7ab15e", global.aqueductAtlasRiverineBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low (0 to 1 in 1,000)</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://www.wri.org/publication/aqueduct-30"}
            target={"_blank"}
          >
            Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
            “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk
            Indicators.” Technical Note. Washington, DC: World Resources
            Institute
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "aqueductAtlas") {
    return (
      <div className="quality-references">
        <h5>
          <strong>Aqueduct Water Risk Atlas</strong>
        </h5>
        <br />
        <h5>WATER STRESS</h5>
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "hsla(0, 73%, 30%, 0.6)" }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High ({">"}80%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "hsla(3, 79%, 50%, 0.6)" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (40-80%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "hsla(51, 89%, 51%, 0.6)" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (20-40%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "hsla(73, 88%, 55%, 0.6)" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (10-20%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "hsla(91, 44%, 48%, 0.6)" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low ({"<"}10%)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "hsla(0, 4%, 53%, 0.6)" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Arid and Low Water Use</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5>COASTAL FLOOD RISK</h5>
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#4C1C24", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High (more than 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#782121", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (3 in 10,000 to 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#807720", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (7 in 100,000 to 3 in 10,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#69852A", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (9 in 1,000,000 to 7 in 100,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#2C4235", global.AAV4CoastalFloodRiskBrightness)}` }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low (0 to 9 in 1,000,000)</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5>RIVERINE FLOOD RISK</h5>
        <table>
          <tbody>
            <tr
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <td
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#D61F1F" }}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                -
              </td>
              <td>Extremely High (more than 1 in 100)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "rgb(234 120 11)" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>High (6 in 1,000 to 1 in 100)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#FFD301" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Medium - High (2 in 1,000 to 6 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#006B3D" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low - Medium (1 in 1,000 to 2 in 1,000)</td>
            </tr>
            <tr
              style={{
                display: "flex",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#7BB662" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>-</td>
              <td>Low (0 to 1 in 1,000)</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://www.wri.org/publication/aqueduct-30"}
            target={"_blank"}
          >
            Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
            “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk
            Indicators.” Technical Note. Washington, DC: World Resources
            Institute
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "femaBoundaries") {
    return (
      <h5 className="legend-source">
        Source:
        <a
          href={"https://www.fema.gov/flood-maps/national-flood-hazard-layer"}
          target={"_blank"}
        >
          FEMA Flood Boundaries
        </a>
      </h5>
    );
  }

  if (input === "terraclimate") {
    return (
      <div className="quality-references">
        <h5>
          Microsoft Terraclimate - Annual Levels of global precipitation
          (inches)
        </h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#440154", global.terraClimateLayerSelectedBrightness)}` }}
                />
              </td>
              <td> - 0</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#443983", global.terraClimateLayerSelectedBrightness)}` }}
                />
              </td>
              <td> - 1</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#31688e", global.terraClimateLayerSelectedBrightness)}` }}
                />
              </td>
              <td> - 11</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#21918c", global.terraClimateLayerSelectedBrightness)}` }}
                />
              </td>
              <td> - 46</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#35b779", global.terraClimateLayerSelectedBrightness)}` }}
                />
              </td>
              <td> - 128</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#90d743", global.terraClimateLayerSelectedBrightness)}` }}
                />
              </td>
              <td> - 279</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={"https://www.climatologylab.org/terraclimate.html"}
            target={"_blank"}
          >
            Microsoft Climatology Lab
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "evapotranspiration") {
    return (
      <div className="quality-references">
        <h5>
          Microsoft Terraclimate - Evapotranspiration: The sum of evaporation
          from the land surface plus transpiration from plants
        </h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#1A8693", global.evapotranspirationSelectedBrightness)}` }}
                />
              </td>
              <td> - 0</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#4EA2A2", global.evapotranspirationSelectedBrightness)}` }}
                />
              </td>
              <td> - 1</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#D4B95E", global.evapotranspirationSelectedBrightness)}` }}
                />
              </td>
              <td> - 9</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#D28F34", global.evapotranspirationSelectedBrightness)}` }}
                />
              </td>
              <td> - 35</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#B34232", global.evapotranspirationSelectedBrightness)}` }}
                />
              </td>
              <td> - 90</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#741a33", global.evapotranspirationSelectedBrightness)}` }}
                />
              </td>
              <td> - 186</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://planetarycomputer.microsoft.com/dataset/terraclimate"
            }
            target={"_blank"}
          >
            Microsoft Climatology Lab
          </a>
        </h5>
        <hr />
      </div>
    );
  }
  if (input === "EJDemoSelected") {
    return (
      <div className="quality-references">
        <h5>Demographic Index (National Percentiles)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          The Demographic Index is a combination of percent low-income and
          percent minority. For each Census block group, these two numbers are
          simply averaged together. The formula is as follows: Demographic Index
          = (% people of color + % low-income) / 2.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#CA7676", global.EJDemoSelectedBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#E8C276", global.EJDemoSelectedBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#F5F5AF", global.EJDemoSelectedBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ABABAB", global.EJDemoSelectedBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#D0D0D0", global.EJDemoSelectedBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#e2e2e2", global.EJDemoSelectedBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJUndergroundStorageTanksBrightness)}` }}
                />
              </td>
              <td> Less than 50 percentile</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJLowIncomeSelected") {
    return (
      <div className="quality-references">
        <h5>Low Income Index (National Percentiles)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Percent of individuals whose ratio of household income to poverty
          level in the past 12 months was less than 2 (as a fraction of
          individuals for whom ratio was determined).
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#003728" }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#006A4E" }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#2E856E" }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#5CA08E" }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#8ABAAE" }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#B8D5CD" }}
                />
              </td>
              <td> 50 - 60 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "#ffffff" }}
                />
              </td>
              <td> Less than 50 percentile</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJPOCSelected") {
    return (
      <div className="quality-references">
        <h5>People of Color (National Percentiles)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          The percent of individuals in a block group who list their racial
          status as a race other than white alone and/or list their ethnicity as
          Hispanic or Latino. That is, all people other than non-Hispanic
          white-alone individuals. The word "alone" in this case indicates that
          the person is of a single race, not multiracial.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#332b00", global.EJPOCSelectedBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#4d4100", global.EJPOCSelectedBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#665600", global.EJPOCSelectedBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#998100", global.EJPOCSelectedBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color:  `${adjustHexBrightness("#ccac00", global.EJPOCSelectedBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffd700", global.EJPOCSelectedBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJPOCSelectedBrightness)}` }}
                />
              </td>
              <td> Less than 50 percentile</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJUnemploymentSelected") {
    return (
      <div className="quality-references">
        <h5>Unemployment Rate (National Percentiles)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          All those who did not have a job at all during the reporting period,
          made at least one specific active effort to find a job during the
          prior 4 weeks, and were available for work (unless temporarily ill).
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FE5A1D", global.EJUnemploymentSelectedBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FE8116", global.EJUnemploymentSelectedBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFA80F", global.EJUnemploymentSelectedBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFCF07", global.EJUnemploymentSelectedBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FFF600", global.EJUnemploymentSelectedBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FCFF69", global.EJUnemploymentSelectedBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJUnemploymentSelectedBrightness)}` }}
                />
              </td>
              <td> Less than 50 percentile</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJLingIsoSelected") {
    return (
      <div className="quality-references">
        <h5>Linguistically Isolated (National Percentiles)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Percent of households in which no one age 14 and over speaks English
          "very well" or speaks English only (as a fraction of households).
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#006868", global.EJLingIsoSelectedBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#006868", global.EJLingIsoSelectedBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#00a2a2", global.EJLingIsoSelectedBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#00dddd", global.EJLingIsoSelectedBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#00ffff", global.EJLingIsoSelectedBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3ddbd9", global.EJLingIsoSelectedBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJLingIsoSelectedBrightness)}` }}
                />
              </td>
              <td> Less than 50 percentile</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJLessHSSelected") {
    return (
      <div className="quality-references">
        <h5>Less Than High School Education (National Percentiles)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Percent of individuals age 25 and over with less than high school
          degree.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#2f443a", global.EJLessHSSelectedBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#374f44", global.EJLessHSSelectedBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3f5b4e", global.EJLessHSSelectedBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#476658", global.EJLessHSSelectedBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#4f7262", global.EJLessHSSelectedBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#577d6c", global.EJLessHSSelectedBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJLessHSSelectedBrightness)}` }}
                />
              </td>
              <td> Less than 50 percentile</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJFiveAndUnderAgeSelected") {
    return (
      <div className="quality-references">
        <h5>Under Age 5 (National Percentiles)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Percent of individuals under age 5 as a fraction of population.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#D62226", global.EJFiveAndUnderAgeSelectedBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#F5C603", global.EJFiveAndUnderAgeSelectedBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color:`${adjustHexBrightness("#01AA31", global.EJFiveAndUnderAgeSelectedBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#1FC3CD", global.EJFiveAndUnderAgeSelectedBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#4542B9", global.EJFiveAndUnderAgeSelectedBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#411271", global.EJFiveAndUnderAgeSelectedBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJFiveAndUnderAgeSelectedBrightness)}` }}
                />
              </td>
              <td> Less than 50 percentile</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJSixtyFourAndOverSelected") {
    return (
      <div className="quality-references">
        <h5>Over Age 64 (National Percentiles)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Percent of individuals over age 64 as a fraction of the population.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#A17A74", global.EJSixtyFourAndOverSelectedBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#F4BF90", global.EJSixtyFourAndOverSelectedBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#FCE8B8", global.EJSixtyFourAndOverSelectedBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#C5D4AB", global.EJSixtyFourAndOverSelectedBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#7F6890", global.EJSixtyFourAndOverSelectedBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#577d6c", global.EJSixtyFourAndOverSelectedBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJSixtyFourAndOverSelectedBrightness)}` }}
                />
              </td>
              <td> Less than 50 percentile</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJWasteWaterDischarge") {
    return (
      <div className="quality-references">
        <h5>
          Wastewater Dischargers Indicator (Stream Proximity and Toxic
          Concentration)
        </h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          RSEI modeled Toxic Concentrations at stream segments within 500
          meters, divided by distance in kilometers (km). Calculated from RSEI
          modeled toxic concentrations to stream reach segments.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#76520e", global.EJWasteWaterDischargeBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#926c15", global.EJWasteWaterDischargeBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#b69121", global.EJWasteWaterDischargeBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#dbb42c", global.EJWasteWaterDischargeBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#fad643", global.EJWasteWaterDischargeBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJWasteWaterDischargeBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile or less</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: "black" }}
                />
              </td>
              <td>No data</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJUndergroundStorageTanks") {
    return (
      <div className="quality-references">
        <h5>Underground Storage Tanks (UST) and Leaking UST (LUST)</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Count of LUSTs (multiplied by a factor of 7.7) and the number of USTs
          within a 1,500-foot buffered block group. Calculated from EPA UST
          Finder.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#143d52", global.EJUndergroundStorageTanksBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#1d5672", global.EJUndergroundStorageTanksBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#256e93", global.EJUndergroundStorageTanksBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#2d87b4", global.EJUndergroundStorageTanksBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3399cc", global.EJUndergroundStorageTanksBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJUndergroundStorageTanksBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile or less</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJRMP") {
    return (
      <div className="quality-references">
        <h5>Risk Management Plan (RMP) Facility Proximity</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Count of RMP (potential chemical accident management plan) facilities
          within 5 km (or nearest one beyond 5 km), each divided by distance in
          km. Calculated from EPA RMP database.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#10002b", global.EJRMPBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#3c096c", global.EJRMPBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color:  `${adjustHexBrightness("#7b2cbf", global.EJRMPBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#c77dff", global.EJRMPBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#e0aaff", global.EJRMPBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color:  `${adjustHexBrightness("#ffffff", global.EJRMPBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile or less</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJSuperfund") {
    return (
      <div className="quality-references">
        <h5>Superfund proximity</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Count of proposed and listed NPL sites within 5 km (or nearest one
          beyond 5 km), each divided by distance in km. Count excludes deleted
          sites. Source: Calculated from EPA CERCLIS database.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#008000", global.EJSuperfundBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#1f991f", global.EJSuperfundBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#47b347", global.EJSuperfundBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#7acc7a", global.EJSuperfundBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#b8e6b8", global.EJSuperfundBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJSuperfundBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile or less</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "EJHazardousWaste") {
    return (
      <div className="quality-references">
        <h5>Hazardous Waste Proximity</h5>
        <p style={{ fontSize: "10px", marginBottom: "0.5rem" }}>
          Count of hazardous waste management facilities (TSDFs and LQGs) within
          5 km (or nearest one beyond 5 km), each divided by distance in km.
          Calculated from EPA RCRAInfo database.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#942a00", global.EJHazardousWasteBrightness)}` }}
                />
              </td>
              <td> 95 - 100 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#c93900", global.EJHazardousWasteBrightness)}` }}
                />
              </td>
              <td> 90 - 95 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#e44000", global.EJHazardousWasteBrightness)}` }}
                />
              </td>
              <td> 80 - 90 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ff4800", global.EJHazardousWasteBrightness)}` }}
                />
              </td>
              <td> 70 - 80 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ff5b1b", global.EJHazardousWasteBrightness)}` }}
                />
              </td>
              <td> 60 - 70 percentile</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness("#ffffff", global.EJHazardousWasteBrightness)}` }}
                />
              </td>
              <td> 50 - 60 percentile or less</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics"
            }
            target={"_blank"}
          >
            EPA Environmental Justice Screen
          </a>
        </h5>
        <hr />
      </div>
    );
  }

  if (input === "Water Service Boundary") {
    return (
      <div className="quality-references">
        <h5 style={{ fontSize: "16px" }}>
          Provisional Water Utility Service Area Boundaries
        </h5>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness(
                    "#60C492", 
                    global.waterSystemBoundaryTier1Brightness
                    | global.waterSystemBoundaryTier2Brightness
                    | global.waterSystemBoundaryTier3Brightness
                    )}` }}
                />
              </td>
              <td>
                Tier 1 - Georeferenced boundary sourced from state agencies and
                utilities
              </td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness(
                    "#36A1C4", 
                    global.waterSystemBoundaryTier1Brightness
                    | global.waterSystemBoundaryTier2Brightness
                    | global.waterSystemBoundaryTier3Brightness
                    )}` }}
                />
              </td>
              <td>
                Tier 2a - Matched government jurisdiction or Census Place
                boundary
              </td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="lg"
                  style={{ color: `${adjustHexBrightness(
                    "#C73234", 
                    global.waterSystemBoundaryTier1Brightness
                    | global.waterSystemBoundaryTier2Brightness
                    | global.waterSystemBoundaryTier3Brightness
                    )}` }}
                />
              </td>
              <td>Tier 3 - Estimated location and extent</td>
            </tr>
          </tbody>
        </table>
        <h5 className="legend-source">
          Source:{" "}
          <a
            href={
              "https://www.hydroshare.org/resource/20b908d73a784fc1a097a3b3f2b58bfb/"
            }
            target={"_blank"}
          >
            U.S. Community Water Systems Service Boundaries
          </a>
        </h5>
        <hr />
      </div>
    );
  }
}


