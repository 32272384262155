export const QPElegend = { "QPE": {
                                    "title": "Precipitation Estimate",
                                    "enabled": false,
                                    "legend": true,
                                    "duration": 7,
                                    "durations": ["Since 12Z", "Last 1 hours", "Last 2 hours", "Last 3 hours", "Last 6 hours", "Last 12 hours", "Last 24 hours", "1 Day", "Last 2 days", "Last 3 days", "Last 4 days", "Last 5 days", "Last 6 days", "Last 7 days", "Last 10 days", "Last 14 days", "Last 30 days", "Last 60 days", "Last 90 days", "Last 120 days", "Last 180 days", "Last 365 days", "Month to date", "Year to date", "Water year to date"],
                                    "durationsShort": ["12z", "1 Hour", "2 Hour", "3 Hour", "6 Hour", "12 Hour", "24 Hour", "1 Day", "2 Day", "3 Day", "4 Day", "5 Day", "6 Day", "7 Day", "10 Day", "14 Day", "30 Day", "60 Day", "90 Day", "120 Day", "180 Day", "365 Day", "mtd", "ytd", "wytd"],
                                    "selectedProduct": 0,
                                    "products": [{
                                        "title": "Observed",
                                        "selectedLayer": 7,
                                        "layers": [{
                                            "id": "qpe/observed/12z",
                                            "esri": "4",
                                            "duration": 0,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/observed/1hour",
                                            "esri": "8",
                                            "duration": 1,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/observed/2hour",
                                            "esri": "12",
                                            "duration": 2,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/observed/3hour",
                                            "esri": "16",
                                            "duration": 3,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/observed/6hour",
                                            "esri": "20",
                                            "duration": 4,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/observed/12hour",
                                            "esri": "24",
                                            "duration": 5,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/observed/24hour",
                                            "esri": "28",
                                            "duration": 6,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/observed/1day",
                                            "esri": "32",
                                            "duration": 7,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/observed/2day",
                                            "esri": "36",
                                            "duration": 8,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/3day",
                                            "esri": "40",
                                            "duration": 9,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/4day",
                                            "esri": "44",
                                            "duration": 10,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/5day",
                                            "esri": "48",
                                            "duration": 11,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/6day",
                                            "esri": "52",
                                            "duration": 12,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/7day",
                                            "esri": "56",
                                            "duration": 13,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/10day",
                                            "esri": "60",
                                            "duration": 14,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/14day",
                                            "esri": "64",
                                            "duration": 15,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/30day",
                                            "esri": "68",
                                            "duration": 16,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/60day",
                                            "esri": "72",
                                            "duration": 17,
                                            "colorRamp": 2
                                        }, {
                                            "id": "qpe/observed/90day",
                                            "esri": "76",
                                            "duration": 18,
                                            "colorRamp": 2
                                        }, {
                                            "id": "qpe/observed/120day",
                                            "esri": "80",
                                            "duration": 19,
                                            "colorRamp": 2
                                        }, {
                                            "id": "qpe/observed/180day",
                                            "esri": "84",
                                            "duration": 20,
                                            "colorRamp": 3
                                        }, {
                                            "id": "qpe/observed/365day",
                                            "esri": "88",
                                            "duration": 21,
                                            "colorRamp": 3
                                        }, {
                                            "id": "qpe/observed/mtd",
                                            "esri": "92",
                                            "duration": 22,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/observed/ytd",
                                            "esri": "96",
                                            "duration": 23,
                                            "colorRamp": 3
                                        }, {
                                            "id": "qpe/observed/wytd",
                                            "esri": "100",
                                            "duration": 24,
                                            "colorRamp": 3
                                        }]
                                    }, {
                                        "title": "Normal",
                                        "selectedLayer": 0,
                                        "layers": [{
                                            "id": "qpe/normal/1day",
                                            "esri": "105",
                                            "duration": 7,
                                            "colorRamp": 0
                                        }, {
                                            "id": "qpe/normal/7day",
                                            "esri": "109",
                                            "duration": 13,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/normal/10day",
                                            "esri": "113",
                                            "duration": 14,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/normal/14day",
                                            "esri": "117",
                                            "duration": 15,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/normal/30day",
                                            "esri": "121",
                                            "duration": 16,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/normal/60day",
                                            "esri": "125",
                                            "duration": 17,
                                            "colorRamp": 2
                                        }, {
                                            "id": "qpe/normal/90day",
                                            "esri": "129",
                                            "duration": 18,
                                            "colorRamp": 2
                                        }, {
                                            "id": "qpe/normal/120day",
                                            "esri": "133",
                                            "duration": 19,
                                            "colorRamp": 2
                                        }, {
                                            "id": "qpe/normal/180day",
                                            "esri": "137",
                                            "duration": 20,
                                            "colorRamp": 3
                                        }, {
                                            "id": "qpe/normal/365day",
                                            "esri": "141",
                                            "duration": 21,
                                            "colorRamp": 3
                                        }, {
                                            "id": "qpe/normal/mtd",
                                            "esri": "145",
                                            "duration": 22,
                                            "colorRamp": 1
                                        }, {
                                            "id": "qpe/normal/ytd",
                                            "esri": "149",
                                            "duration": 23,
                                            "colorRamp": 3
                                        }, {
                                            "id": "qpe/normal/wytd",
                                            "esri": "153",
                                            "duration": 24,
                                            "colorRamp": 3
                                        }]
                                    }, {
                                        "title": "Departure From Normal",
                                        "selectedLayer": 0,
                                        "layers": [{
                                            "id": "qpe/departure/1day",
                                            "esri": "158",
                                            "duration": 7,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/7day",
                                            "esri": "162",
                                            "duration": 13,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/10day",
                                            "esri": "166",
                                            "duration": 14,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/14day",
                                            "esri": "170",
                                            "duration": 15,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/30day",
                                            "esri": "174",
                                            "duration": 16,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/60day",
                                            "esri": "178",
                                            "duration": 17,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/90day",
                                            "esri": "182",
                                            "duration": 18,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/120day",
                                            "esri": "186",
                                            "duration": 19,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/180day",
                                            "esri": "190",
                                            "duration": 20,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/365day",
                                            "esri": "194",
                                            "duration": 21,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/mtd",
                                            "esri": "198",
                                            "duration": 22,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/ytd",
                                            "esri": "202",
                                            "duration": 23,
                                            "colorRamp": 4
                                        }, {
                                            "id": "qpe/departure/wytd",
                                            "esri": "206",
                                            "duration": 24,
                                            "colorRamp": 4
                                        }]
                                    }, {
                                        "title": "Percent of Normal",
                                        "selectedLayer": 0,
                                        "layers": [{
                                            "id": "qpe/percent/1day",
                                            "esri": "211",
                                            "duration": 7,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/7day",
                                            "esri": "215",
                                            "duration": 13,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/10day",
                                            "esri": "219",
                                            "duration": 14,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/14day",
                                            "esri": "223",
                                            "duration": 15,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/30day",
                                            "esri": "227",
                                            "duration": 16,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/60day",
                                            "esri": "231",
                                            "duration": 17,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/90day",
                                            "esri": "235",
                                            "duration": 18,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/120day",
                                            "esri": "239",
                                            "duration": 19,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/180day",
                                            "esri": "243",
                                            "duration": 20,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/365day",
                                            "esri": "247",
                                            "duration": 21,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/mtd",
                                            "esri": "251",
                                            "duration": 22,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/ytd",
                                            "esri": "255",
                                            "duration": 23,
                                            "colorRamp": 7
                                        }, {
                                            "id": "qpe/percent/wytd",
                                            "esri": "259",
                                            "duration": 24,
                                            "colorRamp": 7
                                        }]
                                    }],
                                    "selectedRamp": 0,
                                    "colorRamps": [{
                                        "title": "Rainfall",
                                        "unit": "inches",
                                        "ramp": [["#D7D7D7", "Greater than or equal to 10", "10"], ["#7240D6", "8 to 10", "10"], ["#F600F2", "6 to 8", "8"], ["#700209", "5 to 6", "6"], ["#A20311", "4 to 5", "5"], ["#F50719", "3 to 4", "4"], ["#F68C28", "2.5 to 3", "3"], ["#FDD469", "2 to 2.5", "2.5"], ["#F8FA3D", "1.5 to 2", "2"], ["#0E5918", "1 to 1.5", "1.5"], ["#189624", "0.75 to 1", "1"], ["#28FA3B", "0.5 to 0.75", "0.75"], ["#0C1287", "0.25 to 0.5", "0.5"], ["#3B79BB", "0.1 to 0.25", "0.25"], ["#2BC0F5", "0.01 to 0.1", "0.1"], ["#E2ECF5", "Less than 0.01", "0.01"], ["#727272", "Missing data", "---"]]
                                    }, {
                                        "title": "Rainfall",
                                        "unit": "inches",
                                        "ramp": [["#D7D7D7", "Greater than or equal to 20", "20"], ["#7240D6", "15 to 20", "20"], ["#F600F2", "10 to 15", "15"], ["#700209", "8 to 10", "10"], ["#A20311", "6 to 8", "8"], ["#F50719", "5 to 6", "6"], ["#F68C28", "4 to 5", "5"], ["#FDD469", "3 to 4", "4"], ["#F8FA3D", "2 to 3", "3"], ["#0E5918", "1.5 to 2", "2"], ["#189624", "1 to 1.5", "1.5"], ["#28FA3B", "0.5 to 1", "1"], ["#0C1287", "0.25 to 0.5", "0.5"], ["#3B79BB", "0.1 to 0.25", "0.25"], ["#2BC0F5", "0.01 to 0.1", "0.1"], ["#E2ECF5", "Less than 0.01", "0.01"], ["#727272", "Missing data", "---"]]
                                    }, {
                                        "title": "Rainfall",
                                        "unit": "inches",
                                        "ramp": [["#D7D7D7", "Greater than or equal to 50", "50"], ["#7240D6", "40 to 50", "50"], ["#F600F2", "35 to 40", "40"], ["#700209", "30 to 35", "35"], ["#A20311", "25 to 30", "30"], ["#F50719", "20 to 25", "25"], ["#F68C28", "15 to 20", "20"], ["#FDD469", "10 to 15", "15"], ["#F8FA3D", "8 to 10", "10"], ["#0E5918", "6 to 8", "8"], ["#189624", "4 to 6", "6"], ["#28FA3B", "2 to 4", "4"], ["#0C1287", "1 to 2", "2"], ["#3B79BB", "0.5 to 1", "1"], ["#2BC0F5", "0.01 to 0.5", "0.5"], ["#E2ECF5", "Less than 0.01", "0.01"], ["#727272", "Missing data", "---"]]
                                    }, {
                                        "title": "Rainfall",
                                        "unit": "inches",
                                        "ramp": [["#D7D7D7", "Greater than or equal to 100", "100"], ["#7240D6", "80 to 100", "100"], ["#F600F2", "70 to 80", "80"], ["#700209", "60 to 70", "70"], ["#A20311", "50 to 60", "60"], ["#F50719", "40 to 50", "50"], ["#F68C28", "35 to 40", "40"], ["#FDD469", "30 to 35", "35"], ["#F8FA3D", "25 to 30", "30"], ["#0E5918", "20 to 25", "25"], ["#189624", "15 to 20", "20"], ["#28FA3B", "10 to 15", "15"], ["#0C1287", "5 to 10", "10"], ["#3B79BB", "2.5 to 5", "5"], ["#2BC0F5", "0.01 to 2.5", "2.5"], ["#E2ECF5", "Less than 0.01", "0.01"], ["#727272", "Missing data", "---"]]
                                    }, {
                                        "title": "Rainfall",
                                        "unit": "inches",
                                        "ramp": [["#F600F2", "Greater than or equal to 8", "8"], ["#982FD6", "5 to 8", "8"], ["#7240D6", "4 to 5", "5"], ["#0C1287", "3 to 4", "4"], ["#2BC0F5", "2 to 3", "3"], ["#0E5918", "1 to 2", "2"], ["#28FA3B", "0.5 to 1", "1"], ["#D7D7D7", "-0.5 to 0.5", "0.5"], ["#F0FA9E", "-1 to -0.5", "-0.5"], ["#F8FA3D", "-2 to -1", "-1"], ["#FDD469", "-3 to -2", "-2"], ["#F68C28", "-4 to -3", "-3"], ["#F50719", "-5 to -4", "-4"], ["#A20311", "-8 to -5", "-5"], ["#730F12", "Less than -8", "-8"], ["#727272", "Missing data", "---"]]
                                    }, {
                                        "title": "Rainfall",
                                        "unit": "inches",
                                        "ramp": [["#F600F2", "Greater than or equal to 16", "16"], ["#982FD6", "12 to 16", "16"], ["#7240D6", "8 to 12", "12"], ["#0C1287", "6 to 8", "8"], ["#2BC0F5", "4 to 6", "6"], ["#0E5918", "2 to 4", "4"], ["#28FA3B", "1 to 2", "2"], ["#D7D7D7", "-1 to 1", "1"], ["#F0FA9E", "-2 to -1", "-1"], ["#F8FA3D", "-4 to -2", "-2"], ["#FDD469", "-6 to -4", "-4"], ["#F68C28", "-8 to -6", "-6"], ["#F50719", "-12 to -8", "-8"], ["#A20311", "-16 to -12", "-12"], ["#730F12", "Less than -16", "-16"], ["#727272", "Missing data", "---"]]
                                    }, {
                                        "title": "Rainfall",
                                        "unit": "inches",
                                        "ramp": [["#F600F2", "Greater than or equal to 20", "20"], ["#982FD6", "16 to 20", "20"], ["#7240D6", "12 to 16", "16"], ["#0C1287", "8 to 12", "12"], ["#2BC0F5", "6 to 8", "8"], ["#0E5918", "4 to 6", "6"], ["#28FA3B", "2 to 4", "4"], ["#D7D7D7", "-2 to 2", "2"], ["#F0FA9E", "-4 to -2", "-2"], ["#F8FA3D", "-6 to -4", "-4"], ["#FDD469", "-8 to -6", "-6"], ["#F68C28", "-12 to -8", "-8"], ["#F50719", "-16 to -12", "-12"], ["#A20311", "-20 to -16", "-16"], ["#730F12", "Less than -20", "-20"], ["#727272", "Missing data", "---"]]
                                    }, {
                                        "title": "Rainfall",
                                        "unit": "percentage",
                                        "ramp": [["#F600F2", "Greater than or equal to 600", "600"], ["#982FD6", "400 to 600", "600"], ["#7240D6", "300 to 400", "400"], ["#0C1287", "200 to 300", "300"], ["#2BC0F5", "150 to 200", "200"], ["#0E5918", "125 to 150", "150"], ["#28FA3B", "110 to 125", "125"], ["#D7D7D7", "100 to 110", "110"], ["#F0FA9E", "90 to 100", "100"], ["#F8FA3D", "75 to 90", "90"], ["#FDD469", "50 to 75", "75"], ["#F68C28", "25 to 50", "50"], ["#F50719", "10 to 25", "25"], ["#A20311", "5 to 10", "10"], ["#730F12", "0 to 5", "5"], ["#727272", "Missing data", "---"]]
                                    }],
                                    "opacity": 0.75,
                                    "selectedDisplayType": 0,
                                    "QPEPopupEnabled": false,
                                    "waterYearEnabled": true,
                                    "year": 2024,
                                    "month": 4,
                                    "day": 26
                                }}