import { Source, Layer, Popup } from 'react-map-gl';
import settings from "../../../settings.json";
import { DateTime } from 'luxon';
import { useState, useMemo, useContext, useEffect } from 'react';
import { RefContext } from "../../../RefContext";
import { PointContext } from "../../../PointContext";
import { v4 as uuidv4 } from 'uuid';
import { adjustHexBrightness } from '../../../features/utils';

const NOAAMix = ({ id, url, opacity, global }: any) => {
  let utnow = DateTime.now().setZone('America/New_York').minus({hours: 10}).toISODate({ format: 'basic' });
  let utb = DateTime.now().setZone('America/New_York').minus({hours: 10}).toFormat('yyyy/MM/dd'); 
  let rangeSel = "nws_precip_last7days_";  
  let categorySel = "observed";  
  let _currentUrl = `https://water.weather.gov/ahps/gis.php/wmts/?shape_path=${utb}/${rangeSel}${categorySel}_${utnow}&service=wmts&request=gettile&version=1.0.0&layer=precip&style=default&tilematrixset=esri-web-mercator&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}`;

  _currentUrl = 'https://mapservices.weather.noaa.gov/raster/rest/services/obs/rfc_qpe/MapServer/export?bbox={bbox-epsg-3857}&format=png32&transparent=true&layers=57,58,59,60&f=image'
  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [currentRange, setCurrentRange] = useState<any>(rangeSel);
  const [currentCategory, setCurrentCategory] = useState<any>(categorySel);  //show:32

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  useEffect(() => {   
      let newRange = currentRange;
      let newCategory = currentCategory;
      let newDate = DateTime.now().setZone('America/New_York').minus({hours: 9}).toISODate({ format: 'basic' });
      let newDateB = DateTime.now().setZone('America/New_York').minus({hours: 9}).toFormat('yyyy/MM/dd'); 
      
      if(global.layerMode && global.layerMode.range){

/*        if(global.layerMode.range==='Today'){ newRange = "nws_precip_1day_"; }
        if(global.layerMode.range==='Yesterday'){ newRange = "nws_precip_1day_"; }
        if(global.layerMode.range==='Last 7 days'){ newRange = "nws_precip_last7days_"; }
        if(global.layerMode.range==='14 days'){ newRange = "nws_precip_last14days_"; }
        if(global.layerMode.range==='30 days'){ newRange = "nws_precip_last30days_"; }
        if(global.layerMode.range==='180 days'){ newRange = "nws_precip_last180days_"; }
        if(global.layerMode.range==='365 days'){ newRange = "nws_precip_last365days_"; }
        if(global.layerMode.range==='YTD'){ newRange = "nws_precip_year2date_"; }*/

        if(global.layerMode.range==='Today'){ newRange = "32"; }
        if(global.layerMode.range==='Yesterday'){ newRange = "36"; }
        if(global.layerMode.range==='Last 7 days'){ newRange = "56"; }
        if(global.layerMode.range==='14 days'){ newRange = "64"; }
        if(global.layerMode.range==='30 days'){ newRange = "68"; }
        if(global.layerMode.range==='180 days'){ newRange = "84"; }
        if(global.layerMode.range==='365 days'){ newRange = "88"; }
        if(global.layerMode.range==='YTD'){ newRange = "96"; }

        if(global.layerMode.category === 'normal'){
            if(global.layerMode.range==='Today'){ newRange = "105"; }
            if(global.layerMode.range==='Yesterday'){ newRange = "105"; } //+++
            if(global.layerMode.range==='Last 7 days'){ newRange = "109"; }
            if(global.layerMode.range==='14 days'){ newRange = "117"; }
            if(global.layerMode.range==='30 days'){ newRange = "121"; }
            if(global.layerMode.range==='180 days'){ newRange = "137"; }
            if(global.layerMode.range==='365 days'){ newRange = ",141"; }
            if(global.layerMode.range==='YTD'){ newRange = "149"; }
        }

        if(global.layerMode.category === 'departure'){
            if(global.layerMode.range==='Today'){ newRange = "158"; }
            if(global.layerMode.range==='Yesterday'){ newRange = "158"; } //+++
            if(global.layerMode.range==='Last 7 days'){ newRange = "162"; }
            if(global.layerMode.range==='14 days'){ newRange = "170"; }
            if(global.layerMode.range==='30 days'){ newRange = "174"; }
            if(global.layerMode.range==='180 days'){ newRange = "190"; }
            if(global.layerMode.range==='365 days'){ newRange = "194"; }
            if(global.layerMode.range==='YTD'){ newRange = "202"; }
        }

        if(global.layerMode.category === 'percent'){
            if(global.layerMode.range==='Today'){ newRange = "211"; }
            if(global.layerMode.range==='Yesterday'){ newRange = "211"; } //+++
            if(global.layerMode.range==='Last 7 days'){ newRange = "215"; }
            if(global.layerMode.range==='14 days'){ newRange = "223"; }
            if(global.layerMode.range==='30 days'){ newRange = "227"; }
            if(global.layerMode.range==='180 days'){ newRange = "243"; }
            if(global.layerMode.range==='365 days'){ newRange = "247"; }
            if(global.layerMode.range==='YTD'){ newRange = "255"; }
        }

        if(global.layerMode.range==='Yesterday'){
            newDate = DateTime.now().setZone('America/New_York').minus({days: 1, hours: 9}).toISODate({ format: 'basic' });
            newDateB = DateTime.now().setZone('America/New_York').minus({days: 1, hours: 9}).toFormat('yyyy/MM/dd'); 
        }

        if(global.layerMode && global.layerMode.category){           
          newCategory = global.layerMode.category;
        }
    
        setCurrentUrl(`https://mapservices.weather.noaa.gov/raster/rest/services/obs/rfc_qpe/MapServer/export?bbox={bbox-epsg-3857}&format=png32&transparent=true&layers=show:${newRange}&f=image`)

        setCurrentRange(newRange)
        setCurrentCategory(newCategory)
      }
  }, [global.layerMode])


  return (
    <Source
      id={`${id}-layer`}
      key={`${id}-layer`+currentRange+currentCategory+((global.layerMode && global.layerMode.range) ? global.layerMode.range : '')}
      type="raster"
      tiles={[currentUrl]}
      tileSize={256} 
    >
      <Layer
       
       beforeId={settings.defaultBeforeId}
        paint={{ 'raster-opacity': opacity }}
        type="raster" id={`${id}-layer`}
        source={`${id}-  layer`}
       
      />
    </Source>
  )
}

export default NOAAMix