import React from 'react'
import { Layer, Source } from 'react-map-gl'
import { adjustHexBrightness } from "../../features/utils";
import { FillLayer } from 'mapbox-gl';

const HBALayer = ({ HBALevel, opacity, tileSet, HBAKind, brightness }: any) => {

    const kind = HBAKind === "standard" ? ["standard", adjustHexBrightness("#15616D", brightness)] : ["custom", adjustHexBrightness("#E2A96F", brightness)]

    const HBA: FillLayer = {
        id: `HBA`,
        type: "fill",
        "source-layer": `hydrobasins_africa`,
        paint: {
            "fill-outline-color": `#996515`,
            "fill-opacity": opacity,
            'fill-color': [
                "match",
                ["get", "kind"],
                ...kind,
                'transparent' 
            ],
        },
        filter: ['in', "level", parseInt(HBALevel)]
    };

  return (
    <Source id={`HBA`} type="vector" url={tileSet}>
        <Layer {...HBA} />
    </Source>
  )
}

export default HBALayer
