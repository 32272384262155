import { useState, useEffect, useRef, useContext } from 'react';
import settings from '../../../settings.json';
import { getData, getTweets, getHUCs } from '../../../api/getData';
import WaterQualityList from '../../../components/water-quality-list';
import TweetList from '../../../components/tweet-list';
import HucList from '../../../components/HucList';
import HUCMarkers from '../../../components/HucList/HucMarkers';
import RenderMarkers from '../../../components/RenderMarkers';
import RenderZipCodeMarkers from '../../../components/RenderZipCodeMarkers';
import LoadingSpinner from '../../../components/LoadingSpinner';
import SurfaceWaterMarkers from '../../../components/SurfaceWaterMarkers';
import AGTrueQIScores from '../../../components/AGTrueQIScores/AGTrueQIScores';
import StormTrueQI from '../../../components/StormTrueQI/StormTrueQI';
import { AppContext } from '../../../AppContext'
import { message_nodatalocation, message_unavailable} from '../../../Constants';
import _ from 'lodash';
import { CatalogsContext } from "../../../CatalogsProvider";
import { Expression, StyleFunction } from 'mapbox-gl';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { parse } from 'papaparse';
import agent from '../../../api/agent'
import {  
  adjustHexBrightness,
  adjustHSLABrightness,
  adjustRGBABrightness
} from "../../../features/utils";

const zipcodes = require('zipcodes');
const { abbr } = require('us-state-converter')

const RenderLayerMarkers = (props: any) => {
  const params = window.location.pathname === '/' ? false : true;

  const { global, bounds, zipOff = false, wq = false } = props;

  const [drinkingWaterQuality, setDrinkingWaterQuality] = useState<any[] | null>(null);
  const [wastewater, setWastewater] = useState<any[] | null>(null);
  const [industry, setIndustry] = useState<any[] | null>(null);
  const [h303DData, setH303DData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [tweets, setTweets] = useState<any[] | null>(null);
  const [top100, setTop100] = useState<any | null>(null);
  const [surfaceWaterScores, setSurfaceWaterScores] = useState()
  const [agriculturalWaterScores, setAgriculturalWaterScores] = useState()
  const [stormwaterScores, setStormwaterScores] = useState()

  const [mapExpressionArray, setMapExpressionArray] = useState<any[]>([]);
  const [mapExpression, setMapExpression] = useState<Expression>(['match', ['get', 'name'], 'WASHINGTON', '#cccccc', '#ffffff']);

  const { selectedHUC8 } = useContext<any>(AppContext)

  const [initialDataLoad, setInitialDataLoad] = useState<any>()

  const catalogsContext = useContext(CatalogsContext);

  useEffect(() => { 
    if (global.stormWaterTrueQI){
      global.setLoading(true)
      agent.Reports.StormwaterTrueQI().then(({ body }: any) => {
        setStormwaterScores(body)
      }).finally(() => global.setLoading(false))
    }
  }, [global.stormWaterTrueQI])

  useEffect(() => {
    if (global.agWaterTrueQI) {
      global.setLoading(true)
      agent.Reports.AgriculturalWaterTrueQI().then(({ body }: any) => {
        setAgriculturalWaterScores(body)
      }).finally(() => global.setLoading(false))
    }
  }, [global.agWaterTrueQI])

  const handlePopulationServed = (input: any) => {
    if (input === 'All') {
      setDrinkingWaterQuality(initialDataLoad)
    } else {
      const popFiltered: any = initialDataLoad?.filter((object: any) => input === object.popServed)
      setDrinkingWaterQuality(popFiltered)
    }
  }

  useEffect(() => {
    if (global.pollutionSelected && !top100) {
      getData(settings.apiMethods.Top100Polluters)
      .then(data => {
        setTop100(data.body);
        setLoading(false);
      });
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [global.pollutionSelected]);

  useEffect(() => {
    if (global.twitterSelected && !tweets) {
      getTweets().then(data => {
        setTweets(data.body);
        setLoading(false);
      });
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [global.twitterSelected]);

  useEffect(() => {
    if (global.surfacewaterTrueQI) {
      agent.Reports.SurfaceWater(selectedHUC8).then(({ body }: any) => {

        if (body && body.length === 0) {
          global.notify(message_nodatalocation, "info")
          setLoading(false);
          return;
        } else if (!body) {
          global.notify(message_unavailable, "info")
          setLoading(false);
          console.log('Error: ' + body, selectedHUC8, 'SurfaceWater')
          return;
        }
        setSurfaceWaterScores(body)
        setLoading(false);
      })
      setLoading(true)
    } else {
      setLoading(false);
    }

  }, [
    global.surfacewaterTrueQI,
    selectedHUC8
  ])

  useEffect(() => {
    global.popValue && handlePopulationServed(global.popValue)
  }, [global.popValue])

  useEffect(() => {
    if (global.drinkingWaterQualitySelected && !drinkingWaterQuality) {
      getData(settings.apiMethods.getDrinkingWaterTrueQI).then(data => {
        const zipified = data.body.map((element: any) => ({
          ...zipcodes.lookup(element['Zip Code']),
          huc8: element.Huc8,
          detailsTable: element["Details Table"],
          pwsid: element['Water Company'],
          score: element.Score,
          popServed: element['Population Served'],
          zip: element['Zip Code']
        }));

        console.log(data)
        createMapExpression(data.body, "score", 'HUC8', 'Huc8');

        setDrinkingWaterQuality(zipified);
        setInitialDataLoad(zipified);
        setLoading(false);
      });
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [global.drinkingWaterQualitySelected]);

  let calledOnce = useRef(false)

  useEffect(() => {
    if (calledOnce.current) {
      return
    }

    if (drinkingWaterQuality) {
      const pop: any = drinkingWaterQuality.map((element: any) => element.popServed);
      // @ts-ignore
      const popServed = pop && [...new Set(pop)]
      global.setPopSet(popServed)
      calledOnce.current = true
    }

  }, [drinkingWaterQuality])

  useEffect(() => {
    if (global.wastewaterSelected && !wastewater) {
      getHUCs(settings.apiMethods.getWastewaterTrueQI).then(data => {
        setWastewater(data.body);
        setLoading(false);
      });
      setLoading(true);
    }
  }, [global.wastewaterSelected]);

  useEffect(() => {
    if (global.industrySelected && !industry) {
      getHUCs(settings.apiMethods.getIndustryTrueQIScores).then(data => {
        setIndustry(data.body);
        setLoading(false);
      });
      setLoading(true);
    }
  }, [global.industrySelected]);


  useEffect(() => {
    if (global.h303DTrueQI && !h303DData) {
      const huc12InfoFileURL = '/csv/huc8_303d_scores_normed.csv';
      setLoading(true);
        fetch(huc12InfoFileURL)
          .then(response => response.text())
          .then(responseText => {
            const data:  any[] = parse(responseText, {
              header: true,
            }).data;
            
            let valueOfInterest = 'overall_score';
            let identifierField = 'HUC8';
            let matchingIdentifier = 'huc8'

            for (let dataItem of data) {
              let coord = catalogsContext.huc8Catalog.find((x) => x.huc8 == dataItem.huc8);
              dataItem.lat = coord["centroid_latitude"];
              dataItem.lng = coord["centroid_longitude"];
              dataItem.national_twpe_score = +dataItem[valueOfInterest];
            }  
            createMapExpression(data, valueOfInterest, identifierField, matchingIdentifier);

            setH303DData(data)
            setLoading(false);
          });
    }
  }, [global.h303DTrueQI]);


  const colorScale = (evalValue: number, colorScaleChromatic:string='interpolateRdYlGn' ) => {
    //@ts-ignore
    const colorValue = d3ScaleChromatic[colorScaleChromatic](evalValue);
    return colorValue;
  }

  const createMapExpression = (data:any,  valueOfInterest: string, identifierField: string, matchingIdentifier: string, colorScaleChromatic:string='interpolateRdYlGn') => {
    let matchExpressionZ= ['match', ['get', identifierField]] as Expression;
    let arrayAdded = [] as any;

    if(data && data.length>0){
        const valueExtent = d3Array.extent<any, any>(data, d => +d[valueOfInterest]);
        const legendScale = d3Scale.scaleLinear()
          .domain([valueExtent[0], valueExtent[1]])
          .range([0, 1]);

        for (let dataItem of data) {
          if(dataItem[valueOfInterest]) {
            const colorValue = colorScale(legendScale(+dataItem[valueOfInterest]), colorScaleChromatic);
            if (!isNaN(+dataItem[valueOfInterest]) && arrayAdded.indexOf(dataItem[matchingIdentifier]) < 0 ){
              matchExpressionZ.push(dataItem[matchingIdentifier]);
              matchExpressionZ.push(colorValue);
              arrayAdded.push(dataItem[matchingIdentifier]);
            }
          }
        } 

        matchExpressionZ.push('rgba(0,0,0,0)');
        setMapExpression(matchExpressionZ)                  
    }
  }


  return (
    <>
      {global.stormWaterTrueQI && stormwaterScores && (
        <StormTrueQI
          list={stormwaterScores}
          bounds={bounds}
          global={global}
          viewport={global.viewport}
          haloColor={'gray'}
        />
      )}

      {global.agWaterTrueQI && agriculturalWaterScores && (
        <AGTrueQIScores
          list={agriculturalWaterScores}
          bounds={bounds}
          global={global}
          viewport={global.viewport}
          haloColor={'green'}
        />
    )}
      {global.drinkingWaterQualitySelected && drinkingWaterQuality && !wq && (
        <WaterQualityList
          global={global}
          list={drinkingWaterQuality}
          bounds={bounds}
          viewport={global.viewport}
          setViewport={global.setViewport}
        />
      )}

      {global.twitterSelected && tweets && (
        <TweetList
          global={global}
          tweets={tweets}
          viewport={global.viewport}
          bounds={bounds}
          officialTweetsSelected={global.officialTweetsSelected}
          setofficialTweetsSelected={global.setofficialTweetsSelected}
          unofficialTweetsSelected={false}
          setUnofficialTweetsSelected={global.setUnofficialTweetsSelected}
          setOfficialTweetsPresent={global.setOfficialTweetsPresent}
          setUnOfficialTweetsPresent={global.setUnOfficialTweetsPresent}
        />
      )}


      {global.wastewaterSelected && wastewater && (
        <>
          <HucList
            opacity={0.3}
            data={wastewater}
            fillColor={'#CA9C1E'}
            outlineColor={'black'}
            switch={true}
          />
          <HUCMarkers
            global={global}
            data={wastewater}
            bounds={bounds}
            reportUrl={'/DischargeMonitoring'}
            viewport={global.viewport}
            color={'#daa520'}
          />
        </>
      )}

      {global.industrySelected && industry && (
        <>
          <HucList
            opacity={0.3}
            data={industry}
            fillColor={'#f04f23'}
            outlineColor={'black'}
            switch={true}
          />
          <HUCMarkers
            global={global}
            data={industry}
            bounds={bounds}
            viewport={global.viewport}
            reportUrl={'/DischargeMonitoring'}
            color={'#f04f23'}
          />
        </>
      )}

      {global.h303DTrueQI && h303DData && (
        <>
          <HucList
            opacity={global.OABRMXOpacity}
            data={h303DData}
            fillColor={'#bedb9c'}
            outlineColor={'black'}
            fillColorHUC8= {mapExpression}
            outlineColorHUC8={'black'}
            fillColorHUC12={"transparent"}
            outlineColorHUC12={'black'}
            switch={false}
            huc8ID={"h303DTrueQI"}
            huc12ID={"huc12s-line"}
            huc8only={true}
            global={global}
          />
          <HUCMarkers
            global={global}
            data={h303DData}
            bounds={bounds}
            viewport={global.viewport}
            reportUrl={'/WaterImpairementsLakes'}
            color={'#bedb9c'}
            huc8only={true}
            popupfieldsreflect={true}
          />
        </>
      )}


      {global.pollutionSelected && top100 && (
        <RenderMarkers list={top100} bounds={bounds} viewport={global.viewport} />
      )}

      {global.zipSelected && !zipOff && (
        <RenderZipCodeMarkers bounds={bounds} viewport={global.viewport} />
      )}

      {global.surfacewaterTrueQI && surfaceWaterScores && (
        <SurfaceWaterMarkers
          list={surfaceWaterScores}
          bounds={bounds}
          viewport={global.viewport}
          global={global}
        />
      )}

      {loading && params && <LoadingSpinner active={loading} style={{ top: '-50%' }} />}
      {loading && !params && <LoadingSpinner active={loading} />}
    </>
  );
};

export default RenderLayerMarkers;
  