// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ca-report-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  height: 100vh; }
  .ca-report-container .report-heading {
    margin-top: 1rem;
    width: 80vw;
    display: flex;
    justify-content: flex-start; }
    .ca-report-container .report-heading h3 {
      margin-left: 1.5rem;
      color: white; }
  .ca-report-container .map-container,
  .ca-report-container .graph-container {
    margin-top: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px;
    border-radius: 12px;
    background-color: #0B1736;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }
    .ca-report-container .map-container p,
    .ca-report-container .graph-container p {
      color: white;
      padding: 20px;
      margin: 10px; }
  .ca-report-container .graph-container h1 {
    color: white; }
`, "",{"version":3,"sources":["webpack://./src/layout/contact-admin.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa,EAAA;EANjB;IASQ,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,2BAA2B,EAAA;IAZnC;MAcY,mBAAmB;MACnB,YAAY,EAAA;EAfxB;;IAqBQ,gBAAgB;IAChB,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,4CAA4C,EAAA;IA/BpD;;MAiCY,YAAY;MACZ,aAAa;MACb,YAAY,EAAA;EAnCxB;IAwCa,YAAa,EAAA","sourcesContent":[".ca-report-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    font-size: 1.4em;\n    height: 100vh;\n   \n   .report-heading {\n        margin-top: 1rem;\n        width: 80vw;\n        display: flex;\n        justify-content: flex-start;\n        h3 {\n            margin-left: 1.5rem; \n            color: white; \n        }\n    }\n\n    .map-container,\n    .graph-container {\n        margin-top: 50px;\n        width: fit-content;\n        height: fit-content;\n        padding: 0px;\n        border-radius: 12px;\n        background-color: #0B1736;\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        align-items: center;\n        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n        p {\n            color: white;\n            padding: 20px;\n            margin: 10px;\n        }\n    }\n\n    .graph-container {\n        h1 { color: white }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
