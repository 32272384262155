
import "./App.scss";
import React, {
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
  useRef,
} from "react";
import { PointContext } from "./PointContext";
import { RefContext } from "./RefContext";
import { AppContext } from "./AppContext";
import { parse } from 'papaparse'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./theme/custom.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { DateTime } from 'luxon';
import NavigationBar from "./layout/navigationBar";
import SideBar from "./layout/sidebar";
import SideBarRight from "./layout/sidebar-right";
import { Row, Col, Alert, Image } from "react-bootstrap";
import Render from "./components/render";
import Chat from "./features/reports/Chat/Chat";
import Login from "./features/account/login";
import Register from "./features/account/register";
import AddProfile from "./features/users/add-profile";
import AddUser from "./features/users/add-user";
import AddOrganization from "./features/users/add-organization";
import UserInfo from "./features/users/userinfo";
import Users from "./features/users/users";
import Organizations from "./features/users/organizations";
import Settings from "./features/account/Settings";
import Profiles from "./features/account/Profiles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NationalLiveWaterQualityMap from "./features/reports/NationalLiveWaterQualityMap/NationalLiveWaterQualityMap";
import WaterStatisticsMeasuresReport from "./features/reports/WaterStatisticsMeasures/WaterStatisticsMeasures";
import DailyLakeMeadLevelsReport from "./features/reports/DailyLakeMeadLevels/DailyLakeMeadLevelsReport";
import WastewaterTrueQIScoresReport from "./features/reports/WastewaterTrueQIScores/WastewaterTrueQIScoresReport";
import WaterImpairmentsReport from "./features/reports/WaterImpairmentsReport/WaterImpairmentsReport";
import WhatsInMySurfaceWater from "./features/reports/WhatsInMySurfaceWater/WhatsInMySurfaceWater";
import WhatsInMyWatershed from "./features/reports/WhatsInMyWatershed/WhatsInMyWatershed";
import AGWaterTrueQIForecast from "./features/reports/AGWaterTrueQIForecast/AGWaterTrueQIForecast";
import StormWaterTrueQIForecast from "./features/reports/StormWaterTrueQIForecast/StormWaterTrueQIForecast";
import DischargeMonitoring from "./features/reports/DischargeMonitoring/DischargeMonitoring";
import PermitDischargeReport from "./features/reports/PermitDischargeReport/PermitDischargeReport";
import GroundwaterLevels from "./features/reports/GroundwaterLevels/GroundwaterLevels";
import WhatsInMyDrinkingWater from "./features/reports/WhatsInMyDrinkingWater/WhatsInMyDrinkingWater";
import PfasEnvironmental from "./features/reports/PfasEnvironmental/PfasEnvironmental";
import PfasDrinkingWater from "./features/reports/PfasDrinkingWater/PfasDrinkingWater";
import PfasProduction from "./features/reports/PfasProduction/PfasProduction";
import RiskScreening from "./features/reports/RiskScreening/RiskScreening";
import AustralianBoreData from "./features/reports/AustralianBoreData/AustralianBoreData";
import ClimatePrecipitationCalculator from "./features/reports/ClimatePrecipitationCalculator/ClimatePrecipitationCalculator";
import GroundwaterDetermination from "./features/reports/GroundwaterDetermination/GroundwaterDetermination";
import Risk from "./features/reports/Risk/Risk";
import SurfaceWaterFlowRate from "./features/reports/SurfaceWaterFlowRate/SurfaceWaterFlowRate";
import countries from "./json/country_centroids.json";
import states from "./json/state_centroids.json";
import GroundwaterMexico from "./features/reports/GroundwaterMexico/GroundwaterMexico";
import NationalStreamQualityAccountingNetwork from "./features/reports/NationalStreamQualityAccountingNetwork/NationalStreamQualityAccountingNetwork";
import DrinkingWaterTrueQIReport from "./features/reports/DrinkingWaterTrueQIReport/DrinkingWaterTrueQIReport";
import WaterMonitoring from "./features/reports/WaterMonitoring";
import CWAReport from "./features/reports/CWAReport/CWAReport";
import WaterQuality from "./features/reports/WaterMonitoring/WaterQuality";
import AppContextProvider from "./AppContext";
import PointContextProvider from "./PointContext";
import LayerDataContextProvider from "./LayerDataContext";
import ActivePopupContextProvider from "./ActivePopupContext";
import RefContextProvider from "./RefContext";
import IAppContext from "./IAppContext";
import IRefContext from "./IRefContext";
import ViolationDataReport from "./features/reports/ViolationDataReport/ViolationDataReport";
import TableReport from "./features/reports/TableReport/TableReport";
import FIBTableReport from "./features/reports/FIBTableReport/FIBTableReport";
import NSGReport from "./features/reports/NSGReport/NSGReport";
import WiseReport from "./features/reports/WISEReport/WiseReport";
import MECReport from "./features/reports/MECReport/MECReport";
import {
  LOCALSTORAGE_DEFAULTS_KEY,
  REPORT_KEY,
  message_welcome,
  message_welcome_newuser,
} from "./Constants";
import LoadingBar from "./components/LoadingBar/LoadingBar";
import {
  getDefaultsFromLocalStorage,
  getDefaultHuc8,
  getDefaultLocation,
  putReport,
  getViewportFromStorage,
  putViewportIntoStorage,
  putCountryIntoStorage,
  putStateIntoStorage,
  getCountryFromStorage,
  getStateFromStorage,
  putCustomLocationDataIntoStorage,
  putCustomLayerNamesIntoStorage,
  getCustomLayerNamesFromStorage,
  handleFibColor
} from "./features/utils";
import CatalogsContextWrapper from "./CatalogsProvider";
import ToxicsRealeaseInventory from "./features/reports/ToxicReleaseInventoryReport/ToxicsReleaseInventoryReport";
import { ToxicsReleaseInvetoryResponseBodyModel } from "./api/models";
import BaseReport from "./components/BaseReport/BaseReport";
import Globals from "./types/GlobalsType";
import settings from "./settings.json";
import LoadingSpinner from "./components/LoadingSpinner";
import IPointContext from "./IPointContext";
import ILayerDataContext from "./ILayerDataContext";
import ReportsRoutes from "./ReportsRoutes";
import NationalStormWaterQualityDatabase from "./features/reports/NationalStormWaterQualityDatabase/NationalStormWaterQualityDatabase";
import agent from "./api/agent";
import { useKeycloak } from "@react-keycloak/web";
import { getNewUserAlert, setNewUserAlert } from "./utils/store";
import LiveWaterSensors from "./features/reports/LiveWaterSensors/LiveWaterSensors";
import TimeReferenceUnit from "./types/TimeReferenceUnits";
import FacilityRegistrySystem from "./features/reports/FacilityRegistrySystem/FacilityRegistrySystem";
import EPAMajorPermitHolders from "./features/reports/EPAMajorPermitHolders/EPAMajorPermitHolders";
import Dashboard from "./features/reports/Dashboard/Dashboard";
import WorkflowControl from "./features/reports/Workflow/WorkControl";
import WorkStart from "./features/reports/Workflow/WorkStart";
import Footer from "./layout/Footer";
import ContactAdmin from "./layout/ContactAdmin";
import ContactForm from "./layout/ContactForm";
import QueryString from "./utils/QueryString";
import UserInfoSession from "./features/account/UserInfoSession";
import { QueryParamProvider } from "use-query-params";
import MexicoReport from "./features/reports/MexicoReport/MexicoReport";
import ChemicalDataReporting from "./features/reports/ChemicalDataReporting/ChemicalDataReporting";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import GoogleMapsDemo from "./features/reports/GoogleMapsDemo/GoogleMapsDemo";
import GemstatRaw from "./features/reports/GemstatRaw/GemstatRaw";
import MenuGemstatRaw from "./features/reports/GemstatRaw/MenuGemstatRaw";
import LayerDataReport from "./features/reports/LayerDataReport/LayerDataReport";
import CEJSTReport from "./features/reports/CEJSTReport/CEJSTReport";
import FIBReport from "./features/reports/FIBReport/FIBReport";
import GFSHuc12Forecast from "./features/reports/GFSHuc12Forecast/GFSHuc12Forecast";
import help_WQP from "./assets/help_WQP.gif";
import help_WQP_button from "./assets/help_WQP_button.png";
import { useLoadScript } from "@react-google-maps/api";
import Splash from "./layout/Splash/Splash";
import keycloak from "./keycloak";
import customerRestrictions from "./features/account/customerRestrictions.json";
import RoleRestricted from "./features/account/roleRestrictions";


interface Route {
  route: string;
  identifier: string;
}

interface CustomerRestriction {
  name: string;
  routes: Route[];
}

function CustomerRestricted({ children, userOrgString, path, global }: any) {
  const search = window.location.search;

  return children;
  /*  useEffect(() => {
      global.setUserLogged(true)
    }, [])*/

  if (userOrgString === "True Elements") {
    return children;
  }

  const matchingRestriction = customerRestrictions.find(
    ({ name }) => name === userOrgString
  );

  if (matchingRestriction) {
    // @ts-ignore
    const { routes } = matchingRestriction;
    // @ts-ignore
    const matchingRoute = routes.find(
      // @ts-ignore
      (route: Route) => route.route === path
    );

    if (matchingRoute) {
      if (matchingRoute.identifier) {
        if (search.includes(matchingRoute.identifier)) {
          return children;
        } else {
          return <ContactAdmin global={global} logged={true} />;
        }
      }
      return children;
    } else {
      return <ContactAdmin global={global} logged={true} />;
    }
  }

  return null;
}

function ProtectedRoute({ children }: PropsWithChildren<any>) {
  const { keycloak } = useKeycloak();
  if (
    !keycloak?.authenticated &&
    (!sessionStorage.getItem("authKey") ||
      sessionStorage.getItem("authKey") === "undefined")
  ) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
}

function App() {
  const savedMapStyle = localStorage.getItem('settings_mapstyle');
  const currentRoute: any = window.location.pathname
  const [splashCount, setSplashCount] = useState<number>(0);
  const [menuRightOpen, setMenuRightOpen] = useState(false);
  const [googleMenuOpen, setGoogleMenuOpen] = useState(false);
  const [ABWDLayer, setABWDLayer] = useState(false);
  const [ABWDLayerOpacity, setABWDLayerOpacity] = useState(0.5);
  const [ABWDLayerBrightness, setABWDLayerBrightness] = useState(0);

  const [wrfcRisk, setWRFCRisk] = useState(false);
  const [wrfcRiskOpacity, setWRFCRiskOpacity] = useState(0.5);
  const [wrfcRiskBrightness, setWRFCRiskBrightness] = useState(0);

  const [currentLayerData, setCurrentLayerData] = useState<object>({});
  const [resultsDataset, setResultsDataset] = useState<any[]>([]); 
  const [clickedFeatures, setClickedFeatures] = useState<any[]>([]);

  

  const [authenticatedUser, setAuthenticatedUser] = useState(
    !(
      !sessionStorage.getItem("authKey") ||
      sessionStorage.getItem("authKey") === "undefined"
    )
  );
  const [userRoleString, setUserRoleString] = useState();
  const [userOrgString, setUserOrgString] = useState("none");
  const [userLogged, setUserLogged] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userRole, setUserRole] = useState("User");
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [userOrg, setUserOrg] = useState(0);
  const [guiSwitch, setGuiSwitch] = useState("");

  const [resMask, setResMask] = useState(false);
  const [hucsSelected, setHucsSelected] = useState(false);
  const [hucsSelectedOpacity, setHucsSelectedOpacity] = useState(0.5);
  const [hucsSelectedBrightness, setHucsSelectedBrightness] = useState(0);

  const [gwNitrateAssessmentSelected, setGwNitrateAssessmentSelected] =
    useState(false);
  const [
    gwNitrateAssessmentSelectedOpacity,
    setGwNitrateAssessmentSelectedOpacity,
  ] = useState(0.5);
  const [
    gwNitrateAssessmentSelectedBrightness,
    setGwNitrateAssessmentSelectedBrightness,
  ] = useState(0);

  const [outflowsSelected, setOutflowsSelected] = useState(false);
  const [outflowsSelectedOpacity, setOutflowsSelectedOpacity] = useState(0.5);
  const [outflowsSelectedBrightness, setOutflowsSelectedBrightness] = useState(0);

  const [nhdSelected, setNhdSelected] = useState(false);
  const [nhdSelectedOpacity, setNhdSelectedOpacity] = useState(0.5);
  const [nhdSelectedBrightness, setNhdSelectedBrightness] = useState(0);

  const [hoursIndex, setHoursIndex] = useState(0);

  const [userNotifications, setUserNotifications] = useState<any[]>([]);
  const [hucRestrict, setHucRestrict] = useState<any[]>([]);
  const [reportRestrict, setReportRestrict] = useState<any[]>([]);
  const [permitReports, setPermitReports] = useState<any[]>([
    ReportsRoutes.ClimatePrecipitationCalculator,
    ReportsRoutes.NationalStormWaterQualityDatabase2020,
    ReportsRoutes.RiskScreening,
    ReportsRoutes.NationalStreamQualityAccountingNetwork,
  ]);
  const [settingsDoNotDisturb, setSettingsDoNotDisturb] = useState(false);
  const [dashboardProjects, setDashboardProjects] = useState<any[]>([]);
  const [selectedDashboardProject, setSelectedDashboardProject] = useState("");
  const [selectedDashboardProjectLinks, setSelectedDashboardProjectLinks] =
    useState<any[]>([]);
  const [showWorkflow, setShowWorkflow] = useState(false);
  const [customLocations, setCustomLocations] = useState<any[]>([]);
  const [twitterSelected, setTwitterSelected] = useState(false);
  const [resFEMA, setResFEMA] = useState(false);
  const [drinkingWaterQualitySelected, setDrinkingWaterQualitySelected] =
    useState(false);
  const defaultLocalStorageObject = getDefaultsFromLocalStorage();

  const [selectedHUC8, setSelectedHUC8] = useState('');

  const [currentLocation, setCurrentLocation] = useState(
    getDefaultLocation(defaultLocalStorageObject)
  );
  const [currentCountry, setCurrentCountry] = useState<string>("United States");

  const [MoBiPSR, setMoBiPSR] = useState(false);
  const [MoBiPSROpacity, setMoBiPSROpacity] = useState(0.5);

  const [MoBiSRAll, setMoBiSRAll] = useState(false);
  const [MoBiSRAllOpacity, setMoBiSRAllOpacity] = useState(0.5);

  const [MoBiSRA, setMoBiSRA] = useState(false);
  const [MoBiSRAOpacity, setMoBiSRAOpacity] = useState(0.5);

  const [MoBiPI, setMoBiPI] = useState(false);
  const [MoBiPIOpacity, setMoBiPIOpacity] = useState(0.5);

  const [MoBiV, setMoBiV] = useState(false);
  const [MoBiVOpacity, setMoBiVOpacity] = useState(0.5);
  const [activeLayerList, setActiveLayerList] = useState<any[]>([]);

  const [OABID, setOABID] = useState(false)
  const [OABIDOpacity, setOABIDOpacity] = useState(0.5)
  const [OABIDBrightness, setOABIDBrightness] = useState(0)

  const [OABUS, setOABUS] = useState(false)
  const [OABUSOpacity, setOABUSOpacity] = useState(0.5)
  const [OABUSBrightness, setOABUSBrightness] = useState(0)

  const [OABPH, setOABPH] = useState(false)
  const [OABPHOpacity, setOABPHOpacity] = useState(0.5)
  const [OABPHBrightness, setOABPHBrightness] = useState(0)

  const [OABMX, setOABMX] = useState(false)
  const [OABMXOpacity, setOABMXOpacity] = useState(0.5)
  const [OABMXBrightness, setOABMXBrightness] = useState(0)

  const [OGWTD, setOGWTD] = useState(false)
  const [OGWTDOpacity, setOGWTDOpacity] = useState(0.5)
  const [OGWTDBrightness, setOGWTDBrightness] = useState(0)

  const [SkyFiAustin, setSkyFiAustin] = useState(false)
  const [SkyFiAustinOpacity, setSkyFiAustinOpacity] = useState(0.5)

  const [SkyFiWhiting, setSkyFiWhiting] = useState(false)
  const [SkyFiWhitingOpacity, setSkyFiWhitingOpacity] = useState(0.5)

  const [OWQPDemo, setOWQPDemo] = useState(false)
  const [OWQPDemoOpacity, setOWQPDemoOpacity] = useState(0.5)
  const [OWQPDemoBrightness, setOWQPDemoBrightness] = useState(0)

  const [MBFIBDT, setMBFIBDT] = useState(false)
  const [MBFIBDTOpacity, setMBFIBDTOpacity] = useState(0.5)
  const [MBFIBDTBrightness, setMBFIBDTBrightness] = useState(0)

  const [NSG, setNSG] = useState(false)
  const [NSGOpacity, setNSGOpacity] = useState(0.5)
  const [NSGBrightness, setNSGBrightness] = useState(0)

  const [MEC, setMEC] = useState(false)
  const [MECOpacity, setMECOpacity] = useState(0.5)
  const [MECBrightness, setMECBrightness] = useState(0)
  const [MECDates, setMECDates] = useState<any[]>([])
  const [MECStartDate, setMECStartDate] = useState('')
  const [MECEndDate, setMECEndDate] = useState('')

  const [GGDE, setGGDE] = useState(false)
  const [GGDEOpacity, setGGDEOpacity] = useState(0.5)


  const [appleLocs, setAppleLocs] = useState()

  const [
    NationalWaterImpairementsOpacity,
    setNationalWaterImpairementsOpacity,
  ] = useState(0.5);

  const [
    NationalWaterImpairementsBrightness,
    setNationalWaterImpairementsBrightness,
  ] = useState(0);

  const [NationalWaterImpairements, setNationalWaterImpairements] =
    useState(false);

  const [streams, setStreams] = useState(true);
  const [lakes, setLakes] = useState(true);
  const [points, setPoints] = useState(true);

  useEffect(() => {
    const huc8: any = getDefaultHuc8(defaultLocalStorageObject)
    if(huc8) setSelectedHUC8(huc8)
  }, [getDefaultHuc8(defaultLocalStorageObject)])

  useEffect(() => {
    const lastCountry = getCountryFromStorage();
    const lastState = getStateFromStorage();
    if (lastCountry) setCurrentCountry(lastCountry);
    if (lastState) setTimeout(() => setCurrentState(lastState), 300)
  }, []);

  useEffect(() => {
    return () => {
      putCountryIntoStorage(currentCountry);
      putStateIntoStorage(currentState);
    };
  }, []);

  const [searchCenter, setSearchCenter] = useState<any[]>([]);

  const [surfacewaterTrueQI, setSurfacewaterTrueQI] = useState(false);
  const [biaLayer, setBIALayer] = useState(false);
  const [biaLayerOpacity, setBIALayerOpacity] = useState(0.5);
  const [biaLayerBrightness, setBIALayerBrightness] = useState(0);

  const [filter1Selected, setfilter1Selected] = useState("");
  const [filter1Populated, setfilter1Populated] = useState<any[]>([]);
  const [filterDaysSelected, setfilterDaysSelected] = useState(0);
  const [timeReferenceUnit, setTimeReferenceUnit] =
    useState<TimeReferenceUnit>(3);
  const [filterLocationsSelected, setfilterLocationsSelected] = useState<any[]>(
    []
  );
  const [filterLocationsPopulated, setfilterLocationsPopulated] = useState<
    any[]
  >([]);
  const [industrySectorFilterPopulated, setIndustrySectorFilterPopulated] =
    useState<ToxicsReleaseInvetoryResponseBodyModel[]>([]);
  const [selectedIndustrySector, setSelectedIndustrySector] = useState<
    string[]
  >([]);
  const [selectedYearFilter, setSelectedYearFilter] = useState<string>("");
  const [selectedYearFilterOptions, setSelectedYearFilterOptions] = useState<
    string[]
  >([""]);
  const [yearsSelected, setYearsSelected] = useState<string[]>([]);
  const [dateRangeSelected, setDateRangeSelected] = useState<Date[]>([
    new Date(new Date().setDate(new Date().getDate() - 5)),
    new Date(),
  ]);
  const [chemicalsSelected, setChemicalsSelected] = useState<string[]>([]);
  const [chemicalsFilerOptions, setChemicalsFilerOptions] = useState<string[]>(
    []
  );
  const [industrySelected, setIndustrySelected] = useState(false);
  const [filterBoundingBox, setFilterBoundingBox] = useState<any[]>([]);
  const [filterPolygons, setFilterPolygons] = useState<any[]>([]);
  const [
    selectedMeasurementFilterOptions,
    setSelectedMeasurementFilterOptions,
  ] = useState<string[]>([]);
  const [selectedMeasurementFilterValue, setSelectedMeasurementFilterValue] =
    useState<string>("Streamflow");
  const [layerClimateFill, setLayerClimateFill] = useState<boolean>(true);
  const [wastewaterSelected, setWastewaterSelected] = useState(false);
  const [pollutionSelected, setPollutionSelected] = useState(false);
  const [zipSelected, setZipSelected] = useState(false);
  const [zipOpacity, setZipOpacity] = useState(0.5);
  const [zipBrightness, setZipBrightness] = useState(0);

  const [droughtMonitoringSelected, setDroughtMonitoringSelected] =useState(false);
  const [droughtMonitoringOpacity, setDroughtMonitoringOpacity] = useState(0.5);
  const [droughtMonitoringBrightness, setDroughtMonitoringBrightness] = useState(0);

  const [principalAquifersSelected, setPrincipalAquifersSelected] = useState(false);
  const [principalAquifersOpacity, setPrincipalAquifersOpacity] = useState(0.5);
  const [principalAquifersBrightness, setPrincipalAquifersBrightness] = useState(0)

  const [domesticWellsSelected, setDomesticWellsSelected] = useState(false);
  const [domesticWellsOpacity, setDomesticWellsOpacity] = useState(0.5);
  const [domesticWellsbrightness, setDomesticWellsbrightness] = useState(0);

  const [WaterConflict, setWaterConflict] = useState(false);
  const [WaterConflictOpacity, setWaterConflictOpacity] = useState(0.5);
  const [WaterConflictBrightness, setWaterConflictBrightness] = useState(0);

  const [currentPoint, setCurrentPoint] = useState<object>({});
  const [checkFilters, setCheckFilters] = useState<any>([]);
  const [checkFilter, setCheckFilter] = useState<any>([]);
  const [terraClimateLayerSelected, setTerraClimateLayerSelected] =
    useState(false);
  const [
    terraClimateLayerSelectedOpacity,
    setTerraClimateLayerSelectedOpacity,
  ] = useState(0.5);

  const [
    terraClimateLayerSelectedBrightness,
    setTerraClimateLayerSelectedBrightness,
  ] = useState(0);

  const [MTCLValue, setMTCLValue] = useState<number>(2019);
  const [globeView, setGlobeView] = useState(false);
  const [currentRef, setCurrentRef] = useState({});
  const [popSet, setPopSet] = useState<any[] | null>(null);

  const [philRegions, setPhilRegions] = useState(false);
  const [philRegionsOpacity, setPhilRegionsOpacity] = useState(0.5);
  const [philRegionsBrightness, setPhilRegionsBrightness] = useState(0);

  const [OABRID, setOABRID] = useState(false)
  const [OABRIDOpacity, setOABRIDOpacity] = useState(0.5)

  const [OABRPH, setOABRPH] = useState(false)
  const [OABRPHOpacity, setOABRPHOpacity] = useState(0.5)
  const [OABRPHBrightness, setOABRPHBrightness] = useState(0)

  const [OABRPK, setOABRPK] = useState(false)
  const [OABRPKOpacity, setOABRPKOpacity] = useState(0.5)
  const [OABRPKBrightness, setOABRPKBrightness] = useState(0)

  const [OABRCA, setOABRCA] = useState(false)
  const [OABRCAOpacity, setOABRCAOpacity] = useState(0.5)
  const [OABRCABrightness, setOABRCABrightness] = useState(0)

  const [OABRMX, setOABRMX] = useState(false)
  const [OABRMXOpacity, setOABRMXOpacity] = useState(0.5)
  const [OABRMXBrightness, setOABRMXBrightness] = useState(0)

  const [OABRSGOpacity, setOABRSGOpacity] = useState(0.5)
  const [OABRSG, setOABRSG] = useState(false)

  const [OABSPH, setOABSPH] = useState(false)
  const [OABSPHOpacity, setOABSPHOpacity] = useState(0.5)
  const [OABSPHBrightness, setOABSPHBrightness] = useState(0)
  
  const [OABSMX, setOABSMX] = useState(false)
  const [OABSMXOpacity, setOABSMXOpacity] = useState(0.5)
  const [OABSMXBrightness, setOABSMXBrightness] = useState(0)

  const [OABSID, setOABSID] = useState(false)
  const [OABSIDOpacity, setOABSIDOpacity] = useState(0.5)
  const [OABSIDBrightness, setOABSIDBrightness] = useState(0)

  const [OABSCABrightness, setOABSCABrightness] = useState(0)
  const [OABSCAOpacity, setOABSCAOpacity] = useState(0.5)
  const [OABSCA, setOABSCA] = useState(false)

  const [OABSPKBrightness, setOABSPKBrightness] = useState(0)
  const [OABSPKOpacity, setOABSPKOpacity] = useState(0.5)
  const [OABSPK, setOABSPK] = useState(false)

  const [OABSSGBrightness, setOABSSGBrightness] = useState(0)
  const [OABSSGOpacity, setOABSSGOpacity] = useState(0.5)
  const [OABSSG, setOABSSG] = useState(false)

  const [USGSHuc12Brightness, setUSGSHuc12Brightness] = useState(0)
  const [USGSHuc12Opacity, setUSGSHuc12Opacity] = useState(0.5)
  const [USGShuc12DateString, setUSGShuc12DateString] = useState("1/2016")
  const [USGSHuc12, setUSGSHuc12] = useState(false)

  const [USGSYear, setUSGSYear] = useState('2016')

  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedStateCoordinates, setSelectedStateCoordinates] = useState<
    object | null
  >(null);
  const [ejYear, setEJYear] = useState(2021);

  const [WDPA, setWDPA] = useState(false);
  const [WDPAOpacity, setWDPAOpacity] = useState(0.5);
  const [WDPABrightness, setWDPABrightness] = useState(0);

  const [loading, setLoading] = useState(false);

  const [dates, setDates] = useState<null | object>({
    startDate: `${
      new Date().getMonth() - 1
    }/${new Date().getDate()}/${new Date().getFullYear()}`,
    endDate: `${
      new Date().getMonth() + 1
    }/${new Date().getDate()}/${new Date().getFullYear()}`,
  });

  const [filterSICCodesSelected, setFilterSICCodesSelected] = useState<any[]>(
    []
  );
  const [filterSICCodesFitlerOptions, setFilterSICCodesFitlerOptions] =
    useState<any[]>([]);
  const [evapotranspirationSelected, setEvapotranspirationSelected] =
    useState(false);
  const [
    evapotranspirationSelectedOpacity,
    setEvapotranspirationSelectedOpacity,
  ] = useState(0.5);

  const [
    evapotranspirationSelectedBrightness,
    setEvapotranspirationSelectedBrightness,
  ] = useState(0);

  const [EJUnemploymentSelected, setEJUnemploymentSelected] = useState(false);
  const [EJUnemploymentSelectedOpacity, setEJUnemploymentSelectedOpacity] = useState(0.5);
  const [EJUnemploymentSelectedBrightness, setEJUnemploymentSelectedBrightness] = useState(0);

  const [EJDemoSelected, setEJDemoSelected] = useState(false);
  const [EJDemoSelectedOpacity, setEJDemoSelectedOpacity] = useState(0.5);
  const [EJDemoSelectedBrightness, setEJDemoSelectedBrightness] = useState(0);

  const [EJLowIncomeSelected, setEJLowIncomeSelected] = useState(false);
  const [EJLowIncomeSelectedOpacity, setEJLowIncomeSelectedOpacity] = useState(0.5);
  const [EJLowIncomeSelectedBrightness, setEJLowIncomeSelectedBrightness] = useState(0);

  const [EJPOCSelected, setEJPOCSelected] = useState(false);
  const [EJPOCSelectedOpacity, setEJPOCSelectedOpacity] = useState(0.5);
  const [EJPOCSelectedBrightness, setEJPOCSelectedBrightness] = useState(0);

  const [EJLingIsoSelected, setEJLingIsoSelected] = useState(false);
  const [EJLingIsoSelectedOpacity, setEJLingIsoSelectedOpacity] = useState(0.5);
  const [EJLingIsoSelectedBrightness, setEJLingIsoSelectedBrightness] = useState(0);

  const [GBIF, setGBIF] = useState(false);
  const [GBIFOpacity, setGBIFOpacity] = useState(0.5);
  const [GBIFBrightness, setGBIFBrightness] = useState(0);

  const [EJLessHSSelected, setEJLessHSSelected] = useState(false);
  const [EJLessHSSelectedOpacity, setEJLessHSSelectedOpacity] = useState(0.5);
  const [EJLessHSSelectedBrightness, setEJLessHSSelectedBrightness] = useState(0);

  const [EJFiveAndUnderAgeSelected, setEJFiveAndUnderAgeSelected] =
    useState(false);
  const [
    EJFiveAndUnderAgeSelectedOpacity,
    setEJFiveAndUnderAgeSelectedOpacity,
  ] = useState(0.5);
  const [
    EJFiveAndUnderAgeSelectedBrightness,
    setEJFiveAndUnderAgeSelectedBrightness,
  ] = useState(0);


  const [EJSixtyFourAndOverSelected, setEJSixtyFourAndOverSelected] =
    useState(false);
  const [
    EJSixtyFourAndOverSelectedOpacity,
    setEJSixtyFourAndOverSelectedOpacity,
  ] = useState(0.5);
  const [
    EJSixtyFourAndOverSelectedBrightness,
    setEJSixtyFourAndOverSelectedBrightness,
  ] = useState(0);

  const [EJWasteWaterDischarge, setEJWasteWaterDischarge] = useState(false);
  const [EJWasteWaterDischargeOpacity, setEJWasteWaterDischargeOpacity] = useState(0.5);
  const [EJWasteWaterDischargeBrightness, setEJWasteWaterDischargeBrightness] = useState(0);

  const [EJUndergroundStorageTanks, setEJUndergroundStorageTanks] =
    useState(false);
  const [EJUndergroundStorageTanksOpacity, setEJUndergroundStorageTanksOpacity] = useState(0.5);
  const [EJUndergroundStorageTanksBrightness, setEJUndergroundStorageTanksBrightness] = useState(0);

  const [EJHazardousWaste, setEJHazardousWaste] = useState(false);
  const [EJHazardousWasteOpacity, setEJHazardousWasteOpacity] = useState(0.5);
  const [EJHazardousWasteBrightness, setEJHazardousWasteBrightness] = useState(0);

  const [EJRMP, setEJRMP] = useState(false);
  const [EJRMPOpacity, setEJRMPOpacity] = useState(0.5);
  const [EJRMPBrightness, setEJRMPBrightness] = useState(0);

  const [EJSuperfund, setEJSuperfund] = useState(false);
  const [EJSuperfundOpacity, setEJSuperfundOpacity] = useState(0.5);
  const [EJSuperfundBrightness, setEJSuperfundBrightness] = useState(0);

  const [unregulatedReport, setUnregulatedReport] = useState(false);

  const [agWaterTrueQI, setAgWaterTrueQI] = useState(false);
  const [stormWaterTrueQI, setStormWaterTrueQI] = useState(false);
  const [h303DTrueQI, setH303DTrueQI] = useState(false);

  const [I10Huc12s, setI10Huc12s] = useState(false);
  const [I10Huc12sOpacity, setI10Huc12sOpacity] = useState(0.5);
  const [I10Huc12sBrightness, setI10Huc12sBrightness] = useState(0);

  const [cookieTrailState, setCookieTrailState] = useState<string>("");

  const [HUCsMexico, setHUCsMexico] = useState(false);
  const [HUCsMexicoOpacity, setHUCsMexicoOpacity] = useState(0.5);

  const [officialTweetsSelected, setofficialTweetsSelected] = useState(true);
  const [unofficialTweetsSelected, setUnofficialTweetsSelected] =
    useState(true);
  const [officialTweetsPresent, setOfficialTweetsPresent] = useState(false);
  const [unofficialTweetsPresent, setUnOfficialTweetsPresent] = useState(false);
  const [waterSystemBoundariesSelected, setWaterSystemBoundariesSelected] =
    useState(false);

  const [reportNames, setReportNames] = useState();
  const [reportNamesSelected, setReportNamesSelected] = useState([]);

  const [countryInit, setCountryInit] = useState<any>();

  const [trackerOn, setTrackerOn] = useState<any>(false);
  const [mouseData, setMouseData] = useState<any>();
  const [showPopup, setShowPopup] = useState<any>(false);
  const [philippinesRiverBasins, setPhilippinesRiverBasins] =
    useState<any>(false);
  const [philippinesRiverBasinsOpacity, setPhilippinesRiverBasinsOpacity] =
    useState<any>(0.5);
    const [philippinesRiverBasinsBrightness, setPhilippinesRiverBasinsBrightness] =
    useState<any>(0);

  const [wildFireBoundaries, setWildFireBoundaries] = useState(false);
  const [wildFireBoundariesOpacity, setWildFireBoundariesOpacity] = useState(0.5);
  const [wildFireBoundariesBrightness, setWildFireBoundariesBrightness] = useState(0);

  const [fireYear, setFireYear] = useState<number>(1800);

  const [aqueductAtlas, setAqueductAtlas] = useState<any>(false);
  const [rainfallForecast, setRainfallForecast] = useState(false);
  const [rainfallForecastOpacity, setRainfallForecastOpacity] = useState(0.5);
  const [rainfallForecastBrightness, setRainfallForecastBrightness] = useState(0);
  const [rainfallDay, setRainfallDay] = useState<number>(1);
  const [AAV4WaterStress, setAAV4WaterStress] = useState(false);
  const [AAV4WaterStressOpacity, setAAV4WaterStressOpacity] = useState(0.5);
  const [AAV4WaterStressBrightness, setAAV4WaterStressBrightness] = useState(0);

  const [AAV4CoastalFloodRisk, setAAV4CoastalFloodRisk] = useState(false);
  const [AAV4CoastalFloodRiskOpacity, setAAV4CoastalFloodRiskOpacity] =
    useState(0.5);
  const [AAV4CoastalFloodRiskBrightness, setAAV4CoastalFloodRiskBrightness] =
    useState(0);

  const [AAV4RiverineFloodRisk, setAAV4RiverineFloodRisk] = useState(false);
  const [AAV4RiverineFloodRiskOpacity, setAAV4RiverineFloodRiskOpacity] =
    useState(0.5);
  const [AAV4RiverineFloodRiskBrightness, setAAV4RiverineFloodRiskBrightness] =
    useState(0);

  const [aqueductAtlasCoastalFlood, setAqueductAtlasCoastalFlood] =
    useState<any>(false);
  const [
    aqueductAtlasCoastalFloodOpacity,
    setAqueductAtlasCoastalFloodOpacity,
  ] = useState<any>(0.5);
  const [
    aqueductAtlasCoastalFloodBrightness,
    setAqueductAtlasCoastalFloodBrightness,
  ] = useState<any>(0);

  const [aqueductAtlasWaterStress, setAqueductAtlasWaterStress] =
    useState<any>(false);
  const [aqueductAtlasWaterStressOpacity, setAqueductAtlasWaterStressOpacity] =
    useState<any>(0.5);
    const [aqueductAtlasWaterStressBrightness, setAqueductAtlasWaterStressBrightness] =
    useState<any>(0);

  const [aqueductAtlasRiverine, setAqueductAtlasRiverine] =
    useState<any>(false);
  const [aqueductAtlasRiverineOpacity, setAqueductAtlasRiverineOpacity] =
    useState<any>(0.5);
    const [aqueductAtlasRiverineBrightness, setAqueductAtlasRiverineBrightness] =
    useState<any>(0);

  const [waterSystemBoundaryTier1, setWaterSystemBoundaryTier1] =
    useState<boolean>(false);
  const [waterSystemBoundaryTier1Opacity, setWaterSystemBoundaryTier1Opacity] =
    useState(0.5);
  const [waterSystemBoundaryTier1Brightness, setWaterSystemBoundaryTier1Brightness] =
    useState(0);

  const [waterSystemBoundaryTier2, setWaterSystemBoundaryTier2] =
    useState<boolean>(false);
  const [waterSystemBoundaryTier2Opacity, setWaterSystemBoundaryTier2Opacity] =
    useState(0.5);

  const [waterSystemBoundaryTier3, setWaterSystemBoundaryTier3] =
    useState<boolean>(false);
  const [waterSystemBoundaryTier3Opacity, setWaterSystemBoundaryTier3Opacity] =
    useState(0.5);
  const [waterSystemBoundaryTier2Brightness, setWaterSystemBoundaryTier2Brightness] =
    useState(0);
  const [waterSystemBoundaryTier3Brightness, setWaterSystemBoundaryTier3Brightness] =
    useState(0);

  const [usCounties, setUSCounties] = useState(false);
  const [usCountiesOpacity, setUSCountiesOpacity] = useState(0.5);
  const [usCountiesBrightness, setUSCountiesBrightness] = useState(0);

  const [femaZones, setFemaZones] = useState<boolean>(false);
  const [femaZonesOpacity, setFemaZonesOpacity] = useState(0.5);
  const [femaZonesBrightness, setFemaZonesBrightness] = useState(0);

  const [femaBoundaries, setFemaBoundaries] = useState<boolean>(false);

  const [popValue, setPopValue] = useState<string | null>(null);
  const [EPASuperfundSites, setEPASuperfundSites] = useState(false);
  const [EPASuperfundSitesOpacity, setEPASuperfundSitesOpacity] = useState(0.5);
  const [EPASuperfundSitesBrightness, setEPASuperfundSitesBrightness] = useState(0);

  const [eddi, setEddi] = useState(false);
  const [eddiOpacity, setEddiOpacity] = useState(0.5);
  const [eddiBrightness, setEddiBrightness] = useState(0);

  const [nwshazard, setNwshazard] = useState(false); 
  const [nwshazardOpacity, setNwshazardOpacity] = useState(0.5);
  const [nwshazardBrightness, setNwshazardBrightness] = useState(0);

  const [warningLayer, setWarningLayer] = useState(false);
  const [warningLayerOpacity, setWarningLayerOpacity] = useState(0.5);
  const [warningLayerBrightness, setWarningLayerBrightness] = useState(0);

  const [NOAAMix, setNOAAMix] = useState(false); 
  const [NOAAMixOpacity, setNOAAMixOpacity] = useState(0.8);
  const [NOAAMixBrightness, setNOAAMixBrightness] = useState(0);

  const [waterQualityPortal, setWaterQualityPortal] = useState(false);
  const [waterQualityPortalLocations, setWaterQualityPortalLocations] =
    useState<any>();
  const [waterQualityPortalData, setWaterQualityPortalData] = useState<any>();
  const [waterQualityPortalDataType, setWaterQualityPortalDataType] =
    useState<string>("locations");
  const [waterQualityPortalDataMetric, setWaterQualityPortalDataMetric] =
    useState<string>("");
  const [waterQualityPortalOpacity, setWaterQualityPortalOpacity] =
    useState(0.5);
  const [WIContaminate, setWIContaminate] = useState<any>();
  

  const [selectedYearMonth, setSelectedYearMonth] = useState<string>("2022_12");
  const [yearMonthRange, setYearMonthRange] = useState([
    "2022_10",
    "2022_11",
    "2022_12",
  ]);

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const queryClient = new QueryClient();

  const [viewport, setViewport] = useState<any>({
    longitude: settings.defaultLocationMap.lng,
    latitude: settings.defaultLocationMap.lat,
    zoom: settings.defaultLocationMap.zoom,
  });

  useEffect(() => {
    const lastViewport = getViewportFromStorage();
    setTimeout(() => {
      if (lastViewport) setViewport(lastViewport);
    }, 100);
  }, []);

  useEffect(() => {
    return () => {
      putViewportIntoStorage(viewport);
    };
  }, []);

  const [layerNames, setLayerNames] = useState<any[]>([]);

  const handleCustomLocationData = (input: any[], name: string) => {
    setCustomLocations(input);

    setCustomLayers((prev: any) => [...prev, input]);
    setLayerNames((prev: any) => [...prev, !prev.includes(name) && name]);

    putCustomLayerNamesIntoStorage(layerNames);
    putCustomLocationDataIntoStorage(customLayers);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD3lOUNPCbyJJMB9Qz--A3yA9gFmv0mleo",
  });

  const [action, setAction] = useState<any>();
  const [clickedHUC8, setClickedHUC8] = useState("");
  const [clickedHUC12, setClickedHUC12] = useState("");
  const [latitude, setLatitude] = useState(viewport.latitude);
  const [longitude, setLongitude] = useState(viewport.longitude);
  const [period, setPeriod] = useState(0);
  const [NOAALayer, setNOAALayer] = useState(false);
  const [EPOLayer, setEPOLayer] = useState(false);
  const [EPOLayerOpacity, setEPOLayerOpacity] = useState(0.5);
  const [EPOLayerBrightness, setEPOLayerBrightness] = useState(0);


  const [CWALayer, setCWALayer] = useState(false)
  const [CWALayerOpacity, setCWALayerOpacity] = useState(0.5)
  const [CWALayerBrightness, setCWALayerBrightness] = useState(0)
  const [CWAParams, setCWAParams] = useState()
  const [CWAParam, setCWAParam] = useState()
  const [CWAStartTime, setCWAStartTime] = useState()
  const [CWAMaxTime, setCWAMaxTime] = useState()
  const [CWATimeRange, setCWATimeRange] = useState()

  const [dataSet, setDataSet] = useState("Commercial Chemical Data");

  const [NOAASeaLevelRise, setNOAASeaLevelRise] = useState(false);
  const [NOAASeaLevelRiseOpacity, setNOAASeaLevelRiseOpacity] = useState(0.5);
  const [NOAASeaLevelRiseBrightness, setNOAASeaLevelRiseBrightness] = useState(0);

  const [percentDone, setPercentDone] = useState(0);
  const [loadingBar, setLoadingBar] = useState(false);
  const [GFSHuc12s, setGFSHuc12s] = useState(false);
  const [rainfallZones, setRainfallZones] = useState<any[]>([
    1, 2, 3, 4, 5, 6, 7,
  ]);

  const [MECReportMounted, setMECReportMounted] = useState(false)
  const [FIBReportMounted, setFIBReportMounted] = useState(false)

  const location = window.location.pathname;

  let topNav = "Insights";

  if (location) {
    if (location === ReportsRoutes.WaterMonitoring) {
      topNav = "Data Management";
    } else if (
      location === ReportsRoutes.ClimatePrecipitationCalculator ||
      location === ReportsRoutes.GroundwaterDetermination
    ) {
      if (window.location.search.indexOf("ent=1") > -1) {
        topNav = "Risk";
      } else {
        topNav = "Climate";
      }
    } else if (location === ReportsRoutes.GroundwaterDetermination) {
      topNav = "Climate";
    } else if (location === ReportsRoutes.Risk) {
      topNav = "Risk";
    }
  }

  const [navTopMenu, setNavTopMenu] = useState(topNav);
  const alerthelp = localStorage.getItem("app_alerthelpA");
  const [showNewUserAlert, setShowNewUserAlert] = useState(false);
  const [showHelpAlert, setShowHelpAlert] = useState(alerthelp ? false : true);

  const [feetIndex, setFeetIndex] = useState(0);

  const [eastPalestineOutflows, setEastPalestineOutflows] = useState(false);

  const [philippinesRivers, setPhilippinesRivers] = useState(false);
  const [philippinesRiversOpacity, setPhilippinesRiversOpacity] = useState(0.5);
  const [philippinesRiversBrightness, setPhilippinesRiversBrightness] = useState(0);

  const [phFloodHazard, setPHFloodHazard] = useState(false);
  const [phFloodHazardOpacity, setPHFloodHazardOpacity] = useState(0.5);
  const [phFloodHazardBrightness, setPHFloodHazardBrightness] = useState(0);

  const [ausPrinHydro, setAusPrinHydro] = useState(false);
  const [ausPrinHydroOpacity, setAusPrinHydroOpacity] = useState(0.5);
  const [ausPrinHydroBrightness, setAusPrinHydroBrightness] = useState(0);

  const [ANGISLower, setANGISLower] = useState(false);
  const [ANGISLowerOpacity, setANGISLowerOpacity] = useState(0.5);
  const [ANGISLowerBrightness, setANGISLowerBrightness] = useState(0);

  const [ANGISMid, setANGISMid] = useState(false);
  const [ANGISMidOpacity, setANGISMidOpacity] = useState(0.5);
  const [ANGISMidBrightness, setANGISMidBrightness] = useState(0);

  const [ANGISUpper, setANGISUpper] = useState(false);
  const [ANGISUpperOpacity, setANGISUpperOpacity] = useState(0.5);
  const [ANGISUpperBrightness, setANGISUpperBrightness] = useState(0);

  const [gemstat, setGemstat] = useState(false);
  const [gemstatOpacity, setGemstatOpacity] = useState(0.5);
  const [gemstatBrightness, setGemstatBrightness] = useState(0);

  const [gemstatPH, setGemstatPH] = useState(false);
  const [gemstatPHOpacity, setGemstatPHOpacity] = useState(0.5);
  const [gemstatPHBrightness, setGemstatPHBrightness] = useState(0);

  const [ausGemstat, setAusGemstat] = useState(false);
  const [ausGemstatOpacity, setAusGemstatOpacity] = useState(0.5);
  const [ausGemstatBrightness, setAusGemstatBrightness] = useState(0);

  const [persistentContaminants, setPersistentContaminants] = useState(false);
  const [modPersistentContaminants, setModPersistentContaminants] =
    useState(false);
  const [volPersistentContaminants, setVolPersistentContaminants] =
    useState(false);
  const [hours, setHours] = useState();
  const [wrfcValue, setWRFCValue] = useState<any>("1. Water Scarcity");
  const [wrfcCategory, setWRFCCategory] = useState("1. Water Scarcity");
  const [wrfcRiskCategory, setWRFCRiskCategory] = useState("1. Water Scarcity");
  const [deloitteFemaZones, setDeloitteFemaZones] = useState(false);
  const [deloitteFema, setDeloitteFema] = useState(false);
  const [contaminate, setContaminate] = useState(
    "moderately_persistent_contaminants"
  );
  const [impairedLakes, setImpairedLakes] = useState(false);
  const [impairedLakesOpacity, setImpairedLakesOpacity] = useState(0.5);

  const [waterWatchSites, setWaterWatchSites] = useState(false);
  const [waterWatchOpacity, setWaterWatchOpacity] = useState(0.5);
  const [waterWatchBrightness, setWaterWatchBrightness] = useState(0);

  const [yellowstoneLayer, setYellowstoneLayer] = useState<any>(false);
  const [yellowstoneLayerOpacity, setYellowstoneLayerOpacity] = useState<any>(0.5);
  const [yellowstoneLayerBrightness, setYellowstoneLayerBrightness] = useState(0);

  const [allFireData, setAllFireData] = useState(false);
  const [allFireDataOpacity, setAllFireDataOpacity] = useState(0.5);
  const [allFireDataBrightness, setAllFireDataBrightness] = useState(0);

  const [toggleHighwayStyle, setToggleHighwayStyle] = useState(false);
  const [mapStyle, setMapStyle] = useState(savedMapStyle ? savedMapStyle : settings.defaultMapStyle);
  const [mapStyleBeforeId, setMapStyleBeforeId] = useState(settings.defaultBeforeId);
  
  const [cvi, setCVI] = useState(false);
  const [cviValue, setCVIValue] = useState("Baseline.Environment");
  const [cviOpacity, setCVIOpacity] = useState(0.5);
  const [cviBrightness, setCVIBrightness] = useState(0);

  const [indoWatersheds, setIndoWatersheds] = useState(false);
  const [indoWatershedsOpacity, setIndoWatershedsOpacity] = useState(0.5);
  const [indoWatershedsBrightness, setIndoWatershedsBrightness] = useState(0);

  const [indoHucs, setIndoHucs] = useState(false);
  const [indoHucsOpacity, setIndoHucsOpacity] = useState(0.5);
  const [indoHucsBrightness, setIndoHucsBrightness] = useState(0);

  const [USGSWUC, setUSGSWUC] = useState(false)
  const [USGSWUCOpacity, setUSGSWUCOpacity] = useState(1);
  const [USGSWUCBrightness, setUSGSWUCBrightness] = useState(0);
  const [dateString, setDateString] = useState("1/2016")
  const [currentState, setCurrentState] = useState('')

  const [singleDate, setSingleDate] = useState<any>(DateTime.now().setZone('utc').minus({days: 1, hours: 2}).toFormat('yyyy-MM-dd'));
  const [globalHuc12, setGlobalHuc12] = useState<any>();
  const [linearGradient, setLinearGradient] = useState<string>("");
  const [customLayers, setCustomLayers] = useState<any[]>([]);

  const [Wise, setWise] = useState(false)
  const [WiseOpacity, setWiseOpacity] = useState(0.5);
  const [WiseBrightness, setWiseBrightness] = useState(0);

  const [HBA, setHBA] = useState(true);
  const [HBAOpacity, setHBAOpacity] = useState(0.5);
  const [HBABrightness, setHBABrightness] = useState(0);
  const [HBALevel, setHBALevel] = useState(1)
  const [HBAKind, setHBAKind] = useState('standard')

  const [selectedUSGSYear, setSelectedUSGSYear] = useState<number>(2016); 

  const [customSelected, setCustomSelected] = useState("")
  const [customLayer1, setCustomLayer1] = useState(false);
  const [customLayer2, setCustomLayer2] = useState(false);
  const [customLayer3, setCustomLayer3] = useState(false);
  const [customLayer4, setCustomLayer4] = useState(false);
  const [customLayer5, setCustomLayer5] = useState(false);
  const [customLayer6, setCustomLayer6] = useState(false);
  const [customLayer7, setCustomLayer7] = useState(false);
  const [customLayer8, setCustomLayer8] = useState(false);
  const [customLayer9, setCustomLayer9] = useState(false);
  const [customLayer10, setCustomLayer10] = useState(false);
  const [customLayer11, setCustomLayer11] = useState(false);
  const [customLayer12, setCustomLayer12] = useState(false);
  const [customLayer13, setCustomLayer13] = useState(false);
  const [customLayer14, setCustomLayer14] = useState(false);
  const [customLayer15, setCustomLayer15] = useState(false);
  const [customLayer16, setCustomLayer16] = useState(false);
  const [customLayer17, setCustomLayer17] = useState(false);
  const [customLayer18, setCustomLayer18] = useState(false);
  const [customLayer19, setCustomLayer19] = useState(false);
  const [customLayer20, setCustomLayer20] = useState(false);

  const [customData1, setCustomData1] = useState<any[]>([]);
  const [customData2, setCustomData2] = useState<any[]>([]);
  const [customData3, setCustomData3] = useState<any[]>([]);
  const [customData4, setCustomData4] = useState<any[]>([]);
  const [customData5, setCustomData5] = useState<any[]>([]);
  const [customData6, setCustomData6] = useState<any[]>([]);
  const [customData7, setCustomData7] = useState<any[]>([]);
  const [customData8, setCustomData8] = useState<any[]>([]);
  const [customData9, setCustomData9] = useState<any[]>([]);
  const [customData10, setCustomData10] = useState<any[]>([]);
  const [customData11, setCustomData11] = useState<any[]>([]);
  const [customData12, setCustomData12] = useState<any[]>([]);
  const [customData13, setCustomData13] = useState<any[]>([]);
  const [customData14, setCustomData14] = useState<any[]>([]);
  const [customData15, setCustomData15] = useState<any[]>([]);
  const [customData16, setCustomData16] = useState<any[]>([]);
  const [customData17, setCustomData17] = useState<any[]>([]);
  const [customData18, setCustomData18] = useState<any[]>([]);
  const [customData19, setCustomData19] = useState<any[]>([]);
  const [customData20, setCustomData20] = useState<any[]>([]);

  const [hiddenData, setHiddenData] = useState(false)

  const [countriesWithData, setCountriesWithData] = useState()
  const [statesWithData, setStatesWithData] = useState()

  const [customDataLayerValue, setCustomDataLayerValue] = useState();

  const [layerIds, setLayerIds] = useState()
  const [pillNames, setPillNames] = useState([])
  const [AppleLocationData, setAppleLocationData] = useState(false)

  
  const [CEJST, setCEJST] = useState(false)
  const [CEJSTOpacity, setCEJSTOpacity] = useState(0.5)
  const [CEJSTBrightness, setCEJSTBrightness] = useState(0)
  const [CEJSTPopup, setCEJSTPopup] = useState(true)

  const [FHBP, setFHBP] = useState(false);
  const [FHBPOpacity, setFHBPOpacity] = useState(0.5);
  const [FHBPBrightness, setFHBPBrightness] = useState(0);

  const [FBFIB, setFBFIB] = useState(false);
  const [FBFIBOpacity, setFBFIBOpacity] = useState(0.5);
  const [FBFIBBrightness, setFBFIBBrightness] = useState(0);
  const [FBFIBDates, setFBFIBDates] = useState();
  const [FBFIBDate, setFBFIBDate] = useState();

  const [activePopup, setActivePopup] = useState<{ id: string | null, content: JSX.Element | null }>({ id: null, content: null });

  let lastNotification = "";
  let skipNotification = false;
  const notify = (input: string, condition = null) => {
    if(input === lastNotification){
      if(!skipNotification){
        skipNotification = true;
        setTimeout(() => {
          lastNotification = "";
          skipNotification = false;
        }, 4444);        
      }
      return;
    } else {
      lastNotification = input;
    }
    
    if (!settingsDoNotDisturb && condition === null) {
      toast(input);
    }   

    if (condition === "warn") {
      toast.warn(input);
    }

    if (condition === "error") {
      toast.error(input);
    }

    if (condition === "info") {
      toast.info(input);
    }

    if (condition === "success") {
      toast.success(input);
    }
  };

  const zoom = viewport.zoom;

  const onMapClick = (e: any) => {
      e.preventDefault();

      if(e.point){
        setCurrentPoint?.({
              lat: e.lngLat.lat,
              lng: e.lngLat.lng
         });
      }
      
      if(e && e.target && e.target.queryRenderedFeatures && e.point){
        setClickedFeatures(e.target.queryRenderedFeatures(e.point))
      }

      setAction(e);

      const { lng, lat } = e.lngLat;
      if (lng && lat) {
        setShowPopup(true);
        if (zoom < 8) {
          return agent.Utilities.LatLngToHuc8(lat, lng).then(({ body }: any) => {
            if (body && body.length > 0 && body[0].Huc8) {
              setClickedHUC8(body[0].Huc8);           
              if(global.hucsSelected ){
                  setSelectedHUC8(body[0].Huc8.substr(0, 8));
              }            
            }
          });
        }

        if (zoom > 8) {
          return agent.Utilities.LatLngToHuc12(lat, lng).then(({ body }: any) => {
            if (body && body.length > 0 && body[0].Huc8) {
              setClickedHUC12(body[0].Huc8);
              setGlobalHuc12(body[0].Huc8)
              if(global.hucsSelected ){
                  setSelectedHUC8(body[0].Huc8.substr(0, 8));
              }   
            }
          });
        }
      }

  };

  const appContext = useContext(AppContext);
  const { updateContext } = useContext(AppContext);


  useEffect(() => {

    const handleUnload = () => {
      putCountryIntoStorage(currentCountry);
      putStateIntoStorage(currentState)
    };

    window.onbeforeunload = handleUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, [
    currentCountry,
    currentState
  ]);

  const fetchHuc8 = async (lat: any, lng: any) => {
    let pointHuc8: any;
    setLoading(true)
    const res = await agent.Utilities.LatLngToHuc8(lat, lng)
    .then(({ body }: any) => pointHuc8 = body[0].Huc8)
    .catch((error) => console.log(error))
    .finally(() => setLoading(false))

    return pointHuc8
  }

  const handleCustomLayer = (id: any, data: any) => {
    switch (id) {
      case 1:
        setCustomLayer1(!customLayer1);
        setCustomData1(data);
        return;
      case 2:
        setCustomLayer2(!customLayer2);
        setCustomData2(data);
        return;
      case 3:
        setCustomLayer3(!customLayer3);
        setCustomData3(data);
        return;
      case 4:
        setCustomLayer4(!customLayer4);
        setCustomData4(data);
        return;
      case 5:
        setCustomLayer5(!customLayer5);
        setCustomData5(data);
        return;
      case 6:
        setCustomLayer6(!customLayer6);
        setCustomData6(data);
        return;
      case 7:
        setCustomLayer7(!customLayer7);
        setCustomData7(data);
        return;
      case 8:
        setCustomLayer8(!customLayer8);
        setCustomData8(data);
        return;
      case 9:
        setCustomLayer9(!customLayer9);
        setCustomData9(data);
        return;
      case 10:
        setCustomLayer10(!customLayer10);
        setCustomData10(data);
        return;
      case 11:
        setCustomLayer11(!customLayer11);
        setCustomData11(data);
        return;
      case 12:
        setCustomLayer12(!customLayer12);
        setCustomData12(data);
        return;
      case 13:
        setCustomLayer13(!customLayer13);
        setCustomData13(data);
        return;
      case 14:
        setCustomLayer14(!customLayer14);
        setCustomData14(data);
        return;
      case 15:
        setCustomLayer15(!customLayer15);
        setCustomData15(data);
        return;
      case 16:
        setCustomLayer16(!customLayer16);
        setCustomData16(data);
        return;
      case 17:
        setCustomLayer17(!customLayer17);
        setCustomData17(data);
        return;
      case 18:
        setCustomLayer18(!customLayer18);
        setCustomData18(data);
        return;
      case 19:
        setCustomLayer19(!customLayer19);
        setCustomData19(data);
        return;
      case 20:
        setCustomLayer20(!customLayer20);
        setCustomData20(data);
        return;
      default:
        break;
    }
  };

  const handleCustomLayerSelected = (id: any) => {
    switch (id) {
      case 1:
        return customLayer1;
      case 2:
        return customLayer2;
      case 3:
        return customLayer3;
      case 4:
        return customLayer4;
      case 5:
        return customLayer5;
      case 6:
        return customLayer6;
      case 7:
        return customLayer7;
      case 8:
        return customLayer8;
      case 9:
        return customLayer9;
      case 10:
        return customLayer10;
      case 11:
        return customLayer11;
      case 12:
        return customLayer12;
      case 13:
        return customLayer13;
      case 14:
        return customLayer14;
      case 15:
        return customLayer15;
      case 16:
        return customLayer16;
      case 17:
        return customLayer17;
      case 18:
        return customLayer18;
      case 19:
        return customLayer19;
      case 20:
        return customLayer20;
      default:
        break;
    }
  };

  let layerList: any[] = [];
  const [openLayerMenu, setOpenLayerMenu] = useState(false)

  const handleClickScroll = (id:string) => {
    // Get the element by its ID
    setOpenLayerMenu(true)
    const element = document.getElementById(id);
    if (element) {
      // Scroll to the element
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [statusMessage, setStatusMessage] = useState('');
  const [layerName, setLayerName] = useState();
  const [layerMode, setLayerMode] = useState();

 

  const global: Globals = {
    HBA, setHBA,
    HBAOpacity, setHBAOpacity,
    HBABrightness, setHBABrightness,
    HBALevel, setHBALevel,
    HBAKind, setHBAKind,
    Wise, setWise,
    WiseOpacity, setWiseOpacity,
    WiseBrightness, setWiseBrightness,
    MECReportMounted, setMECReportMounted,
    FIBReportMounted, setFIBReportMounted,
    statesWithData, setStatesWithData,
    currentState, setCurrentState,
    MEC, setMEC,
    MECOpacity, setMECOpacity,
    MECBrightness, setMECBrightness,
    MECDates, setMECDates,
    MECStartDate, setMECStartDate,
    MECEndDate, setMECEndDate,
    MBFIBDT, setMBFIBDT,
    MBFIBDTOpacity, setMBFIBDTOpacity,
    MBFIBDTBrightness, setMBFIBDTBrightness,
    NSG, setNSG,
    NSGOpacity, setNSGOpacity,
    NSGBrightness, setNSGBrightness,
    GGDE, setGGDE,
    GGDEOpacity, setGGDEOpacity,
    CWALayer, setCWALayer,
    CWALayerOpacity, setCWALayerOpacity,
    CWALayerBrightness, setCWALayerBrightness,
    FBFIB, setFBFIB,
    FBFIBOpacity, setFBFIBOpacity,
    FBFIBBrightness, setFBFIBBrightness,
    FHBP, setFHBP,
    FHBPOpacity, setFHBPOpacity,
    FHBPBrightness, setFHBPBrightness,
    FBFIBDates, setFBFIBDates,
    FBFIBDate, setFBFIBDate,
    CWAParam, setCWAParam,
    CWAParams, setCWAParams,
    OWQPDemo, setOWQPDemo,
    CWATimeRange, setCWATimeRange,
    CWAStartTime, setCWAStartTime,
    CWAMaxTime, setCWAMaxTime,
    OWQPDemoOpacity, setOWQPDemoOpacity,
    OWQPDemoBrightness, setOWQPDemoBrightness,
    selectedUSGSYear, setSelectedUSGSYear,
    SkyFiAustin, setSkyFiAustin,
    SkyFiAustinOpacity, setSkyFiAustinOpacity,
    SkyFiWhitingOpacity, setSkyFiWhitingOpacity,
    SkyFiWhiting, setSkyFiWhiting,
    CEJST, setCEJST,
    CEJSTOpacity, setCEJSTOpacity,
    CEJSTBrightness, setCEJSTBrightness,
    CEJSTPopup, setCEJSTPopup,
    appleLocs, setAppleLocs,
    hiddenData, setHiddenData,
    AppleLocationData, setAppleLocationData,
    statusMessage, setStatusMessage,
    layerName, setLayerName,
    layerMode, setLayerMode,
    USGSHuc12, setUSGSHuc12,
    USGSYear, setUSGSYear,
    USGShuc12DateString, setUSGShuc12DateString,
    USGSHuc12Opacity, setUSGSHuc12Opacity,
    USGSHuc12Brightness, setUSGSHuc12Brightness,
    openLayerMenu, setOpenLayerMenu,
    handleClickScroll,
    USGSWUC, setUSGSWUC,
    USGSWUCOpacity, setUSGSWUCOpacity,
    USGSWUCBrightness, setUSGSWUCBrightness,
    dateString, setDateString,
    countriesWithData, setCountriesWithData,
    OABSPKBrightness, setOABSPKBrightness,
    OABSPKOpacity, setOABSPKOpacity,
    OABSPK, setOABSPK,
    OABSSGBrightness, setOABSSGBrightness,
    OABSSGOpacity, setOABSSGOpacity,
    OABSSG, setOABSSG,
    OABSCABrightness, setOABSCABrightness,
    OABSCAOpacity, setOABSCAOpacity,
    OABSCA, setOABSCA,
    OABSPH, setOABSPH,
    OABSMX, setOABSMX,
    OABSID, setOABSID,
    OABRSG, setOABRSG,
    OABRMX, setOABRMX,
    OABRCA, setOABRCA,
    OABRPK, setOABRPK,
    OABRID, setOABRID,
    OABRPH, setOABRPH,
    OABSPHOpacity, setOABSPHOpacity,
    OABSPHBrightness, setOABSPHBrightness,
    OABSMXOpacity, setOABSMXOpacity,
    OABSMXBrightness, setOABSMXBrightness,
    OABSIDOpacity, setOABSIDOpacity,
    OABSIDBrightness, setOABSIDBrightness,
    OABRCAOpacity, setOABRCAOpacity,
    OABRCABrightness, setOABRCABrightness,
    OABRMXOpacity, setOABRMXOpacity,
    OABRMXBrightness, setOABRMXBrightness,
    OABRPKOpacity, setOABRPKOpacity,
    OABRPKBrightness, setOABRPKBrightness,
    OABRIDOpacity, setOABRIDOpacity,
    OABRPHOpacity, setOABRPHOpacity,
    OABRPHBrightness, setOABRPHBrightness,
    OABRSGOpacity, setOABRSGOpacity,
    layerIds, setLayerIds,
    pillNames, setPillNames,
    OABID, setOABID,
    OABIDOpacity, setOABIDOpacity,
    OABIDBrightness, setOABIDBrightness,
    OABUS, setOABUS,
    OABUSOpacity, setOABUSOpacity,
    OABUSBrightness, setOABUSBrightness,
    OABPH, setOABPH,
    OABPHOpacity, setOABPHOpacity,
    OABPHBrightness, setOABPHBrightness,
    OABMX, setOABMX,
    OABMXOpacity, setOABMXOpacity,
    OABMXBrightness, setOABMXBrightness,
    OGWTD, setOGWTD,
    OGWTDOpacity, setOGWTDOpacity,
    OGWTDBrightness, setOGWTDBrightness,
    points, setPoints,
    streams, setStreams,
    lakes, setLakes,
    NationalWaterImpairements, setNationalWaterImpairements,
    NationalWaterImpairementsOpacity, setNationalWaterImpairementsOpacity,
    NationalWaterImpairementsBrightness, setNationalWaterImpairementsBrightness,
    activeLayerList, setActiveLayerList,
    currentYear,
    WIContaminate, setWIContaminate,
    selectedYear, setSelectedYear,
    WaterConflict, setWaterConflict,
    WaterConflictOpacity, setWaterConflictOpacity,
    WaterConflictBrightness, setWaterConflictBrightness,
    layerNames, setLayerNames,
    layerList,
    GBIF, setGBIF,
    GBIFOpacity, setGBIFOpacity,
    GBIFBrightness, setGBIFBrightness,
    customDataLayerValue, setCustomDataLayerValue,
    customLayers, setCustomLayers,
    handleCustomLayer, handleCustomLayerSelected,
    customSelected, setCustomSelected,
    customLayer1, setCustomLayer1,
    customLayer2, setCustomLayer2,
    customLayer3, setCustomLayer3,
    customLayer4, setCustomLayer4,
    customLayer5, setCustomLayer5,
    customLayer6, setCustomLayer6,
    customLayer7, setCustomLayer7,
    customLayer8, setCustomLayer8,
    customLayer9, setCustomLayer9,
    customLayer10, setCustomLayer10,
    customLayer11, setCustomLayer11,
    customLayer12, setCustomLayer12,
    customLayer13, setCustomLayer13,
    customLayer14, setCustomLayer14,
    customLayer15, setCustomLayer15,
    customLayer16, setCustomLayer16,
    customLayer17, setCustomLayer17,
    customLayer18, setCustomLayer18,
    customLayer19, setCustomLayer19,
    customLayer20, setCustomLayer20,
    customData1, setCustomData1,
    customData2, setCustomData2,
    customData3, setCustomData3,
    customData4, setCustomData4,
    customData5, setCustomData5,
    customData6, setCustomData6,
    customData7, setCustomData7,
    customData8, setCustomData8,
    customData9, setCustomData9,
    customData10, setCustomData10,
    customData11, setCustomData11,
    customData12, setCustomData12,
    customData13, setCustomData13,
    customData14, setCustomData14,
    customData15, setCustomData15,
    customData16, setCustomData16,
    customData17, setCustomData17,
    customData18, setCustomData18,
    customData19, setCustomData19,
    customData20, setCustomData20,
    handleCustomLocationData,
    MoBiPSR, setMoBiPSR,
    MoBiPSROpacity, setMoBiPSROpacity,
    MoBiSRAll, setMoBiSRAll,
    MoBiSRAllOpacity, setMoBiSRAllOpacity,
    MoBiPI, setMoBiPI,
    MoBiPIOpacity, setMoBiPIOpacity,
    MoBiV, setMoBiV,
    MoBiVOpacity, setMoBiVOpacity,
    MoBiSRA, setMoBiSRA,
    MoBiSRAOpacity, setMoBiSRAOpacity,
    indoHucs, setIndoHucs,
    indoHucsOpacity, setIndoHucsOpacity,
    indoHucsBrightness, setIndoHucsBrightness,
    indoWatersheds, setIndoWatersheds,
    indoWatershedsOpacity, setIndoWatershedsOpacity,
    indoWatershedsBrightness, setIndoWatershedsBrightness,
    AAV4RiverineFloodRisk, setAAV4RiverineFloodRisk,
    AAV4RiverineFloodRiskOpacity, setAAV4RiverineFloodRiskOpacity,
    AAV4RiverineFloodRiskBrightness, setAAV4RiverineFloodRiskBrightness,
    AAV4CoastalFloodRisk, setAAV4CoastalFloodRisk,
    AAV4CoastalFloodRiskOpacity, setAAV4CoastalFloodRiskOpacity,
    AAV4CoastalFloodRiskBrightness, setAAV4CoastalFloodRiskBrightness,
    AAV4WaterStress, setAAV4WaterStress,
    AAV4WaterStressOpacity, setAAV4WaterStressOpacity,
    AAV4WaterStressBrightness, setAAV4WaterStressBrightness,
    linearGradient, setLinearGradient,
    cviValue, setCVIValue,
    cvi, setCVI,
    cviOpacity, setCVIOpacity,
    cviBrightness, setCVIBrightness,
    rainfallDay, setRainfallDay,
    rainfallZones, setRainfallZones,
    rainfallForecast, setRainfallForecast,
    rainfallForecastOpacity, setRainfallForecastOpacity,
    rainfallForecastBrightness, setRainfallForecastBrightness,
    toggleHighwayStyle, setToggleHighwayStyle,
    mapStyle, setMapStyle,
    mapStyleBeforeId, setMapStyleBeforeId,
    fireYear, setFireYear,
    allFireData, setAllFireData,
    allFireDataOpacity, setAllFireDataOpacity,
    allFireDataBrightness, setAllFireDataBrightness,
    wildFireBoundaries, setWildFireBoundaries,
    wildFireBoundariesOpacity, setWildFireBoundariesOpacity,
    wildFireBoundariesBrightness, setWildFireBoundariesBrightness,
    yellowstoneLayer, setYellowstoneLayer,
    yellowstoneLayerOpacity, setYellowstoneLayerOpacity,
    yellowstoneLayerBrightness, setYellowstoneLayerBrightness,
    globalHuc12, setGlobalHuc12,
    GFSHuc12s, setGFSHuc12s,
    singleDate, setSingleDate,
    waterWatchSites, setWaterWatchSites,
    waterWatchOpacity, setWaterWatchOpacity,
    waterWatchBrightness, setWaterWatchBrightness,
    impairedLakes, setImpairedLakes,
    impairedLakesOpacity, setImpairedLakesOpacity,
    contaminate, setContaminate,
    deloitteFema, setDeloitteFema,
    deloitteFemaZones, setDeloitteFemaZones,
    wrfcRiskCategory, setWRFCRiskCategory,
    wrfcRiskOpacity, setWRFCRiskOpacity,
    wrfcRiskBrightness, setWRFCRiskBrightness,
    wrfcCategory, setWRFCCategory,
    wrfcValue, setWRFCValue,
    loadingBar, setLoadingBar,
    percentDone, setPercentDone,
    hoursIndex, setHoursIndex,
    hours, setHours,
    persistentContaminants, setPersistentContaminants,
    modPersistentContaminants, setModPersistentContaminants,
    volPersistentContaminants, setVolPersistentContaminants,
    wrfcRisk, setWRFCRisk,
    splashCount, setSplashCount,
    eddi, setEddi,
    eddiOpacity, setEddiOpacity,
    eddiBrightness, setEddiBrightness,
    nwshazard, setNwshazard,
    nwshazardOpacity, setNwshazardOpacity,
    nwshazardBrightness, setNwshazardBrightness,
    warningLayer, setWarningLayer,
    warningLayerOpacity, setWarningLayerOpacity,
    warningLayerBrightness, setWarningLayerBrightness,
    NOAAMix, setNOAAMix,
    NOAAMixOpacity, setNOAAMixOpacity,
    NOAAMixBrightness, setNOAAMixBrightness,
    waterQualityPortal, setWaterQualityPortal,
    waterQualityPortalLocations, setWaterQualityPortalLocations,
    waterQualityPortalData, setWaterQualityPortalData,
    waterQualityPortalDataType, setWaterQualityPortalDataType,
    waterQualityPortalDataMetric, setWaterQualityPortalDataMetric,
    waterQualityPortalOpacity, setWaterQualityPortalOpacity,
    selectedYearMonth, setSelectedYearMonth,
    yearMonthRange, setYearMonthRange,
    ausGemstat, setAusGemstat,
    ausGemstatOpacity, setAusGemstatOpacity,
    ausGemstatBrightness, setAusGemstatBrightness,
    gemstatPH, setGemstatPH,
    gemstatPHOpacity, setGemstatPHOpacity,
    gemstatPHBrightness, setGemstatPHBrightness,
    gemstat, setGemstat,
    gemstatOpacity, setGemstatOpacity,
    gemstatBrightness, setGemstatBrightness,
    phFloodHazard, setPHFloodHazard,
    phFloodHazardOpacity, setPHFloodHazardOpacity,
    phFloodHazardBrightness, setPHFloodHazardBrightness,
    philRegions, setPhilRegions,
    philRegionsOpacity, setPhilRegionsOpacity,
    philRegionsBrightness, setPhilRegionsBrightness,
    ABWDLayer, setABWDLayer,
    ABWDLayerOpacity, setABWDLayerOpacity,
    ABWDLayerBrightness, setABWDLayerBrightness,
    ANGISLower, setANGISLower,
    ANGISLowerOpacity, setANGISLowerOpacity,
    ANGISLowerBrightness, setANGISLowerBrightness,
    ANGISMid, setANGISMid,
    ANGISMidOpacity, setANGISMidOpacity,
    ANGISMidBrightness, setANGISMidBrightness,
    ANGISUpper, setANGISUpper,
    ANGISUpperOpacity, setANGISUpperOpacity,
    ANGISUpperBrightness, setANGISUpperBrightness,
    ausPrinHydro, setAusPrinHydro,
    ausPrinHydroOpacity, setAusPrinHydroOpacity,
    ausPrinHydroBrightness, setAusPrinHydroBrightness,
    biaLayer, setBIALayer,
    biaLayerOpacity, setBIALayerOpacity,
    biaLayerBrightness, setBIALayerBrightness,
    philippinesRivers, setPhilippinesRivers,
    philippinesRiversOpacity, setPhilippinesRiversOpacity,
    philippinesRiversBrightness, setPhilippinesRiversBrightness,
    philippinesRiverBasins, setPhilippinesRiverBasins,
    philippinesRiverBasinsOpacity, setPhilippinesRiverBasinsOpacity,
    philippinesRiverBasinsBrightness, setPhilippinesRiverBasinsBrightness,
    usCounties, setUSCounties,
    usCountiesOpacity, setUSCountiesOpacity,
    usCountiesBrightness, setUSCountiesBrightness,
    authenticatedUser, setAuthenticatedUser,
    resMask, setResMask,
    userOrgString, setUserOrgString,
    userRoleString, setUserRoleString,
    I10Huc12s, setI10Huc12s,
    I10Huc12sOpacity, setI10Huc12sOpacity,
    I10Huc12sBrightness, setI10Huc12sBrightness,
    eastPalestineOutflows,
    setEastPalestineOutflows,
    selectedHUC8: selectedHUC8,
    setSelectedHUC8: setSelectedHUC8,
    navTopMenu, setNavTopMenu,
    MenuRightOpen: menuRightOpen,
    SetMenuRightOpen: setMenuRightOpen,
    hucsSelected: hucsSelected,
    setHucsSelected: setHucsSelected,
    hucsSelectedOpacity, setHucsSelectedOpacity,
    hucsSelectedBrightness, setHucsSelectedBrightness,
    gwNitrateAssessmentSelected: gwNitrateAssessmentSelected,
    gwNitrateAssessmentSelectedOpacity, setGwNitrateAssessmentSelectedOpacity,
    gwNitrateAssessmentSelectedBrightness, setGwNitrateAssessmentSelectedBrightness,
    setGwNitrateAssessmentSelected: setGwNitrateAssessmentSelected,
    outflowsSelected: outflowsSelected, setOutflowsSelected: setOutflowsSelected,
    outflowsSelectedOpacity,
    setOutflowsSelectedOpacity,
    outflowsSelectedBrightness, setOutflowsSelectedBrightness,
    nhdSelected: nhdSelected, setNhdSelected: setNhdSelected,
    nhdSelectedOpacity, setNhdSelectedOpacity,
    nhdSelectedBrightness, setNhdSelectedBrightness,
    userLogged: userLogged, setUserLogged: setUserLogged,
    userInfo: userInfo, setUserInfo: setUserInfo,
    userRole, setUserRole,
    userRoles, setUserRoles,
    userOrg, setUserOrg,
    guiSwitch, setGuiSwitch,
    hucRestrict, setHucRestrict,
    reportRestrict, setReportRestrict,
    permitReports, setPermitReports,
    resultsDataset, setResultsDataset,
    clickedFeatures, setClickedFeatures,
    userNotifications, setUserNotifications,
    settingsDoNotDisturb, setSettingsDoNotDisturb,
    dashboardProjects: dashboardProjects, setDashboardProjects: setDashboardProjects,
    selectedDashboardProject, setSelectedDashboardProject,
    selectedDashboardProjectLinks, setSelectedDashboardProjectLinks,
    showWorkflow, setShowWorkflow,
    customLocations, setCustomLocations,
    twitterSelected, setTwitterSelected,
    drinkingWaterQualitySelected, setDrinkingWaterQualitySelected,
    filter1Selected: filter1Selected, setfilter1Selected: setfilter1Selected,
    filter1Populated: filter1Populated, setfilter1Populated: setfilter1Populated,
    filterDaysSelected: filterDaysSelected, setfilterDaysSelected: setfilterDaysSelected,
    filterLocationsSelected: filterLocationsSelected, setfilterLocationsSelected: setfilterLocationsSelected,
    filterLocationsPopulated: filterLocationsPopulated, setfilterLocationsPopulated: setfilterLocationsPopulated,
    industrySelected, setIndustrySelected,
    filterBoundingBox, setFilterBoundingBox,
    filterPolygons, setFilterPolygons,
    layerClimateFill, setLayerClimateFill,
    wastewaterSelected, setWastewaterSelected,
    pollutionSelected, setPollutionSelected,
    zipSelected, setZipSelected,
    zipOpacity, setZipOpacity,
    zipBrightness, setZipBrightness,
    industrySectorFilterPopulated, setIndustrySectorFilterPopulated,
    selectedIndustrySector, setSelectedIndustrySector,
    selectedYearFilter, setSelectedYearFilter,
    selectedYearFilterOptions, setSelectedYearFilterOptions,
    yearsSelected, setYearsSelected,
    dateRangeSelected, setDateRangeSelected,
    chemicalsSelected, setChemicalsSelected,
    chemicalsFilerOptions, setChemicalsFilerOptions,
    droughtMonitoringSelected, setDroughtMonitoringSelected,
    droughtMonitoringOpacity, setDroughtMonitoringOpacity,
    droughtMonitoringBrightness, setDroughtMonitoringBrightness,
    principalAquifersSelected, setPrincipalAquifersSelected,
    principalAquifersOpacity, setPrincipalAquifersOpacity,
    principalAquifersBrightness,  setPrincipalAquifersBrightness,
    domesticWellsSelected, setDomesticWellsSelected,
    domesticWellsOpacity, setDomesticWellsOpacity,
    domesticWellsbrightness,  setDomesticWellsbrightness,
    timeReferenceUnit, setTimeReferenceUnit,
    selectedMeasurementFilterOptions, setSelectedMeasurementFilterOptions,
    selectedMeasurementFilterValue, setSelectedMeasurementFilterValue,
    viewport, setViewport,
    checkFilters, setCheckFilters,
    checkFilter, setCheckFilter,
    dates, setDates,
    terraClimateLayerSelected, setTerraClimateLayerSelected,
    terraClimateLayerSelectedOpacity, setTerraClimateLayerSelectedOpacity,
    terraClimateLayerSelectedBrightness, setTerraClimateLayerSelectedBrightness,
    MTCLValue, setMTCLValue,
    filterSICCodesFitlerOptions, setFilterSICCodesFitlerOptions,
    filterSICCodesSelected, setFilterSICCodesSelected,
    globeView, setGlobeView,
    searchCenter, setSearchCenter,
    currentCountry, setCurrentCountry,
    loading, setLoading,
    evapotranspirationSelected, setEvapotranspirationSelected,
    evapotranspirationSelectedOpacity, setEvapotranspirationSelectedOpacity,
    evapotranspirationSelectedBrightness, setEvapotranspirationSelectedBrightness,
    selectedState, setSelectedState,
    selectedStateCoordinates, setSelectedStateCoordinates,
    cookieTrailState, setCookieTrailState,
    EJDemoSelected, setEJDemoSelected,
    EJDemoSelectedOpacity, setEJDemoSelectedOpacity,
    EJDemoSelectedBrightness, setEJDemoSelectedBrightness,
    EJLowIncomeSelected, setEJLowIncomeSelected,
    EJLowIncomeSelectedOpacity, setEJLowIncomeSelectedOpacity,
    EJLowIncomeSelectedBrightness, setEJLowIncomeSelectedBrightness,
    EJPOCSelected, setEJPOCSelected,
    EJPOCSelectedOpacity, setEJPOCSelectedOpacity,
    EJPOCSelectedBrightness, setEJPOCSelectedBrightness,
    EJUnemploymentSelected, setEJUnemploymentSelected,
    EJUnemploymentSelectedOpacity, setEJUnemploymentSelectedOpacity,
    EJUnemploymentSelectedBrightness,  setEJUnemploymentSelectedBrightness,
    EJLingIsoSelected, setEJLingIsoSelected,
    EJLingIsoSelectedOpacity, setEJLingIsoSelectedOpacity,
    EJLingIsoSelectedBrightness, setEJLingIsoSelectedBrightness,
    EJLessHSSelected, setEJLessHSSelected,
    EJLessHSSelectedOpacity, setEJLessHSSelectedOpacity,
    EJLessHSSelectedBrightness, setEJLessHSSelectedBrightness,
    EJFiveAndUnderAgeSelected, setEJFiveAndUnderAgeSelected,
    EJFiveAndUnderAgeSelectedOpacity, setEJFiveAndUnderAgeSelectedOpacity,
    EJFiveAndUnderAgeSelectedBrightness, setEJFiveAndUnderAgeSelectedBrightness,
    EJSixtyFourAndOverSelected, setEJSixtyFourAndOverSelected,
    EJSixtyFourAndOverSelectedOpacity, setEJSixtyFourAndOverSelectedOpacity,
    EJSixtyFourAndOverSelectedBrightness, setEJSixtyFourAndOverSelectedBrightness,
    EJWasteWaterDischarge, setEJWasteWaterDischarge,
    EJWasteWaterDischargeOpacity, setEJWasteWaterDischargeOpacity,
    EJWasteWaterDischargeBrightness,  setEJWasteWaterDischargeBrightness,
    HUCsMexico, setHUCsMexico,
    HUCsMexicoOpacity, setHUCsMexicoOpacity,
    EJUndergroundStorageTanks, setEJUndergroundStorageTanks,
    EJUndergroundStorageTanksOpacity, setEJUndergroundStorageTanksOpacity,
    EJUndergroundStorageTanksBrightness,  setEJUndergroundStorageTanksBrightness,
    EJHazardousWaste, setEJHazardousWaste,
    EJHazardousWasteOpacity, setEJHazardousWasteOpacity,
    EJHazardousWasteBrightness, setEJHazardousWasteBrightness,
    EJRMP, setEJRMP,
    EJRMPOpacity, setEJRMPOpacity,
    EJRMPBrightness, setEJRMPBrightness,
    EJSuperfund, setEJSuperfund,
    EJSuperfundOpacity, setEJSuperfundOpacity,
    EJSuperfundBrightness,  setEJSuperfundBrightness,
    officialTweetsSelected, setofficialTweetsSelected,
    unofficialTweetsSelected, setUnofficialTweetsSelected,
    officialTweetsPresent, setOfficialTweetsPresent,
    unofficialTweetsPresent, setUnOfficialTweetsPresent,
    reportNames, setReportNames,
    reportNamesSelected, setReportNamesSelected,
    countryInit, setCountryInit,
    trackerOn, setTrackerOn,
    latitude, setLatitude,
    longitude, setLongitude,
    period, setPeriod,
    onMapClick,
    mouseData, setMouseData,
    clickedHUC8, setClickedHUC8,
    clickedHUC12, setClickedHUC12,
    showPopup, setShowPopup,
    unregulatedReport, setUnregulatedReport,
    waterSystemBoundariesSelected, setWaterSystemBoundariesSelected,
    action, setAction,
    waterSystemBoundaryTier1, setWaterSystemBoundaryTier1,
    waterSystemBoundaryTier2, setWaterSystemBoundaryTier2,
    waterSystemBoundaryTier3, setWaterSystemBoundaryTier3,
    waterSystemBoundaryTier1Opacity, setWaterSystemBoundaryTier1Opacity,
    waterSystemBoundaryTier1Brightness, setWaterSystemBoundaryTier1Brightness,
    waterSystemBoundaryTier2Brightness, setWaterSystemBoundaryTier2Brightness,
    waterSystemBoundaryTier3Brightness, setWaterSystemBoundaryTier3Brightness,
    waterSystemBoundaryTier2Opacity, setWaterSystemBoundaryTier2Opacity,
    waterSystemBoundaryTier3Opacity, setWaterSystemBoundaryTier3Opacity,
    dataSet, setDataSet,
    googleMenuOpen, setGoogleMenuOpen,
    surfacewaterTrueQI, setSurfacewaterTrueQI,
    popSet, setPopSet,
    popValue, setPopValue,
    agWaterTrueQI, setAgWaterTrueQI,
    stormWaterTrueQI, setStormWaterTrueQI,
    h303DTrueQI, setH303DTrueQI,
    femaZones, setFemaZones,
    femaZonesOpacity, setFemaZonesOpacity,
    femaZonesBrightness, setFemaZonesBrightness,
    femaBoundaries, setFemaBoundaries,
    NOAALayer, setNOAALayer,
    aqueductAtlas, setAqueductAtlas,
    aqueductAtlasCoastalFlood, setAqueductAtlasCoastalFlood,
    aqueductAtlasCoastalFloodOpacity, setAqueductAtlasCoastalFloodOpacity,
    aqueductAtlasCoastalFloodBrightness, setAqueductAtlasCoastalFloodBrightness,
    aqueductAtlasWaterStress, setAqueductAtlasWaterStress,
    aqueductAtlasWaterStressOpacity, setAqueductAtlasWaterStressOpacity,
    aqueductAtlasWaterStressBrightness, setAqueductAtlasWaterStressBrightness,
    aqueductAtlasRiverine, setAqueductAtlasRiverine,
    aqueductAtlasRiverineOpacity, setAqueductAtlasRiverineOpacity,
    aqueductAtlasRiverineBrightness, setAqueductAtlasRiverineBrightness,
    EPOLayer, setEPOLayer,
    EPOLayerOpacity, setEPOLayerOpacity,
    EPOLayerBrightness, setEPOLayerBrightness,
    WDPA, setWDPA,
    WDPAOpacity, setWDPAOpacity,
    WDPABrightness, setWDPABrightness,
    NOAASeaLevelRise, setNOAASeaLevelRise,
    NOAASeaLevelRiseOpacity, setNOAASeaLevelRiseOpacity,
    NOAASeaLevelRiseBrightness,  setNOAASeaLevelRiseBrightness,
    feetIndex, setFeetIndex,
    EPASuperfundSites, setEPASuperfundSites,
    EPASuperfundSitesOpacity, setEPASuperfundSitesOpacity,
    EPASuperfundSitesBrightness, setEPASuperfundSitesBrightness,
    resFEMA, setResFEMA,
    notify,
    currentRoute
  };

  useEffect(() => {
    //@ts-ignore
    if(userRoleString && userRoleString.toLowerCase() === 'te-admin') {
      setHiddenData(true)
    }

    if(userOrgString && userOrgString.toLowerCase() === 'apple') {
      setHiddenData(true)
    }
  },[userRoleString, userOrgString])

  useEffect(() => {
    if (location === "/") {
      return putReport({
        name: "Global Live Water Map",
        parentReportName: "Home",
        root: "/",
      });
    }
  }, [location]);

  useEffect(() => {
    document.getElementById("root")?.addEventListener("click", (e) => {
      if (!isRightSideMenuClicked(e) && !isRightSideBarMenuButtonClicked(e))
        closeRightSideMenu();
    });
  }, []);

  const isRightSideBarMenuButtonClicked = (event: Event): boolean => {
    const hasClass = (elment: Element, className: string) =>
      elment.classList.contains(className);

    const isMenuTrigger = (element: Element) =>
      (element as Element).tagName == "BUTTON" ||
      (element as Element).tagName == "IMG";

    const targetElement: EventTarget | null = event.target;
    const isRightSideMenuTrigger = isMenuTrigger(targetElement as Element);
    if (isRightSideMenuTrigger) {
      const parentElement =
        (targetElement as Element).tagName == "BUTTON"
          ? (targetElement as Element).parentElement
          : (targetElement as Element).parentElement?.parentElement;
      if (
        parentElement?.tagName == "DIV" &&
        hasClass(parentElement, "right-header")
      )
        return true;
    }
    return false;
  };

  const closeShowNewUserAlert = () => {
    setNewUserAlert();
    setShowNewUserAlert(false);
  };

  const closeShowHelpAlert = () => {
    setShowHelpAlert(false);
    localStorage.setItem("app_alerthelpA", "true");
  };

  const isRightSideMenuClicked = (event: Event): boolean => {
    const selector = ".sidebar-right, .sidebar-right *";
    return (event.target as Element).matches(selector);
  };

  const closeRightSideMenu = () => {
    setMenuRightOpen(false);
  };

  const RefContextValue: IRefContext = {
    currentRef: currentRef,
    setCurrentRef: (input: object) => {
      setCurrentRef(input);
    },
  };

  const LayerDataContextValue: ILayerDataContext = {
    currentLayerData: currentLayerData,
    setCurrentLayerData: (input: object) => {
      setCurrentLayerData(input);
    },
  };

  const pointContextValue: IPointContext = {
    currentPoint: currentPoint,
    setCurrentPoint: (input: object) => {
      setCurrentPoint(input);
    },
  };

  const contextValue: IAppContext = {
    selectedHUC8: selectedHUC8,
    setHUC8: (huc8: string) => {
      setSelectedHUC8(huc8);
    },
    currentLocation: currentLocation,
    setCurrentLocation: (location: string) => {
      setCurrentLocation(location);
    },
    updateContext: (huc8: string, location: string) => {
      setCurrentLocation(location);
      setSelectedHUC8(huc8);
      localStorage.setItem(
        LOCALSTORAGE_DEFAULTS_KEY,
        JSON.stringify({ location: location, huc8: huc8 })
      );
    },
  };

  const activePopupContextValue = {
    activePopup: activePopup,
    setActivePopup: (popup: any) => {
      setActivePopup(popup)
    }
  }

  const { initialized } = useKeycloak();
  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* @ts-ignore */}
      <ActivePopupContextProvider value={activePopupContextValue}>
        <LayerDataContextProvider value={LayerDataContextValue}>
          <RefContextProvider value={RefContextValue}>
            <PointContextProvider value={pointContextValue}>
              <AppContextProvider value={contextValue}>
                <CatalogsContextWrapper>
                  <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                      <QueryParamProvider adapter={ReactRouter6Adapter}>
                        <QueryString global={global} />
                        <UserInfoSession global={global} />
                        <Render condition={userLogged}>
                          <header>
                            <NavigationBar
                              global={global}
                              navTopMenu={global.navTopMenu}
                            />
                          </header>
                        </Render>

                        <Row
                          id="mainRow"
                          className={`container-row  main-row ${
                            global.navTopMenu === "Insights" ||
                            global.navTopMenu === "Climate" ||
                            global.navTopMenu === "Risk"
                              ? "app-insights"
                              : "app-other"
                          }`}
                        >
                          {" "}
                          <Render condition={userLogged}>
                            {location !== ReportsRoutes.WaterMonitoring && (
                              <Col className="container-col sidebar-container sidebar-menu">
                                <SideBar global={global} />
                              </Col>
                            )}
                          </Render>
                          <Routes>
                            <Route
                              path={ReportsRoutes.WaterMonitoring}
                              element={
                                <ProtectedRoute>
                                  <CustomerRestricted
                                    global={global}
                                    userOrgString={userOrgString}
                                    path={ReportsRoutes.WaterMonitoring}
                                  >
                                    <WaterMonitoring global={global} />
                                  </CustomerRestricted>
                                </ProtectedRoute>
                              }
                            />
                            <Route path="*" element={<></>} />
                          </Routes>
                          <Col
                            id="reportsContainer"
                            className="container-col reports-container"
                          >
                            <Render condition={userLogged}>
                              <WorkflowControl global={global}></WorkflowControl>
                            </Render>
                            <Routes>
                              <Route
                                path="/ContactAdmin"
                                element={
                                  <ContactAdmin global={global} logged={true} />
                                }
                              />
                              <Route
                                path="/login"
                                element={
                                  <Login
                                    setUserInfo={setUserInfo}
                                    setUserRole={setUserRole}
                                    setUserRoles={setUserRoles}
                                    setShowNewUserAlert={setShowNewUserAlert}
                                    notify={notify}
                                    global={global}
                                  />
                                }
                              />
                              <Route
                                path="/register"
                                element={
                                  <Register
                                    setUserInfo={setUserInfo}
                                    setUserRole={setUserRole}
                                    setShowNewUserAlert={setShowNewUserAlert}
                                    notify={notify}
                                  />
                                }
                              />
                              {userOrgString && (
                                <>
                                  <Route
                                    path="/"
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/"}
                                          userOrgString={userOrgString}
                                        >
                                          <NationalLiveWaterQualityMap
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.CWAReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.CWAReport}
                                          userOrgString={userOrgString}
                                        >
                                          <CWAReport global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.EPAMajorPermitHolders}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.EPAMajorPermitHolders}
                                          userOrgString={userOrgString}
                                        >
                                          <EPAMajorPermitHolders global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/NationalLiveWaterQualityMap"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/NationalLiveWaterQualityMap"}
                                          userOrgString={userOrgString}
                                        >
                                          <NationalLiveWaterQualityMap
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/WaterStatisticsMeasures"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/WaterStatisticsMeasures"}
                                          userOrgString={userOrgString}
                                        >
                                          <WaterStatisticsMeasuresReport
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.CEJST}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.CEJST}
                                          userOrgString={userOrgString}
                                        >
                                          <CEJSTReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"CEJST-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.IndoHUCs}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.IndoHUCs}
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"IndoHUCs-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.FBFIBReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.FBFIBReport}
                                          userOrgString={userOrgString}
                                        >
                                          <FIBTableReport
                                            global={global}
                                            api={agent.Reports.FBFIB}
                                            apiParam={'Site ID'}
                                            filterConfig={{
                                              date: FBFIBDate, setDate: setFBFIBDate,
                                              dates: FBFIBDates, setDates: setFBFIBDates
                                            }}
                                            reportLayer={FBFIB}
                                            setRepotLayer={setFBFIB}
                                            hasMarker={true}
                                            reportID={"FBFIBReport-report"}
                                            filterID={'Site ID'}
                                            colorArg={'Mpn 100 Ml'}
                                            colorParams={handleFibColor}
                                            setDates={setMECDates}
                                            startDate={MECStartDate} 
                                            endDate={MECEndDate}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.FHBPReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.FHBPReport}
                                          userOrgString={userOrgString}
                                        >
                                          <TableReport
                                            global={global}
                                            api={agent.Reports.FHBP}
                                            apiParam={'location'}
                                            reportLayer={FHBP}
                                            setRepotLayer={setFHBP}
                                            reportID={"FHBPReport-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.OWQPReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.OWQPReport}
                                          userOrgString={userOrgString}
                                        >
                                          <TableReport
                                            global={global}
                                            reportID={"OWQPReport-report"}
                                            centerMarker={true}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.AAV4WaterStress}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.AAV4WaterStress}
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"AAV4WaterStress-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.CVI}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.CVI}
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"CVI-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.Top100Polluters}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.Top100Polluters}
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"Top100Polluters-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.PAWD}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.PAWD}
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"PAWD-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.RainfallForecast}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.RainfallForecast}
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"RainfallForecast-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.ProtectedAreasWorldDatabase}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.ProtectedAreasWorldDatabase
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={
                                              "ProtectedAreasWorldDatabase-report"
                                            }
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.AqueductWaterRiskAtlas}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.AqueductWaterRiskAtlas
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={
                                              "AqueductWaterRiskAtlas-report"
                                            }
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.USGSHuc12}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.USGSHuc12}
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"USGSHuc12-report"}
                                            graph={true}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WIImpairedWatersList}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.WIImpairedWatersList}
                                          userOrgString={userOrgString}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"WIImpairedWatersList-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.FIBReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.FIBReport
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <FIBReport
                                            global={global}
                                            dates={MECDates}
                                            setDates={setMECDates}
                                            startDate={MECStartDate} 
                                            endDate={MECEndDate}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.DailyLakeMeadLevelsReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.DailyLakeMeadLevelsReport
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <DailyLakeMeadLevelsReport
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/WhatsInMySurfaceWater"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/WhatsInMySurfaceWater"}
                                          userOrgString={userOrgString}
                                        >
                                          <WhatsInMySurfaceWater global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.MECReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.MECReport}
                                          userOrgString={userOrgString}
                                        >
                                          <MECReport 
                                            global={global}
                                            setDates={setMECDates}
                                            startDate={MECStartDate} 
                                            endDate={MECEndDate}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WiseReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.WiseReport}
                                          userOrgString={userOrgString}
                                        >
                                          <WiseReport global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.NSGReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.NSGReport}
                                          userOrgString={userOrgString}
                                        >
                                          <NSGReport global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/WhatsInMyWatershed"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/WhatsInMyWatershed"}
                                          userOrgString={userOrgString}
                                        >
                                          <WhatsInMyWatershed global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={
                                      ReportsRoutes.ToxicsReleaseInventoryReport
                                    }
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.ToxicsReleaseInventoryReport
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <ToxicsRealeaseInventory
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.AGWaterTrueQIForecast}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.AGWaterTrueQIForecast}
                                          userOrgString={userOrgString}
                                        >
                                          <AGWaterTrueQIForecast global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.StormWaterTrueQIForecast}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.StormWaterTrueQIForecast
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <StormWaterTrueQIForecast
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.FacilityRegistrySystem}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.FacilityRegistrySystem
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <FacilityRegistrySystem global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.DischargeMonitoring}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.DischargeMonitoring}
                                          userOrgString={userOrgString}
                                        >
                                          <DischargeMonitoring global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/WastewaterTrueQIScores"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/WastewaterTrueQIScores"}
                                          userOrgString={userOrgString}
                                        >
                                          <BaseReport />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/WastewaterTrueQIScores/:id"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/WastewaterTrueQIScores/:id"}
                                          userOrgString={userOrgString}
                                        >
                                          <BaseReport />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/WeatherHistoryAndFutureForecasts"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/WeatherHistoryAndFutureForecasts"}
                                          userOrgString={userOrgString}
                                        >
                                          <BaseReport />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={
                                      ReportsRoutes.NationalStormWaterQualityDatabase2020
                                    }
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.NationalStormWaterQualityDatabase2020
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <NationalStormWaterQualityDatabase
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.SurfaceWaterFlowRate}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.SurfaceWaterFlowRate}
                                          userOrgString={userOrgString}
                                        >
                                          <SurfaceWaterFlowRate global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={
                                      ReportsRoutes.NationalStreamQualityAccountingNetwork
                                    }
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.NationalStreamQualityAccountingNetwork
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <NationalStreamQualityAccountingNetwork
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.PermitDischargeReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.PermitDischargeReport}
                                          userOrgString={userOrgString}
                                        >
                                          <PermitDischargeReport global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/NationalStormWaterQualityDatabase2020"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            "/NationalStormWaterQualityDatabase2020"
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <BaseReport />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/DrinkingWaterTrueQIReport"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/DrinkingWaterTrueQIReport"}
                                          userOrgString={userOrgString}
                                        >
                                          <DrinkingWaterTrueQIReport
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/DrinkingWaterTrueQIReport/:id"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/DrinkingWaterTrueQIReport/:id"}
                                          userOrgString={userOrgString}
                                        >
                                          <DrinkingWaterTrueQIReport
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.RiskScreening}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.RiskScreening}
                                          userOrgString={userOrgString}
                                        >
                                          <RiskScreening global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={
                                      ReportsRoutes.ClimatePrecipitationCalculator
                                    }
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.ClimatePrecipitationCalculator
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <ClimatePrecipitationCalculator
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.GroundwaterDetermination}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.GroundwaterDetermination
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <GroundwaterDetermination
                                            global={global}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.Risk}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.Risk}
                                          userOrgString={userOrgString}
                                        >
                                          <Risk global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={
                                      "/WhatIndustrialFacilitiesAreInMyWatershed"
                                    }
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            "/WhatIndustrialFacilitiesAreInMyWatershed"
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <BaseReport />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/DomesticWellByPopulationHeatMap"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/DomesticWellByPopulationHeatMap"}
                                          userOrgString={userOrgString}
                                        >
                                          <BaseReport />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.GroundwaterLevels}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.GroundwaterLevels}
                                          userOrgString={userOrgString}
                                        >
                                          <GroundwaterLevels global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/PrincipalAquiferMap"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/PrincipalAquiferMap"}
                                          userOrgString={userOrgString}
                                        >
                                          <BaseReport />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WhatsInMyDrinkingWater}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={
                                            ReportsRoutes.WhatsInMyDrinkingWater
                                          }
                                          userOrgString={userOrgString}
                                        >
                                          <WhatsInMyDrinkingWater global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.PfasEnvironmental}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.PfasEnvironmental}
                                          userOrgString={userOrgString}
                                        >
                                          <PfasEnvironmental global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.PfasDrinkingWater}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.PfasDrinkingWater}
                                          userOrgString={userOrgString}
                                        >
                                          <PfasDrinkingWater global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.PfasProduction}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.PfasProduction}
                                          userOrgString={userOrgString}
                                        >
                                          <PfasProduction global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/top100Polluters/:id"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={"/top100Polluters/:id"}
                                          userOrgString={userOrgString}
                                        >
                                          <BaseReport />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.LiveWaterSensors}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          path={ReportsRoutes.LiveWaterSensors}
                                          userOrgString={userOrgString}
                                        >
                                          <LiveWaterSensors global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.Dashboard}
                                    element={
                                      <ProtectedRoute>
                                        <Dashboard global={global} />
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/WorkStart"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                        >
                                          <WorkStart global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.SurfacewaterMexico}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.SurfacewaterMexico}
                                        >
                                          <MexicoReport
                                            global={global}
                                            api={agent.Reports.SurfacewaterMexico}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.GroundwaterMexico}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.GroundwaterMexico}
                                        >
                                          <GroundwaterMexico
                                            global={global}
                                            api={agent.Reports.GroundwaterMexico}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.ChemicalDataReporting}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.ChemicalDataReporting}
                                        >
                                          <ChemicalDataReporting global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.GoogleMapsDemo}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.GoogleMapsDemo}
                                        >
                                          <GoogleMapsDemo global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.ViolationData}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.ViolationData}
                                        >
                                          <ViolationDataReport global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />

                                  <Route
                                    path={"/Splash"}
                                    element={
                                      <ProtectedRoute>
                                        {userOrgString && (
                                          <Splash
                                            global={global}
                                            userOrgString={userOrgString}
                                            setShowNewUserAlert={
                                              setShowNewUserAlert
                                            }
                                            showNewUserAlert={showNewUserAlert}
                                          />
                                        )}
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.AustralianBoreData}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.AustralianBoreData}
                                        >
                                          <AustralianBoreData global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.GemstatRaw}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.GemstatRaw}
                                        >
                                          <GemstatRaw
                                            global={global}
                                            reportID={"GemstatRaw-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WildfireBoundaries}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.WildfireBoundaries}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"WildfireBoundaries-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WaterConflict}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.WaterConflict}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"WaterConflict-report"}
                                            WaterConflict={true}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.GBIF}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.GBIF}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            centerMarker={true}
                                            reportID={"GBIF-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.HistoricalFireData}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.HistoricalFireData}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            reportID={"HistoricalFireData-report"}
                                            centerMarker={true}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WaterImpairmentsReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.WaterImpairmentsReport}
                                        >
                                          <WaterImpairmentsReport
                                            global={global}
                                            reportID={"WaterImpairmentsReport-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WaterImpairmentsLakes}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.WaterImpairmentsLakes}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            reportID={"WaterImpairmentsLakes-report"}
                                            centerMarker={true}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WaterImpairmentsPoints}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.WaterImpairmentsPoints}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            reportID={"WaterImpairmentsPoints-report"}
                                            centerMarker={true}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.WaterImpairmentsSteams}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.WaterImpairmentsSteams}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            reportID={"WaterImpairmentsSteams-report"}
                                            centerMarker={true}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.USGSWUCReport}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.USGSWUCReport}
                                        >
                                          <LayerDataReport
                                            global={global}
                                            reportID={"USGSWUC-report"}
                                            centerMarker={true}
                                            graph={true}
                                            filtered={true}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.MenuGemstatRaw}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={ReportsRoutes.MenuGemstatRaw}
                                        >
                                          <MenuGemstatRaw
                                            global={global}
                                            reportID={"MenuGemstat-report"}
                                          />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={ReportsRoutes.Chat}
                                    element={
                                      <ProtectedRoute>
                                        <RoleRestricted path={ReportsRoutes.Chat}>
                                        <Chat global={global} />
                                      </RoleRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                  <Route
                                    path={"/WaterMonitoring"}
                                    element={
                                      <ProtectedRoute>
                                        <CustomerRestricted
                                          global={global}
                                          userOrgString={userOrgString}
                                          path={"/WaterMonitoring"}
                                        >
                                          <WaterMonitoring global={global} />
                                        </CustomerRestricted>
                                      </ProtectedRoute>
                                    }
                                  />
                                </>
                              )}
                              <Route
                                path={ReportsRoutes.GFSHuc12Forecast}
                                element={
                                  <ProtectedRoute>
                                    <GFSHuc12Forecast global={global} />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="/Settings"
                                element={
                                  <ProtectedRoute>
                                    <Settings global={global} />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="/Profiles"
                                element={
                                  <ProtectedRoute>
                                    <Profiles global={global} />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="/users"
                                element={
                                  <ProtectedRoute>
                                    <Users global={global} />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="/organizations"
                                element={
                                  <ProtectedRoute>
                                    <Organizations global={global} />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="/add-organization"
                                element={
                                  <ProtectedRoute>
                                    <AddOrganization global={global} />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="/add-profile"
                                element={
                                  <ProtectedRoute>
                                    <AddProfile global={global} />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="/add-user"
                                element={
                                  <ProtectedRoute>
                                    <AddUser global={global} />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="/userinfo"
                                element={
                                  <ProtectedRoute>
                                    <UserInfo global={global} />
                                  </ProtectedRoute>
                                }
                              />

                              <Route path="/contact" element={<ContactForm />} />

                              <Route path="*" element={<>Not Found</>} />
                            </Routes>
                            <LoadingSpinner active={loading} style={{ top: "0" }} />
                          </Col>
                          {loadingBar && <LoadingBar int={percentDone} />}
                          {!globeView && location !== "/GoogleMapsDemo" && (
                            <ToastContainer />
                          )}
                          {location === "/ChemicalDataReporting" && (
                            <ToastContainer />
                          )}
                        </Row>
                        {/* <LoadingSpinner active={loading} style={{ top: '0' }} /> */}
                        <Render condition={userLogged}>
                          <SideBarRight global={global} location={location} />
                        </Render>
                        <Render condition={userLogged}>
                          <Alert
                            variant="success"
                            closeVariant={"white"}
                            className={"app-alert-main"}
                            show={showNewUserAlert && getNewUserAlert()}
                            onClose={() => closeShowNewUserAlert()}
                            dismissible
                          >
                            <Alert.Heading>{message_welcome}</Alert.Heading>
                            <p>
                              Thank you for logging onto True Elements Insights. 
                            </p>
                            <p>
                              Your account is still being created. A notification
                              will be sent when your subscribed access is available.
                            </p>
                            <Image
                              className={"app-alert-main-img"}
                              src="logo.png"
                            />
                          </Alert>
                          <Alert
                            variant="success"
                            closeVariant={"white"}
                            className={"app-alert-help"}
                            show={waterQualityPortal && showHelpAlert}
                            onClose={() => closeShowHelpAlert()}
                            dismissible
                          >
                            <Alert.Heading>
                              Water Quality Portal Map Layer
                            </Alert.Heading>
                            <p>
                              The Water Quality Portal map layer populates based on
                              custom queries of the EPA's repository of water
                              quality monitoring data.
                            </p>
                            <p>
                              Click{" "}
                              <img
                                src={help_WQP_button}
                                style={{
                                  boxShadow: "0 5px 5px rgba(0, 0, 0, 0.4)",
                                }}
                              />{" "}
                              on the secondary Menu Bar to select criteria to query.
                            </p>
                            <img src={help_WQP} style={{ marginLeft: "6.2%" }} />
                            <Image
                              className={"app-alert-main-img"}
                              src="logo.png"
                            />
                          </Alert>
                        </Render>
                      </QueryParamProvider>
                    </BrowserRouter>
                  </QueryClientProvider>
                </CatalogsContextWrapper>
              </AppContextProvider>
            </PointContextProvider>
          </RefContextProvider>
        </LayerDataContextProvider>
      </ActivePopupContextProvider>
    </>
  );
}

export default App;
