import { useContext, useCallback, memo, useMemo } from "react";
import { RefContext } from "../RefContext";
import { CatalogsContext } from "../CatalogsProvider";
import { AppContext } from "../AppContext";
import { Dropdown } from "react-bootstrap";
import { Location, useLocation, useNavigate } from "react-router";
import "./sidebar.scss";
import "./sidebar.css";
import { Nav } from "react-bootstrap";
import Reports from "./Reports";
import Layers from "./Layers/Layers";
import Filters from "./Filters/Filters";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faGlobe,
  faDesktopAlt,
  faMapMarked,
} from "@fortawesome/pro-solid-svg-icons";
import {
  setLocalReport,
  fetchHuc8
} from "../features/utils";
import dashboardIcon from "../assets/dashboard.svg";
import countries from "../json/country_centroids.json";
import states from "../json/state_centroids.json";
import agent from "../api/agent";

const SideNavBar = ({ global }: any) => {
  const { userRole, userOrg } = global;

  const { currentRef } = useContext(RefContext);
  const catalogsContext = useContext(CatalogsContext);
  const appContext = useContext(AppContext);

  const restrictedRoutesView = useMemo(() => ["/DailyLakeMeadLevelsReport"], []);
  const restrictedRoutesLayers = useMemo(() => ["/DailyLakeMeadLevelsReport"], []);

  const { pathname } = useLocation();
  const location: Location = useLocation();
  const navigate = useNavigate();

  const handleViewSwitch = () => {
    setTimeout(() => {
      global.setGlobeView(!global.globeView);
    }, 1555);
    const { current }: any = currentRef;

    if (current) {
      current.getMap().setFog({});
      global.globeView && current.getFog();

      const currentHuc8GeoPoint = getCurrentHuc8LongLat(appContext.selectedHUC8);
      
      if (currentHuc8GeoPoint) {
        current.flyTo({
          center: [
            parseFloat(currentHuc8GeoPoint.centroid_longitude),
            parseFloat(currentHuc8GeoPoint.centroid_latitude),
          ],
          essential: true,
          zoom: global.globeView === false ? 2 : 13,
        });
      } else {
        current.flyTo({
          center: [39.828175, -98.5795],
          essential: true,
          zoom: global.globeView === false ? 2 : 13,
        });
      }
    }
  }

  const onClickDashboard = useCallback((event: any) => {
    if (pathname === "/Dashboard") {
      const lastLocation = localStorage.getItem("lastlocation");
      if (lastLocation) {
        const returnLocation = JSON.parse(lastLocation);
        setLocalReport(returnLocation.pathname);
        navigate(returnLocation.pathname + returnLocation.search);
      }
    } else {
      const l_temp = location;
      localStorage.setItem("lastlocation", JSON.stringify(l_temp));
      navigate("/Dashboard" + l_temp.search);
    }
  }, [pathname, location, navigate]);

  const getCurrentHuc8LongLat = useCallback((selectedHUC8Value: string, catalog?: any[]) => {
    if (catalog) return catalog.find((x) => x.huc8 == selectedHUC8Value);
    return catalogsContext.huc8Catalog.find((x) => x.huc8 == selectedHUC8Value);
  }, [catalogsContext.huc8Catalog]);

  const switchCountryProps = useMemo(() => ({ global }), [global]);
  const switchStateProps = useMemo(() => ({ global, states, currentCountry: global.currentCountry }), [global, states, global.currentCountry]);

  return (
    <div className="sidebar is-open">
      <Nav.Item
        className={pathname === "/Dashboard" ? "dashboard dashboard-active" : "dashboard"}
        onClick={onClickDashboard}
      >
        <FontAwesomeIcon icon={faDesktopAlt} />
        <span>Dashboard</span>
      </Nav.Item>
      <hr />
      <div className="switch-location-wrapper">
        <Nav.Item className="active switch-country">
          <MemoizedSwitchCountry {...switchCountryProps} />
        </Nav.Item>
        <Nav.Item className="active switch-state">
          <MemoizedSwitchState {...switchStateProps} />
        </Nav.Item>
      </div>
      {!restrictedRoutesView.includes(pathname) && (
        <Nav.Item className="views sidebar-dropdown">
          <strong>View</strong>
          <FontAwesomeIcon icon={faGlobe} />
          <Form.Check
            type="switch"
            checked={global.globeView}
            className="view-switch"
            onChange={handleViewSwitch}
          />
          <FontAwesomeIcon icon={faMap} />
        </Nav.Item>
      )}
      <Nav id="report-btns" className="flex-column pt-2">
        <Nav.Item className="active sidebar-nav-item">
          {!restrictedRoutesLayers.includes(pathname) && <Layers global={global} {...global} />}
        </Nav.Item>
        <Nav.Item className="active sidebar-nav-item">
          <Reports global={global} />
        </Nav.Item>
        <Nav.Item className="active sidebar-nav-item">
          <Filters {...{ ...global }} />
        </Nav.Item>
      </Nav>
    </div>
  );
}

const SwitchState = ({ global, states, currentCountry }: any) => {

  const appContext = useContext(AppContext);
  const statesCentroids = states.find((country: any) => currentCountry === country.countryName);
  const { currentRef } = useContext<any>(RefContext);

  const handleStateChange = useCallback(async (country: any, state: any) => {
    if (state === global.currentState) return;

    global.setCurrentState(state)
    const nation: any = states.find(({ countryName }: any) => countryName === country)!;
    const { latitude, longitude, name, zoom }: any = nation.stateCentroids.find(({ name }: any) => name === state);
    let locationName: any;
    if (latitude && longitude) {
      global.setLoading(true);
      const huc8 = global.currentCountry === 'United States' ? await fetchHuc8(latitude, longitude, global) : ''
      await agent.Utilities.LatLngToCity(latitude, longitude)
        .then((data: any) => {
          locationName =
            data.features.length < 1
              ? `Centroid [latitude,longitude], ${name}`
              : data.features[0].place_name;
        })
        .finally(() => global.setLoading(false));

      if (appContext.updateContext)
        appContext.updateContext?.(huc8 ? huc8 : "", locationName, {}, country);
    }
    if (currentRef.current) {
      return currentRef.current.flyTo({
        center: [longitude, latitude],
        essential: true,
        zoom: zoom,
      });
    }
  }, [global, currentRef]);

  if (statesCentroids) {
    const { stateCentroids } = statesCentroids;
    return (
      <Dropdown className="reports-sidebar state-switch">
        <Dropdown.Toggle id="dropdown-basic" className="state-switch-toggle" style={{ lineHeight: "16px" }}>
          <FontAwesomeIcon icon={faMapMarked} className="mr-2" />
          Select <br /> State/<br />Province
        </Dropdown.Toggle>
        <Dropdown.Menu className={"select-country-menu"}>
          <div className="title">
            <FontAwesomeIcon icon={faMapMarked} className="mr-2" /> Select State/Province
          </div>
          <hr />
          <Form.Select
            onChange={(e) => handleStateChange(currentCountry, e.target.value)}
            style={{
              width: "90%",
              marginLeft: "1rem",
              backgroundColor: "#0C1936",
              color: "white",
              marginBottom: "0.5rem",
            }}
          >
            {stateCentroids.map((state: any) => (
              <option
                key={`inline-${state.name}`}
                selected={global.currentState && global.currentState === state.name ? true : false}
                style={{
                  color: global.statesWithData?.includes(state.name) ? 'green' : 'white',
                  fontWeight: global.statesWithData?.includes(state.name) ? 'bold' : 'normal',
                }}
              >
                {state.name}
              </option>
            ))}
          </Form.Select>
          <h5 style={{ fontSize: "14px", textAlign: "center" }}>
            ***states/provinces with data are indicated in <strong style={{ color: 'green' }}>green</strong>
          </h5>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return <></>;
};

const SwitchCountry = ({ global }: any) => {

  const appContext = useContext(AppContext);
  const { currentRef } = useContext<any>(RefContext);

  const handleCountryChange = useCallback(async (e: any) => {
    if (e === global.currentCountry) return;

    global.setCurrentState('')
    global.setCurrentCountry(e)
    const { lat, long, zoom, name } = countries.find((nation: any) => nation.name === e)!;
    let locationName: any;
    if (lat && long) {
      global.setLoading(true);
      const huc8 = global.currentCountry === 'United States' ? await fetchHuc8(lat, long, global) : ''
      await agent.Utilities.LatLngToCity(lat, long)
        .then((data: any) => {
          locationName =
            data.features.length < 1
              ? `Centroid [latitude,longitude], ${name}`
              : data.features[0].place_name;
        })
        .finally(() => global.setLoading(false));

      if (appContext.updateContext)
        appContext.updateContext?.(huc8 ? huc8 : "", locationName, {}, e);
    }
    if (currentRef.current) {
      return currentRef.current.flyTo({
        center: [long, lat],
        essential: true,
        zoom: zoom,
      });
    }
  }, [global, currentRef]);

  return (
    <Dropdown className="reports-sidebar">
      <Dropdown.Toggle id="dropdown-basic" style={{ lineHeight: "16px" }}>
        <FontAwesomeIcon icon={faMapMarked} className="mr-2" />
        Select <br /> Country
      </Dropdown.Toggle>
      <Dropdown.Menu className={"select-country-menu"}>
        <div className="title">
          <FontAwesomeIcon icon={faMapMarked} className="mr-2" /> Select Country
        </div>
        <hr />
        <Form.Select
          onChange={(e) => setTimeout(() => handleCountryChange(e.target.value), 500)}
          style={{
            width: "90%",
            marginLeft: "1rem",
            backgroundColor: "#0C1936",
            color: "white",
            marginBottom: "0.5rem",
          }}
        >
          {countries.map((country) => (
            <option
              key={`inline-${country.name}`}
              selected={global.currentCountry && global.currentCountry === country.name ? true : false}
              style={{
                color: global.countriesWithData?.includes(country.name) ? 'green' : 'white',
                fontWeight: global.countriesWithData?.includes(country.name) ? 'bold' : 'normal',
              }}
            >
              {country.name}
            </option>
          ))}
        </Form.Select>
        <h5 style={{ fontSize: "14px", textAlign: "center" }}>
          ***countries with data are indicated in <strong style={{ color: 'green' }}>green</strong>
        </h5>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const MemoizedSwitchCountry = memo(SwitchCountry);
const MemoizedSwitchState = memo(SwitchState);

export default SideNavBar;
