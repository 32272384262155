import { useContext, useMemo } from "react";
import { Layer, Source, Popup } from "react-map-gl";
import { CircleLayer } from "mapbox-gl";
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ActivePopupContext } from "../../ActivePopupContext";
import "./gemstat-layer.scss";

const GemstatLayer = ({
    id,
    circleColor,
    tileSet,
    sourceLayer,
    zoom
  }: any) => {

      const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
      const { setCurrentPoint } = useContext(PointContext);
      const { currentRef } = useContext(RefContext);
      const navigate = useNavigate();
 
      // @ts-ignore
      const mapInstance = currentRef && currentRef?.current;
      const location = window.location.pathname;
    
      useMemo(
        () =>
          mapInstance &&
          mapInstance.on("click", (event: any) => {
            const layerObject = mapInstance.queryRenderedFeatures(event.point);
            if (layerObject !== undefined && layerObject.length > 0) {
              const feature = layerObject[0];
              setActivePopup({
                id,
                content: renderPopups(feature, event.lngLat)
              });
            }
          }),
        [mapInstance]
      );
            
      const handleRadius = (input: number) => {
        if (input <= 3) {
            return 5;
        } else if (input >= 4 && input <= 7) {
            return 5;
        } else if (input >= 8 && input <= 9) {
            return 7;
        } else if (input >= 9) {
            return 9;
        }
      }
    
      const CircleVectorLayer: CircleLayer = {
        id: `${id}-layer`,
        type: "circle",
        "source-layer": `${sourceLayer}`,
        paint: {
          "circle-radius": handleRadius(zoom),
          "circle-color": circleColor,
          "circle-opacity": 1,
        },
      };
            
      const goToGemstatReport = (input: any) => {
        setCurrentPoint?.({
          id: input["GEMS Station Number"],
          lat: input.Latitude,
          lng: input.Longitude,
        });
        setTimeout(() => {
          location === "/MenuGemstatRaw"
            ? navigate("/MenuGemstatRaw")
            : navigate("/GemstatRaw");
        }, 100);
      };
        
      const handleGemstatPopup = (input: any, lngLat: any) => {
        return (
          <Popup
            longitude={lngLat.lng}
            latitude={lngLat.lat}
            className={"gemstat-popup-main"}
            onClose={() => setActivePopup(null)}
          >
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => setActivePopup(null)}
              style={{
                color: "white",
                position: "absolute",
                top: "5%",
                right: "3%",
                cursor: "pointer",
              }}
            />
            <div className="gemstat-popup-body">
              <a onClick={() => goToGemstatReport(input.properties)}>
                Go to report
              </a>
            </div>
          </Popup>
        );
      };
    
      const renderPopups = (features: any, lngLat: any) => handleGemstatPopup(features, lngLat)
    
      return (
        <>
          <Source id={`${id}-layer`} type="vector" url={tileSet}>
            <Layer {...CircleVectorLayer} />
            {activePopup && activePopup.id === id && activePopup.content}
          </Source>
        </>
      );
}

export default GemstatLayer
