import { useEffect, useContext } from 'react';
import agent from '../api/agent';
import { Popup } from 'react-map-gl';
import { AppContext } from '../AppContext';
import { ActivePopupContext } from '../ActivePopupContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import './huc-popup-styles.scss';

const HucPopup = ({ global, huc12Data = null, huc8Data = null }: any) => {
  const { updateContext } = useContext(AppContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

  useEffect(() => {
    if (huc8Data) {
      agent.Utilities.LatLngToCity(
        huc8Data.centroid_latitude,
        huc8Data.centroid_longitude
      ).then((data: any) => {
        if (data && data.features && data.features[0] && data.features[0].place_name) {
          const location = data.features[0].place_name;
          updateContext?.(
            global.selectedHUC8,
            location,
            global.currentReport,
            'United States'
          );
        }
      });
    }
  }, [huc8Data]);

  useEffect(() => {
    if (huc12Data) {
      agent.Utilities.LatLngToCity(
        huc12Data.centroid_latitude,
        huc12Data.centroid_longitude
      ).then((data: any) => {
        if (data && data.features && data.features[0] && data.features[0].place_name) {
          const location = data.features[0].place_name;
          updateContext?.(
            global.selectedHUC8,
            location,
            global.currentReport,
            'United States'
          );
        }
      });
    }
  }, [huc12Data]);

  useEffect(() => {
    document
      .getElementById('huc-popup')
      ?.addEventListener('contextmenu', (event) => event.preventDefault());
  }, []);

  useEffect(() => {
    if (global.showPopup && huc8Data) {
      setActivePopup({
        id: 'huc8Popup',
        content: renderPopup(huc8Data, 'HUC8')
      });
    } else if (global.showPopup && huc12Data) {
      setActivePopup({
        id: 'huc12Popup',
        content: renderPopup(huc12Data, 'HUC12')
      });
    } else {
      setActivePopup({ id: null, content: null });
    }
  }, [global.showPopup, huc8Data, huc12Data]);

  const renderPopup = (data: any, type: string) => {
    const latitude = parseFloat(data.centroid_latitude);
    const longitude = parseFloat(data.centroid_longitude);

    return (
      <Popup
        longitude={longitude}
        latitude={latitude}
        onClose={() => global.setShowPopup(false)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0.5rem'
          }}
        >
          <div className="btn-close-container" style={{ alignSelf: 'end' }}>
            <FontAwesomeIcon icon={faCircleXmark} onClick={() => global.setShowPopup(false)} />
          </div>
          <h4 style={{ fontSize: '14px', textAlign: 'center' }}>Name: {data.name}</h4>
          <h4 style={{ fontSize: '14px' }}>{type}: {type === 'HUC12' && data.huc12 && data.huc12.length === 11 ? '0' : ''}{data[type.toLowerCase()]}</h4>
          <h4 style={{ fontSize: '14px' }}>Area: {data.area_acres} Acres</h4>
        </div>
      </Popup>
    );
  };

  return (
    <>
      {activePopup && activePopup.id && activePopup.content}
    </>
  );
};

export default HucPopup;
