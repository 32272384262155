import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Location, useLocation } from 'react-router-dom';
import Globals from '../../types/GlobalsType';
import { AppContext } from '../../AppContext';
import { CatalogsContext } from '../../CatalogsProvider';
import { RefContext } from '../../RefContext';
import { setLocalReport } from '../utils';
import agent from '../../api/agent';
import { useKeycloak } from '@react-keycloak/web';
import { getUser, parseUserRoles, parseToken } from './createAndLoginUser'

interface userInfo {
    email: string;
    name: string;
    Profiles: any;
    indexkey: number;
    source?: string;
    roles?: any;
    data: { role: string, org_id: number }
}
  
const UserInfoSession = (props: any) => {
    const { global } = props;
    const appContext = useContext(AppContext);
    const catalogsContext = useContext(CatalogsContext);
    const { currentRef } = useContext(RefContext);
    const location: Location = useLocation();
    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        //load user info, matching keycloak with database api
        let useremail = 'user';  //_placeholder

        //_set
        if (global && global.userInfo && global.userInfo.email && global.userInfo.email.length > 4) {
            useremail = global.userInfo.email;
            localStorage.setItem('useremail', useremail);
        } else { //_localstorage useremail - empty on initial start
            const ue_ls = localStorage.getItem('useremail');
            if (ue_ls && ue_ls.length > 4) {
                useremail = ue_ls;
            }
        }

        let userRefresh = localStorage.getItem('user_refresh');
        if (global && global.userInfo && global.userInfo.data) {
            //_User already loaded
        } else {
            //_sessionStorage
            const userInfo_session = sessionStorage.getItem("userInfo");
            let userInfo_s = {} as userInfo;
            if (userInfo_session) {
                userInfo_s = JSON.parse(userInfo_session);
            }
            if (userInfo_s && userInfo_s.email && userInfo_s.email.length > 4 && userRefresh === '1') {
                userInfo_s.indexkey = Math.random();
                setRoleOrg(userInfo_s)
            } else { //_not in sessionStorage
                updateUserInfo();
                localStorage.setItem('user_refresh', '1');
            }
        }

        let access_token = sessionStorage.getItem("authKey");
        if(access_token && access_token.length>0){
           let parsedToken = parseToken(access_token);
           if(parsedToken){
               parseUserRoles(parsedToken, global)
           }                                
        }
    }, []);

    useEffect(() => {
        if ((!global.userOrgString || global.userOrgString === 'none') && localStorage.getItem('UserOrgString')) {
            global.setUserOrgString(localStorage.getItem('UserOrgString'));
        }

    }, [global.userOrgString]);


    useEffect(() => {
        if (global && global.userInfo && global.userInfo.email && global.userInfo.email.length > 4) {
            let savedEmail = "";
            const ue_ls = localStorage.getItem('useremail');
            if (ue_ls && ue_ls.length > 4) {
                savedEmail = ue_ls;
            }
            localStorage.setItem('useremail', global.userInfo.email);
            if (global.userInfo.source && global.userInfo.source === 'login' && global.userInfo.email !== savedEmail) {
                sessionStorage.removeItem("userInfo");
                //console.log('remove info')
                updateUser(global.userInfo);
            } else if (global.userInfo.source && global.userInfo.source === 'login' && global.userInfo.email === savedEmail) {
                updateUser(global.userInfo);
            }
        }
    }, [global.userInfo]);

    useEffect(() => {
        if (keycloak) {
            let roles = [] as any;
            roles = parseUserRoles(keycloak, global, true);   
        }
    }, [keycloak]);

    const updateUser = (userInfo: any) => {
        let userData = userInfo as userInfo;    
        userData.Profiles = ['Researcher']; //++++
        userData.source = 'session';
        const username = userData.email;
        agent.UserManagement.UserGet('', username).then((res: any) => {
            if (res.errorMessage) {
                global.setUserRole('User');
                userData.indexkey = Math.random();
                global.setUserInfo(userData);
            } else {
                if (res && res.body && res.body.length > 0) {
                    userData.data = res.body[0];
                    userData.indexkey = Math.random();
                    setRoleOrg(userData);
                    sessionStorage.setItem("userInfo", JSON.stringify(userData));
                }
            }
        })
    }

    const updateUserInfo = () => {
        if (keycloak.authenticated) {
            let roles = [] as any;
            if (keycloak.resourceAccess && keycloak.resourceAccess['true-elements'] && keycloak.resourceAccess['true-elements'].roles) {
                const loginRoles = keycloak.resourceAccess['true-elements'].roles;
                roles = loginRoles;
            }
            
            global.setUserRoles(roles);

            keycloak.loadUserInfo().then(userInfo => {
                let userData = userInfo as userInfo;
                userData.Profiles = ['Researcher']; //++++
                userData.source = 'session';
                userData.roles = roles;
                const username = userData.email;
                agent.UserManagement.UserGet('', username).then((res: any) => {
                    if (res.errorMessage) {
                        global.setUserRole('user');
                        userData.indexkey = Math.random();
                        global.setUserInfo(userData);
                    } else {
                        if (res && res.body && res.body.length > 0) {
                            userData.data = res.body[0];
                            userData.indexkey = Math.random();
                            setRoleOrg(userData);


                            sessionStorage.setItem("userInfo", JSON.stringify(userData));

                            if (res.body[0].org_id) {
                                
                                agent.UserManagement.OrgGet(res.body[0].org_id).then((resO: any) => {
                                    if (resO && resO.body && resO.body[0] && resO.statusCode === 200) {
                                        global.setUserOrgString(resO.body[0].name);
                                        localStorage.setItem('UserOrgString', resO.body[0].name);
                                    }
                                })
                            }
                        }
                    }
                })
            });
        }
    }

    function setRoleOrg(_userData: any) {
        global.setUserInfo(_userData);
        if (_userData.data && _userData.data.role) {
            if (_userData.data.role.toLowerCase() === 'admin' || _userData.data.role.toLowerCase() === 'te-admin'){
                global.setUserRole(_userData.data.role);
            } else {
                global.setUserRole('User');
            }
        } else {
            global.setUserRole('User');
        }

        if (_userData.data && _userData.data.org_id) {
            global.setUserOrg(_userData.data.org_id);
            setUserPermission(_userData.data.org_id);
            if (_userData.data.org_id === 9 && _userData.source === 'login') {
                global.setSelectedHUC8('02070011');
            }
            if (_userData.data.org_id === 14 && _userData.source === 'login') {
                global.setSelectedHUC8('04040003');
            }
        } else {
            global.setUserOrg(0);
            setUserPermission(0);
        }
    }

    useEffect(() => {
        if (global && global.userOrg) {
            let access_token = sessionStorage.getItem("authKey");
            if(access_token){
                 agent.UserManagement.OrgGet(global.userOrg).then((resO: any) => {
                    if (resO && resO.body && resO.body[0] && resO.statusCode === 200) {
                        global.setUserOrgString(resO.body[0].name);
                        localStorage.setItem('UserOrgString', resO.body[0].name);
                    }
                })               
             }
        }
    }, [global.userOrg]);

    useEffect(() => {
        if (global && global.userRole) {
            global.setUserRoleString(global.userRole);
        }
    }, [global.userRole]);

    function setUserPermission(org_id: any) {
        if (org_id === 9) {    //removed '02060010', '02040303'
            global.setHucRestrict(['02070011', '02070010', '02070008', '02080110']); // org_id 9  02060006)
            //  global.setPermitReports([ReportsRoutes.ClimatePrecipitationCalculator, ReportsRoutes.NationalStormWaterQualityDatabase2020, ReportsRoutes.RiskScreening, ReportsRoutes.NationalStreamQualityAccountingNetwork]);
        }
        if (org_id === 12) {
            global.setHucRestrict(['07130009', '07120002', '07130010', '07120007', '05120109', '07100003', '10230006', '10230002', '10230007']);
            //  global.setPermitReports([ReportsRoutes.ClimatePrecipitationCalculator, ReportsRoutes.NationalStormWaterQualityDatabase2020, ReportsRoutes.RiskScreening, ReportsRoutes.NationalStreamQualityAccountingNetwork]);
        }
    }

    return null;
}



export default UserInfoSession;
