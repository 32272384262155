import { useState } from 'react';
import {
    Layer,
    Source,
    FillLayer,
  } from "react-map-gl";
import settings from "../../settings.json";
import { adjustHexBrightness } from "../../features/utils";




const OutflowLayer = ({ opacity, brightness }: any) => {
  const outflowsFillLayer: FillLayer = {
    id: "outflowslayer",
    type: "fill",
    "source-layer": settings.tileSetURLs.outflows_sourcelayer,
    paint: {
      "fill-opacity": opacity,
      "fill-outline-color": `${adjustHexBrightness("#ffd500", brightness, 10)}`,
      'fill-color': `${adjustHexBrightness('#ffd500', brightness, 10)}`,
    },
  };
    return (
        <>
                <Source
                  id="outflows-source"
                  type="vector"
                  url={settings.tileSetURLs.outflows}
                  >
                  <Layer beforeId={'aerialway'}
                    {...outflowsFillLayer}                
                  />
                </Source>
        </>
    )
}

export default OutflowLayer;    


