import { mapLayersOff, customLayersOff } from '../../utils';

export const MCClearLayers = ({ global, top, reverse }: any) => {

  const handleClearSwitch = () => {
    mapLayersOff(global);
  };

    return (
        <>           
          <div style={(reverse) ? {position: 'absolute', bottom: top, left: 10} : {position: 'absolute', top: top, left: 10}}  className="mapboxgl-ctrl mapboxgl-ctrl-group">
          	<div className="tools-box">
              <button onClick={handleClearSwitch} className={"map-clearlayers-button"}>
                <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Clear Map Layers" />	                            
              </button>
            </div>
          </div>
        </>
    )
}
                        
export default MCClearLayers  