import React, { useState, useMemo, useEffect } from 'react';
import { Form, Button, Row, Col, FloatingLabel } from 'react-bootstrap';
import './add-user.scss';
import InputForm from '../../components/input-form';
import companyIcon from '../../assets/company-icon.svg';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import SettingsBreadcrumb from '../account/SettingsBreadcrumb';
import agent from '../../api/agent';

export default function UserInfo(props: any){
	const { global } = props;
	global.setUserLogged(true);

	let _mode = 'View';
	let uInfo = global.userInfo || {};
	let _org = '';
 	if(global && global.userInfo){
		if(uInfo.data){
			_org = uInfo.data.org_id;
		}
	} 

	const [ mode, setMode ] = useState(_mode);
	const [ lastName, setLastName ] = useState(uInfo.family_name || '');
	const [ firstName, setFirstName ] = useState(uInfo.given_name || '');
	const [ emailName, setEmailName ] = useState(uInfo.email || '');
	const [ phoneNumber, setPhoneNumber ] = useState(uInfo.phone || '');
    const [ currentUser, setCurrentUser ] = useState(uInfo || {});
	const [ organization, setOrganization ] = useState(_org || '');
    const [ orgName, setOrgName ] = useState('');
	const [ role, setRole ] = useState(uInfo.role || '');
	const [ profile, setProfile ] = useState(uInfo.Profiles || []);

	const user = {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		emailName,
		setEmailName,
		phoneNumber,
		setPhoneNumber
	};

	useEffect(() => {
        agent.UserManagement.UserGet('', uInfo.email).then((res: any) => {
            if(res && res.body){
                const data = [...res.body]
                setCurrentUser(data[0])
            
                if(data[0].name){
                    let namearray = data[0].name.split(' ');
                    if(namearray.length > 1){
                        setLastName(namearray[1])
                    }
                    setFirstName(namearray[0])
                }		
                if(data[0].role){
                    setRole(data[0].role);
					global.setUserRoleString(data[0].role)
                }				
                 /*if(data[0].Profiles){
                    setProfile(data[0].Profiles);
                }		 */
                if(data.length > 0 && data[0].org_id && data[0].org_id>-1){
                    agent.UserManagement.OrgGet(data[0].org_id).then((res: any) => {
                        const orgdata = [] as any;
                        if(res && res.body){
                            const data = res.body
                            if(data.length>0){
                                setOrgName(data[0].name)    
								 global.setUserOrgString(data[0].name)                
                            }
                        }	
                    })   
                }
            }
        })   
	
    }, []);    


	return (
		<div className='add-user'>
			<SettingsBreadcrumb title={'Profile Information'}  subtitle={''}  />
			<h2>Profile Information</h2>
			<Form>
				<div className='container form form-readonly' style={{marginLeft: 20}}>
					<h4>Basic Information</h4>
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingfirstName" label="First Name">
								<Form.Control defaultValue={firstName}  readOnly type="text" placeholder="name@example.com" />
							</FloatingLabel>	
						</Col>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatinglastName" label="Last Name">
								<Form.Control defaultValue={lastName} readOnly type="text" placeholder="Last Name" />
							</FloatingLabel>	
						</Col>
                    </Row>
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingEmail" label="Email">
								<Form.Control defaultValue={emailName} readOnly type="text" placeholder="Email" />
							</FloatingLabel>
						</Col>
					</Row>
					<Row>
 						{/*<Col className='col-md-4'>
							<FloatingLabel controlId="floatingPhoneNumber" label="Phone Number">
								<Form.Control defaultValue={phoneNumber} onChange={e => handleChange(setPhoneNumber, e.target.value)} type="text" placeholder="Phone Number" />
							</FloatingLabel>
						</Col> */}
						<Col className='col-md-4'>
						  <FloatingLabel controlId="floatingSelect" label="Organization">
                            <Form.Control defaultValue={orgName} readOnly type="text" placeholder="Organization" />

						   </FloatingLabel>
						</Col>
					</Row>

					<h4>Profile {'&'} Roles</h4>
					
					<Row>
						<Col className='col-md-4' >
							<FloatingLabel controlId="floatingEmail" label="Role">
								<Form.Control defaultValue={role.charAt(0).toUpperCase() + role.slice(1)} readOnly  type="text" placeholder="Role" />
							</FloatingLabel>
						</Col>
					</Row>
					
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingEmail" label="Profiles">
								<Form.Control defaultValue={profile} readOnly  type="text" placeholder="Profiles" />
							</FloatingLabel>
						</Col>
					</Row>
				</div>
				<div className='footer'>
				</div>
			</Form>
		</div>
	);
}


