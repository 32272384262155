import axios, { AxiosResponse } from 'axios';
import { AnyAaaaRecord } from 'dns';
import { request } from 'http';
import json from '../settings.json';
import LiveWaterSensorsResponseBodyModel from '../types/LiveWaterSensorsResponseBodyModel';
import GeneralResponseModel from './GeneralResponseModel';
import {
  LakeMeadModel,
  LatLngToHuc8Response,
  EPAHealthEffects,
  WasteWaterModel,
  WhatsInMyWatershedModel,
  GroundwaterModel,
  WhatsInMyDrinkingWaterModel,
  RiskScreeningModel,
  ClimatePrecipitationCalculatorModel,
  AGWaterTrueQIForecastModel,
  StormWaterTrueQIForecastModel,
  ToxicReleaseInvetoryResponseModel as ToxicsReleaseInvetoryResponseModel,
  DischargeMonitoringModel,
  PermitDischargeReportModel,
  SurfaceWaterFlowRateModel,
  NationalStreamQualityAccountingNetworkModel,
} from './models';
import { toast } from 'react-toastify';

let _authorizing: Promise<void> | null = null;
axios.defaults.baseURL = json.apiBaseURl;
axios.defaults.timeout = json.axiosTimeout;

let lastNotification = "";
let skipNotification = false;

const notify = (input: string) => {
  if(input === lastNotification){
    if(!skipNotification){
      skipNotification = true;
      setTimeout(() => {
        lastNotification = "";
        skipNotification = false;
      }, 4444);        
    }
    return;
  } else {
    lastNotification = input;
  }
  toast(input);
}   


let headers = {
    'Authorization': `Bearer ${sessionStorage.getItem('authKey')}`,
};

axios.interceptors.request.use((config: any) => {
  if (config.headers) { 
    if(config.url && (config.url.substr(0,41) ===`${json.Keycloak.Url}/admin/realms/`.substr(0,41))){
        headers = {
            'Authorization': `Bearer ${sessionStorage.getItem('authKey')}`,
        };
        config.headers = { ...config.headers,  'Authorization': `Bearer ${sessionStorage.getItem('authKey')}`}
    } else {
      let x_api_key: string = config.headers[json.apiHeader] = json.apiKey;
      let keycloakToken: any | string = config.headers['keycloakToken'] = sessionStorage.getItem('authKey');
      config.headers = { ...config.headers, 'x-api-key': x_api_key, keycloakToken }
    }           
    return config
  }
  return config;
});

axios.interceptors.response.use(async (response: any) => {
  try { 
    if((response && response.data && response.data.statusCode)||(response.status)){
      if(response.data.statusCode === 401||response.status === 401){
        if((response.data.body && response.data.body === 'Unauthorized')||(response.data && response.data === 'Unauthorized')){
          console.log('Unauthorized');

          sessionStorage.removeItem("authKey");
          sessionStorage.removeItem("refreshToken");

          const permitUnauth = ["/login", "/Login", "/Splash", "/Register"];
          if(window.location.pathname !== "/login"){
            if(localStorage.getItem('authorized' + localStorage.getItem('currentUser'))){
                window.location.href = `${window.location.origin}/login`;
            } else if (permitUnauth.indexOf(window.location.pathname) < 0){
                window.location.href = `${window.location.origin}/login`;
            }
          }

          //window.location.href = `${window.location.origin}/login`;
/*          if(!sessionStorage.getItem("refreshToken")){
            sessionStorage.removeItem("authKey");
            response.data.body = null;
          } else {
            sessionStorage.removeItem("authKey");
            let _refreshToken = sessionStorage.getItem("refreshToken");
            _authorizing ??= (_refreshToken ? refreshSession : authorize)()
            .finally(() => _authorizing = null)
            .catch(error => refreshError(error)); //Promise.reject(error), 

            const originalRequestConfig = response.config;
            return _authorizing.then(() => axios.request(originalRequestConfig));
          }
          */
          
        }
      } 
    }

    return response;

  } catch (error) {
    console.log('catch agent', error);

    return await Promise.reject(error);
  }
}, function (error) {
    if(error && error.message && error.message === "Network Error"){
      console.log("Network Error", error);
      if(error.config && error.config.url&& error.config.url.substr(0, 20) === '/pfas_drinking_water_testing'.substr(0, 20)){
         console.log("Network Error pfas_drinking_water_testing", error);
      } else {
         notify("Network Error")
      }      
      //refreshSession();
    }

    // Test authorization when initializing User Management
    if (error.config && error.config.url && error.config.url===`${json.Keycloak.Url}/admin/realms/trueelements/events?type=LOGIN`){
          window.location.href = `${window.location.origin}/login`;
    }

    if((error && error.response && error.response.status)){
      if(error.response.status === 401){
        if((error.response.data && error.response.data === 'Unauthorized')){
          console.log('Unauthorized');
          sessionStorage.removeItem("authKey");
          sessionStorage.removeItem("refreshToken");          
          const permitUnauth = ["/login", "/Login", "/Splash", "/Register"];
          if(window.location.pathname !== "/login"){
            if(localStorage.getItem('authorized' + localStorage.getItem('currentUser'))){
                window.location.href = `${window.location.origin}/login`;
            } else if (permitUnauth.indexOf(window.location.pathname) < 0){
                window.location.href = `${window.location.origin}/login`;
            }
          }
        } 
      } 
    }

    return Promise.reject(error);
  });  

function refreshError(error:any){
  sessionStorage.removeItem("refreshToken")
  Promise.reject(error)
}

const authorize = async () => {

};

async function refreshSession(){
      const refreshToken = sessionStorage.getItem("refreshToken") || '';
      console.log('refreshSession');
      const url = json.logon;
      const client_id = json.Keycloak.ClientId; 
      const data2 = new URLSearchParams();
          data2.append('grant_type', 'refresh_token');
        data2.append('client_id', client_id);
        data2.append('refresh_token', refreshToken);
    
      try {
         const response: AxiosResponse = await axios.post(url, data2.toString(),  { transformRequest: [(data, headers) => {
                //@ts-ignore
                 delete headers.keycloakToken
                 delete headers['x-api-key']
               return data
          }] })
          const { access_token, refresh_token } = response.data;

          sessionStorage.setItem("authKey", access_token);
          sessionStorage.setItem("refreshToken", refresh_token);
      } catch (error: any) {
          console.log(error);
          console.error('Login failed:', error.message);
          sessionStorage.removeItem("authKey");
          sessionStorage.removeItem("refreshToken");
          //toast.error('Unauthorized  - Please sign in again or contact administrator if it persists.');
          
         if(window.location.pathname !== "/login"){
            window.location.href = `${window.location.origin}/login`;
          }
      }
};


const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  getKeycloak: <T>(url: string) => axios.get<T>(url).then(responseBody),
  postKeycloak: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  delKeycloak: <T>(url: string, body: {}) => axios.delete<T>(url, { data: body }).then(responseBody),
};

const Reports = {
  getWiseMetaData: (countryCode: string) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.GET_WISE_META}?ENV=${json.apiEnvironment}&COUNTRYCODE=${countryCode}`
    ),
  getWiseData: (countryCode: string, table: any, page: number) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.GET_WISE}?ENV=${json.apiEnvironment}&COUNTRYCODE=${countryCode}&TABLE=${table}&PAGE=${page}`
    ),
  NSG: (siteId: string, dataset: any, page: number) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.NSG}?ENV=${json.apiEnvironment}&DATASET=${dataset}&SITEID=${siteId}&PAGE=${page}`
    ),
  NSGMeta: (id: string) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.NSGMeta}?ENV=${json.apiEnvironment}&SITEID=${id}`
    ),
  FBFIB: (id: string) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.FBFIB}?&SITE_ID=${id}&ENV=${json.apiEnvironment}`
    ),
  FHBP: (loc: string) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.FHBP}?ENV=${json.apiEnvironment}&LOCATION=${loc}`
    ),
  CWA: (loc: any, mmt: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.CWA}?LOCATION_ID=${loc}&MEASUREMENT_ID=${mmt}&ENV=${json.apiEnvironment}`
    ),
  GFSHuc12Forecast: (huc12: any, date: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.GFSHuc12Forecast}?ENV=${json.apiEnvironment}&HUC12=${huc12}&DATE=${date}`
    ),
  GemstatRaw: (stationID: any) =>
      requests.get<GeneralResponseModel>(
      `${json.apiMethods.GemstatRaw}?ENV=${json.apiEnvironment}&STATION_NUMBER=${stationID}`
    ),
  AusBoreData: (hydroid: number) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.AusBoreData}?ENV=${json.apiEnvironment}&HYDROID=${hydroid}`
    ),
  ViolationData: (pwsid: string) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.ViolationData}?ENV=${json.apiEnvironment}&PWSID=${pwsid}`
    ),
  AgriculturalWaterTrueQI: () =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.AgriculturalWaterTrueQI}?ENV=${json.apiEnvironment}`
    ),
  StormwaterTrueQI: () =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.StormwaterTrueQI}?ENV=${json.apiEnvironment}`
    ),
  EPAMajorPermitHolders: (huc8: string, page: number = 0, resultsPerPage: number = 100) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.EPAMajorPermitHolders}?ENV=${json.apiEnvironment}&HUC8=${huc8}&PER_PAGE=${resultsPerPage}&PAGE_NUM=${page}`
    ),
  LakeMead: () =>
    requests.get<LakeMeadModel>(`${json.apiMethods.lake_mead}?ENV=${json.apiEnvironment}`),
  WasteWaterTrueQIScores: (huc8: string) =>
    requests.get<WasteWaterModel>(
      `${json.apiMethods.waste_water}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  WhatsInMyWatershed: (huc8: string) =>
    requests.get<WhatsInMyWatershedModel>(
      `${json.apiMethods.whats_in_my_watershed}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  AGWaterTrueQIForecast: (huc8: string, days: number, start: string = '') =>
    requests.get<AGWaterTrueQIForecastModel>(
      `${json.apiMethods.AGWaterTrueQIForecast}?ENV=${json.apiEnvironment}&HUC8=${huc8}&DAYS=${days}&START=${start}`
    ),
  StormWaterTrueQIForecast: (huc8: string, days: number, start: string = '') =>
    requests.get<StormWaterTrueQIForecastModel>(
      `${json.apiMethods.StormWaterTrueQIForecast}?ENV=${json.apiEnvironment}&HUC8=${huc8}&DAYS=${days}&START=${start}`
    ),
  GroundwaterLevels: (huc8: string) =>
    requests.get<GroundwaterModel>(
      `${json.apiMethods.GroundwaterLevels}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  WhatsInMyDrinkingWater: (huc8: string) =>
    requests.get<WhatsInMyDrinkingWaterModel>(
      `${json.apiMethods.UCMR}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  RiskScreening: (state: any) =>
    requests.get<RiskScreeningModel>(
      `${json.apiMethods.RiskScreening}?ENV=${json.apiEnvironment}&STATE=${state}`
    ),
  ClimatePrecipitationCalculator: (latitude: number, longitude: number, county_data_type: string) =>
    requests.get<ClimatePrecipitationCalculatorModel>(
      // @ts-ignore
      `${json.apiMethods.ClimatePrecipitationCalculator}?ENV=${json.apiEnvironment}&lat=${latitude}&long=${longitude}&county_data_type=${county_data_type}`
    ),
  DischargeMonitoring: (huc8: string) =>
    requests.get<DischargeMonitoringModel>(
      `${json.apiMethods.DischargeMonitoring}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  PermitDischargeReport: (huc8: string) =>
    requests.get<PermitDischargeReportModel>(
      `${json.apiMethods.PermitDischargeReport}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  SurfaceWaterFlowRate: (huc8: string, days: number = 0, start: string = '') =>
    requests.get<SurfaceWaterFlowRateModel>(
      `${json.apiMethods.SurfaceWaterFlowRate}?ENV=${json.apiEnvironment}&HUC8=${huc8}&DAYS=${days}&START=${start}`
    ),
  NationalStreamQualityAccountingNetwork: () =>
    requests.get<NationalStreamQualityAccountingNetworkModel>(
      // @ts-ignore
      `${json.apiMethods.NationalStreamQualityAccountingNetwork}?ENV=${json.apiEnvironment}`
    ),
  ToxicsReleaseInventory: (huc8: string, page: number = 0) =>
    requests.get<ToxicsReleaseInvetoryResponseModel>(
      `${json.apiMethods.ToxicReleaseInvetory}?ENV=${json.apiEnvironment}&HUC8=${huc8}&PER_PAGE=100&PAGE_NUM=${page}`
    ),
  LiveWaterSensors: (huc8: string, days: number = 0, start: string = '') =>
    requests.get<LiveWaterSensorsResponseBodyModel>(
      // @ts-ignore
      `${json.apiMethods.LiveWaterSensors}?ENV=${json.apiEnvironment}&HUC8=${huc8}&DAYS=${days}&START=${start}`
    ),
  ConsumerConfidenceReport: (zip: string) =>
    requests.get<any>(
      `${json.apiMethods.getConsumerConfidence}?ENV=${json.apiEnvironment}&ZIPCODE=${zip}`
    ),
  FacilityRegistrySystem: (huc8: string, page: number = 0, resultsPerPage: number = 100) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.FacilityRegistrySystem}?ENV=${json.apiEnvironment}&HUC8=${huc8}&PER_PAGE=${resultsPerPage}&PAGE_NUM=${page}`
    ),
  NationalStormWaterDataBase: () =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.StormwaterQualityData}?ENV=${json.apiEnvironment}`
    ),
  GroundwaterMexico: (basinId: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.GroundwaterMexico}?ENV=${json.apiEnvironment}&BASIN=${basinId}`
    ),
  SurfacewaterMexico: (basinId: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.SurfacewaterMexico}?ENV=${json.apiEnvironment}&BASIN=${basinId}`
    ),
  PFA: (huc8: string, page: number = 0, resultsPerPage: number = 100) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.PFA}?ENV=${json.apiEnvironment}&HUC8=${huc8}&PER_PAGE=${resultsPerPage}&PAGE_NUM=${page}`
    ),
  PFASDW: (huc8: string, page: number = 0, resultsPerPage: number = 100) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.PFASDW}?ENV=${json.apiEnvironment}&HUC8=${huc8}&PER_PAGE=${resultsPerPage}&PAGE_NUM=${page}`
    ),
  PFASPr: (huc8: string, page: number = 0, resultsPerPage: number = 100) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.PFASPr}?ENV=${json.apiEnvironment}&HUC8=${huc8}&PER_PAGE=${resultsPerPage}&PAGE_NUM=${page}`
    ),
  CDRConsumerCommercial: (huc8: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.CDRConsumerCommercial}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  CDRIndustrial: (huc8: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.CDRIndustrial}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  CDRManufactureImport: (huc8: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.CDRManufactureImport}?ENV=${json.apiEnvironment}&HUC8=${huc8}`
    ),
  DrinkingWaterPA: (pwsid: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.CCRPA}?ENV=${json.apiEnvironment}&PWSID=${pwsid}`
    ),
  DrinkingWaterFL: (pwsid: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.CCRFL}?ENV=${json.apiEnvironment}&PWSID=${pwsid}`
    ),
  DrinkingWaterWI: (pwsid: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.CCRWI}?ENV=${json.apiEnvironment}&PWSID=${pwsid}`
    ),
  SurfaceWater: (huc:string) => 
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.SurfaceWaterTrueQI}?ENV=${json.apiEnvironment}`
    ),
};

const Utilities = {
  Huc12Flow: (lat: number, lng: number) =>
    requests.get<GeneralResponseModel>(
      // @ts-ignore
      `${json.apiMethods.Huc12Flow}?lat=${lat}&lng=${lng}&ENV=${json.apiEnvironment}`
    ),
  LatLngToHuc8: (latitude: number, longitude: number) =>
    requests.get<LatLngToHuc8Response>(
      `${json.apiMethods.latLongToHuc8}?ENV=${json.apiEnvironment}&LAT=${latitude}&LNG=${longitude}`
    ),
  LatLngToHuc12: (latitude: number, longitude: number) =>
    requests.get<any>(
      `${json.apiMethods.latLongToHuc12}?ENV=${json.apiEnvironment}&LAT=${latitude}&LNG=${longitude}`
    ),
  LatLngToHUCMexico: (lat: any, lng: any) =>
    requests.get<GeneralResponseModel>(
      `${json.apiMethods.MexicoLatLongToHuc8}?ENV=${json.apiEnvironment}&LAT=${lat}&LNG=${lng}`
    ),
  EPAHealthEffects: (cas: string) =>
    requests.get<EPAHealthEffects>(
      `${json.apiMethods.EPAHealthEffects}?ENV=${json.apiEnvironment}&CAS_NUMBER=${cas}`
    ),
  CityToLatLng: (city: string) =>
    requests.get(
      `${json.apiMethods.GeoCodingAPI}/${city}.json?types=place&access_token=${json.maboxKey}`
    ),
  LatLngToCity: (latitude: number, longitude: number) =>
    requests.get(
      `${json.apiMethods.GeoCodingAPI}/${longitude},${latitude}.json?types=place&access_token=${json.maboxKey}`
    ),
  GetDashboard: (user: string) =>
    requests.get(
      `${json.apiMethods.GetDashboard}?ENV=${json.apiEnvironment}&EMAIL=${user}`
    ),
  CreateDashboard: (body: any) =>
    requests.post(
      `${json.apiMethods.CreateDashboard}?ENV=${json.apiEnvironment}&EMAIL=${body.EMAIL}&TITLE=${body.TITLE}&DESCRIPTION=${body.DESCRIPTION}`, body
    ),
  DeleteDashboard: (dashboard: string) =>
    requests.get(
      `${json.apiMethods.DeleteDashboard}?ENV=${json.apiEnvironment}&DASHBOARD_ID=${dashboard}`
    ),
  UpdateDashboard: (body: any) => //REPORT_ID = DASHBOARD_ID
    requests.post(
     `${json.apiMethods.UpdateDashboard}?ENV=${json.apiEnvironment}&REPORT_ID=${body.DASHBOARD_ID}&TITLE=${body.TITLE}&DESCRIPTION=${body.DESCRIPTION}`, body
    ),
  GetReport: (dashboard: string) =>
    requests.get(
      `${json.apiMethods.GetReport}?ENV=${json.apiEnvironment}&DASHBOARD_ID=${dashboard}`
    ),
  CreateReport: (body: any) =>
    requests.post(
      `${json.apiMethods.CreateReport}?ENV=${json.apiEnvironment}&DASHBOARD_ID=${body.DASHBOARD_ID}&REPORT_DATA=${body.REPORT_DATA}&SUBTITLE=${body.SUBTITLE}`, body
  ),
  DeleteReport: (report: string) =>
    requests.get(
      `${json.apiMethods.DeleteReport}?ENV=${json.apiEnvironment}&REPORT_ID=${report}`
    ),
  UpdateReport: (body: any) =>
    requests.post(
      `${json.apiMethods.UpdateReport}?ENV=${json.apiEnvironment}&REPORT_ID=${body.REPORT_ID}&REPORT_DATA=${body.REPORT_DATA}&SUBTITLE=${body.SUBTITLE}`, body
    ),
  WSIO: (huc12: string, MAX_LEVEL: any) =>
    requests.get(
      `${json.apiMethods.WSIO}?ENV=${json.apiEnvironment}&HUC12=${huc12}&MAX_LEVEL=${MAX_LEVEL}`
    ),
  Tilequery: (tileset_id: string, lat: any, lng: any) =>
    requests.get(
      `${json.apiMethods.Tilequery}/${tileset_id}/tilequery/${lng},${lat}.json?limit=100&access_token=${json.maboxKey}`
    ),
};

const UserManagement = {
  Login: (info: object) => {
    requests.post(`${json.logon}`, info)
  },
  KeycloakCreateUser: (info: object) => {
    requests.post(`${json.logon}`, info)
  },
  OrgGet: (org: string) =>
    requests.get(
      `${json.apiMethods.OrgGet}?ENV=${json.apiEnvironment}&ORG_ID=${org}`
    ),
  OrgPost: (body: any) =>
    requests.post(
      `${json.apiMethods.OrgPost}?ENV=${json.apiEnvironment}&EPA_ORG_ID=${body.EPA_ORG_ID}&ORG_NAME=${body.ORG_NAME}&DESCRIPTION=${body.DESCRIPTION}&EMAIL=${body.EMAIL}&IS_ACTIVE=${body.IS_ACTIVE}`, body
    ),
  OrgDelete: (org: string) =>
    requests.get(
      `${json.apiMethods.OrgDelete}?ENV=${json.apiEnvironment}&ORG_ID=${org}`
    ),
  OrgUpdate: (org: string, body: any) => //all posted fields currently req
    requests.post(                      //org_id, {name, email, description, is_active, epa_org_id} 
      `${json.apiMethods.OrgUpdate}?ENV=${json.apiEnvironment}&ORG_ID=${org}&EPA_ORG_ID=${body.EPA_ORG_ID}&ORG_NAME=${body.ORG_NAME}&DESCRIPTION=${body.DESCRIPTION}&EMAIL=${body.EMAIL}&IS_ACTIVE=${body.IS_ACTIVE}`, body
    ),
  UserGet: (org: string, username: string) =>
    requests.get(
      `${json.apiMethods.UserGet}?ENV=${json.apiEnvironment}&ORG_ID=${org}&USERNAME=${username}`
    ),
  UserPost: (body: any) =>
    requests.post(
      `${json.apiMethods.UserPost}?ENV=${json.apiEnvironment}&ROLE=${body.ROLE}&PROFILE=${body.PROFILE}&EMAIL=${body.EMAIL}&ORG_ID=${body.ORG_ID}&NAME=${body.NAME}&USERNAME=${body.USERNAME}&IS_ACTIVE=${body.IS_ACTIVE}`, body
    ),
  UserDelete: (org: string, username: string) =>
    requests.get(
      `${json.apiMethods.UserDelete}?ENV=${json.apiEnvironment}&ORG_ID=${org}&USERNAME=${username}`
    ),
  UserUpdate: (userid: string, body: any) => //WQX_REPORT_ID = ORG_ID
    requests.post(  
      `${json.apiMethods.UserUpdate}?ENV=${json.apiEnvironment}&ROLE=${body.ROLE}&PROFILE=${body.PROFILE}&USER_ID=${userid}&WQX_REPORT_ID=${body.ORG_ID}&NAME=${body.NAME}&IS_ACTIVE=${body.IS_ACTIVE}`, body
    ),
  GetProfile: (allprofiles: boolean) =>
    requests.get(
      `${json.apiMethods.GetProfile}?ENV=${json.apiEnvironment}${(allprofiles) ? '&ALL_PROFILES=True' : '&ALL_PROFILES='}`
    ),
  PostProfile: (body: any) =>
    requests.post(
      `${json.apiMethods.PostProfile}?ENV=${json.apiEnvironment}`, body
    ),
  DeleteProfile: (profile_id: string) =>
    requests.get(
      `${json.apiMethods.DeleteProfile}?ENV=${json.apiEnvironment}&PROFILE_ID=${profile_id}`
    ),
  UpdateProfile: (userid: string, body: any) =>
    requests.post(
      `${json.apiMethods.UpdateProfile}?ENV=${json.apiEnvironment}&PROFILE_ID=${userid}`, body
    ),
  WQXGet: (org: string) =>
    requests.get(
      `${json.apiMethods.WQXGet}?ENV=${json.apiEnvironment}&ORG_ID=${org}`
    ),
  WQXPost: (wqx_params: any, body: any) =>
    requests.post(
      `${json.apiMethods.WQXPost}?ENV=${json.apiEnvironment}&wqx_params=${wqx_params}&params=${wqx_params}`, body
    ),
  WQXUpdate: (wqx_id: string, body: any) =>
    requests.post(
      `${json.apiMethods.WQXUpdate}?ENV=${json.apiEnvironment}&WQX_ID=${wqx_id}&wqx_params=${body}&params=${body}`, body
    ),
  WQXDelete: (wqx_id: string) =>
    requests.get(
      `${json.apiMethods.WQXDelete}?ENV=${json.apiEnvironment}&WQX_ID=${wqx_id}`
    ),
  WQXSubmitter: (body: any) =>
    requests.post(
      `${json.apiMethods.WQXSubmitter}?ENV=${json.apiEnvironment}`, body
    ),
  WQXReferenceData:(domain_title: string) =>
    requests.get(
      `${json.apiMethods.WQXReferenceData}?ENV=${json.apiEnvironment}&DOMAIN_TITLE=${domain_title}`
    ),
  UserImageGet: (userid: string) =>
    requests.get(
      `${json.apiMethods.UserImageGet}?ENV=${json.apiEnvironment}&USER_ID=${userid}`
    ),
  UserImagePost: (userid: string, body: any) =>
    requests.post(
      `${json.apiMethods.UserImagePost}?ENV=${json.apiEnvironment}&USER_ID=${userid}`, body
    ),
  UserImageDelete: (userid: string) =>
    requests.del(
      `${json.apiMethods.UserImageDelete}?ENV=${json.apiEnvironment}&USER_ID=${userid}`
    ),
  UserImageUpdate: (userid: string, body: any) =>
    requests.put(
      `${json.apiMethods.UserImageUpdate}?ENV=${json.apiEnvironment}&USER_ID=${userid}`, body
    ),
  UserPrefGet: (userid: string) =>
    requests.get(
      `${json.apiMethods.UserPrefGet}?ENV=${json.apiEnvironment}&USER_ID=${userid}`
    ),
  UserPrefPost: (userid: string, body: any) =>
    requests.post(
      `${json.apiMethods.UserPrefPost}?ENV=${json.apiEnvironment}&USER_ID=${userid}`, body
    ),
  UserPrefDelete: (userid: string) =>
    requests.del(
      `${json.apiMethods.UserPrefDelete}?ENV=${json.apiEnvironment}&USER_ID=${userid}`
    ),
  UserPrefUpdate: (userid: string, body: any) =>
    requests.put(
      `${json.apiMethods.UserPrefUpdate}?ENV=${json.apiEnvironment}&USER_ID=${userid}`, body
    ),
  UserPref: () =>
    requests.get(
      `${json.apiMethods.UserPref}?ENV=${json.apiEnvironment}`
    ),
  UserLocationGet: (userid: string) =>
    requests.get(
      `${json.apiMethods.UserLocationGet}?ENV=${json.apiEnvironment}&USER_ID=${userid}`
    ),
  UserLocationUpdate: (userid: string, body: any) =>
    requests.put(
      `${json.apiMethods.UserLocationUpdate}?ENV=${json.apiEnvironment}&USER_ID=${userid}`, body
    ),
};

const UserKeycloak = {
  GetUser: (username: string) => 
    requests.getKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/users?username=${username}`
    ),
  GetUserById: (clientId: string) => 
    requests.getKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/users?clientId=${clientId}`
    ),
  AvailableUserRoles: (id: string, client: string) => 
    requests.getKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/users/${id}/role-mappings/clients/${client}/available`
    ),
  CurrentUserRoles: (id: string, client: string) => 
    requests.getKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/users/${id}/role-mappings/clients/${client}`
    ),
  AddUserRole: (id: string, client: string, body: any) => 
    requests.postKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/users/${id}/role-mappings/clients/${client}`, body
    ),
  DeleteUserRole: (id: string, client: string, body: any) => 
    requests.delKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/users/${id}/role-mappings/clients/${client}`, body
    ),
  GetClient: () => 
    requests.getKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/clients?clientId=${json.Keycloak.ClientId}`
    ),
  GetLogin: () => 
    requests.getKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/events?type=LOGIN`
    ),
  GetLoginUserId: (userid: string) => 
    requests.getKeycloak(
      `${json.Keycloak.Url}/admin/realms/${json.Keycloak.Realm}/events?type=LOGIN&user=${userid}`
    ),
};

const WaterMonitoring = {

}

const agent = {
  Reports,
  Utilities,
  UserManagement,
  UserKeycloak,
  WaterMonitoring
};

export default agent;
