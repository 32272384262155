import {
    useState,
    useEffect,
    useContext,
    useMemo,
    useRef,

} from 'react'
import { Form } from 'react-bootstrap'
import Map, {
    Marker,
    FullscreenControl
} from 'react-map-gl'
import agent from '../../../api/agent'
import settings from '../../../settings.json'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { Table } from 'react-bootstrap'
import {
    FullScreen,
    useFullScreenHandle
} from 'react-full-screen';
import ReportHeader from '../shared/ReportHeader';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import RenderLayers from '../shared/RenderLayers';
import MapSlider from '../shared/MapSlider';
import MapTitle from '../shared/MapTitle';
import MapLegend from '../shared/MapLegend';
import MapAddition from  '../shared/MapAddition';

import ReportFooter from '../shared/ReportFooter';
import { AppContext } from '../../../AppContext';
import { PointContext } from '../../../PointContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronRight,
    faChevronLeft,
    faMaximize,
    faMinimize
} from '@fortawesome/pro-solid-svg-icons'
import { getData } from '../../../api/getData'
import CellPopup from './CellPopup'
import { RefContext } from '../../../RefContext'
import ReportOptions from '../shared/ReportOptions'
import { parse } from "papaparse";
import ReportTable from '../shared/ReportTable'
import date from 'date-and-time';
import _ from 'lodash'
import './styles.scss'

const zipcodes = require('zipcodes')
const { abbr } = require('us-state-converter')

const DrinkingWaterTrueQIReport = (props: any) => {
    const { global } = props
    const { currentPoint, setCurrentPoint } = useContext<any>(PointContext)
    const id = currentPoint.zip

    const {
        selectedHUC8,
        updateContext,
        currentLocation
    } = useContext(AppContext);

    const [reportMarkers, setReportMarkers] = useState<any[] | null>(null)
    const [filteredReports, setFilteredReports] = useState<any[] | null>(null)
    const [reportData, setReportData] = useState<any>()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [next, setNext] = useState(10)
    const [back, setBack] = useState(0)
    const [toggledReportData, setToggledReportData] = useState<any[]>([])
    const [activeReport, setActiveReport] = useState<null | number>(0)
    const [casNumbers, setCasNumbers] = useState<any[]>([])
    const [casInfo, setCasInfo] = useState<any[]>([])
    const [key, setKey] = useState<any>()
    const [hover, setHover] = useState(false)
    const [statusMessage, setStatusMessage] = useState<any>(null); 
    const [filteredByYear, setFilteredByYear] = useState()

    const [years, setYears] = useState()
    const FullScreenComp = FullScreen as any;
    const fullscreenhandle = useFullScreenHandle() as any;
    const [newZip, setNewZip] = useState()

    const mapRef = useRef<any>()
    const { setCurrentRef } = useContext(RefContext)

    useEffect(() => {
        setCurrentRef?.(mapRef)
    }, [mapRef])

    useEffect(() => {

        let yearsArray: any = []

        reportData && reportData.map((ele: any) => yearsArray.push(ele.Year))

        // @ts-ignore
        const removeDuplicates: any = [...new Set(yearsArray)];

        setYears(removeDuplicates)

    }, [reportData])

    useEffect(() => {
        let array: any = []

        if (reportData) {
            for (let index = 0; index < reportData.length; index++) {
                const element = reportData[index];
                if (element.Year === global.selectedYearFilter) {
                    array.push(element)
                }
            }
        }

        if (array.length > 0) {
            setFilteredByYear(array)
        }
    }, [
        global.selectedYearFilter,
        reportData
    ])

    
    useEffect(() => years && global.setSelectedYearFilterOptions(years), [years])

    const expandTable = {
        height: '100vh',
        position: 'absolute',
        top: '10%',
        zIndex: 100
    }

    const flyToPoint = (lng: any, lat: any, zoom: any) => {
        global.setCheckFilters([])
        global.setCheckFilter([])

        if (!lng || !lat) {
            global.notify('No data at this location')
            return;
        }


        return mapRef.current.flyTo({
            center: [
                parseFloat(lng),
                parseFloat(lat),
            ],
            essential: true,
            zoom: zoom,
        });
    }

    const filterZipcodes = (input: string) => {
        const reports = reportMarkers && reportMarkers.filter((report: any) => report.Huc8 === input)

        setFilteredReports(reports)
    }


    const paginateNext = () => {
        let itemPerPage = 10
        let itemSlice = filteredReports && filteredReports.slice(back, next).length
        let terminalNum = filteredReports && filteredReports.length % itemPerPage
        if (itemSlice !== terminalNum) {
            setNext(prev => prev + itemPerPage)
            setBack(prev => prev + itemPerPage)
            setActiveReport(null)
        }
    }

    const paginateBack = () => {
        let itemPerPage = 10
        if (back > 0) {
            setNext(prev => prev - itemPerPage)
            setBack(prev => prev - itemPerPage)
            setActiveReport(null)
        }
    }

    useEffect(() => {
        if (!id) {
            setLoading(true)
            getData(settings.apiMethods.getDrinkingWaterTrueQI)
                .then((data) => {
                    const zipified = data.body.map((element: any) =>
                    ({
                        ...zipcodes.lookup(element["Zip Code"]),
                        Huc8: element["Huc8"],
                        zip: element["Zip Code"],
                        score: element.Score
                    }))
                    setLoading(false)
                    setReportMarkers(zipified)
                })
        }
        global.setUserLogged(true);
        if (global.trackerOn) global.setTrackerOn(false)

        return () => setCurrentPoint({})
    }, [])



    const fetchCasInfo = () => {
        return Promise.all([
            ...casNumbers.map((cas: any) =>
                agent.Utilities.EPAHealthEffects(cas)
            )
        ])
            .then(data => {
                setCasInfo(data)
            })
    }

    useEffect(() => {
        fetchCasInfo()
    }, [casNumbers])

    useEffect(() => {
        filteredReports && handleActiveReport(0)
    }, [filteredReports])

    useEffect(() => {
        setTimeout(() => {
            flyToPoint(
                global.viewport.longitude,
                global.viewport.latitude,
                10
            )
        }, 500)
        global.setCheckFilters([])
        global.setCheckFilter([])

    }, [selectedHUC8])

    useEffect(() => {
        setTimeout(() => {
            reportMarkers && filterZipcodes(selectedHUC8)
        }, 500)
    }, [selectedHUC8, reportMarkers])


    useEffect(() => {
        setTimeout(() => {
            flyToPoint(currentPoint.longitude, currentPoint.latitude, 10)
        }, 500)

        if (id) {

            if (currentPoint.detailsTable === "wi_pwsid_detail_datavalue1")
                agent
                    .Reports
                    .DrinkingWaterWI(currentPoint.pwsid)
                    .then(({ body }: any) => {
                        setReportData(body)
                    })

            if (currentPoint.detailsTable === "pa_pwsid_detail_data")
                agent
                    .Reports
                    .DrinkingWaterPA(currentPoint.pwsid)
                    .then(({ body }: any) => {
                        setReportData(body)
                    })

            if (currentPoint.detailsTable === "fl_pwsid_detail_data")
                agent
                    .Reports
                    .DrinkingWaterFL(currentPoint.pwsid)
                    .then(({ body }: any) => {
                        setReportData(body)
                    })

            else agent
                .Reports
                .ConsumerConfidenceReport(currentPoint.zip)
                .then(({ body }: any) => {
                    setReportData(body)
                })
        }


    }, [currentPoint.zip])

    const handleActiveReport = (input: number) => {
        setActiveReport(input)
        const report = filteredReports && filteredReports[input]
        if (!report) {
            global.notify('No data at this location')
            setReportData(null)
        } else {
            agent.Reports.ConsumerConfidenceReport(report.zip).then(data => {
                setReportData(data.body)
            })
        }

        setTimeout(() => {
            flyToPoint(report.longitude, report.latitude, 15)
        }, 100)
    }

    const returnUnique = () => {
        let data = reportData && filteredByYear ? filteredByYear : reportData
        if (data) {
            // @ts-ignore
            const unique = [...new Set(data.map((report: any) => report['Chemical Parameter']))]
            // @ts-ignore
            const cas = [...new Set(data.map((report: any) => report['Cas Number']))]
            global.setCheckFilters(unique)
            setCasNumbers(cas)
        }
    }

    useEffect(() => {
        returnUnique()
    }, [reportData])

    const renderReportSelect = () => {
        if (filteredReports) {
            return (
                <div className="zip-menu">
                    <div className="zip-menu-header">
                        <h3>Zip Codes</h3>
                    </div>
                    {filteredReports.slice(back, next).map((report: any, index: number) => {
                        const className = activeReport === index ? 'report-id active' : 'report-id'
                        return <span onClick={() => handleActiveReport(index)} className={className} key={index}>{report.zip}</span>
                    })}
                </div>
            )
        }
    }

    const renderReportMarkers = () => {
        if (filteredReports) {
            return filteredReports.map((report: any, index: number) => {
                return (
                    <Marker
                        key={index}
                        latitude={report.latitude}
                        longitude={report.longitude}
                    >
                        <img
                            width="48px"
                            style={{ cursor: "pointer" }}
                            src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${report.score}.svg`}
                            alt="score_icon"
                        />
                    </Marker>
                )
            })
        }
    }

    const handleFlagged = (input: any) =>
        input.forEach((e: any, i: any) => {
            if (parseInt(e.Average) / parseInt(e['Mcl Federal']) >= 0.3) {
                document.getElementsByTagName('tr')[i].style.borderColor = 'red'
                document.getElementsByTagName('tr')[i].style.borderTopWidth = '3px'
                document.getElementsByTagName('tr')[i].style.borderBottomWidth = '3px'
            }
        })



    useEffect(() => {
        if (reportData) {
            toggledReportData.length > 0 ?
                handleFlagged(toggledReportData) :
                handleFlagged(reportData)
        }
    }, [
        reportData,
        toggledReportData
    ])


    const tableColumns = useMemo(
        () => [
            {
                Header: 'Contaminant',
                accessor: 'Chemical Parameter',
            }, {
                Header: 'Average',
                accessor: 'Average',
            },
            {
                Header: 'CAS Number',
                accessor: 'Cas Number',
            },
            {
                Header: 'CCR Name',
                accessor: 'Ccr Name',
            },
            {
                Header: 'Classification',
                accessor: 'Classification',
            },
            {
                Header: 'SIC Description',
                accessor: 'Sic Description',
            },
            {
                Header: 'Dlr',
                accessor: 'Dlr',
            },
            {
                Header: 'Major Sources In Drinking Water',
                accessor: 'Major Sources In Drinking Water',
            },
            {
                Header: 'MCL Federal',
                accessor: 'Mcl Federal',
            },
            {
                Header: 'MCL State',
                accessor: 'Mcl State',
            },
            {
                Header: 'MCLG',
                accessor: 'Mclg',
            },
            {
                Header: 'Metropolitan Area',
                accessor: 'Metropolitan Area',
            },
            {
                Header: 'Contaminant',
                accessor: 'Purchased Treated Water Average',
            },
            {
                Header: 'Detected Value [Range]',
                accessor: 'Purchased Treated Water Range',
            },
            {
                Header: 'Treatment Plant',
                accessor: 'Treatment Plant',
            },
            {
                Header: 'Units',
                accessor: 'Units',
            },
            {
                Header: 'Value Range',
                accessor: 'Val Range',
            },
            {
                Header: 'Violation',
                accessor: 'Violation',
            },
            {
                Header: 'Year',
                accessor: 'Year',
            },
        ],
        []
    );

    const tableColumnsPA = useMemo(
        () => [
            {
                Header: 'Activity',
                accessor: 'activity',
            },
            {
                Header: 'Analysis Date',
                accessor: 'analdate',
                Cell: ({ cell }: any) => {
                    return date.format(new Date(cell.value), 'MMMM DD YYYY')
                }
            },
            {
                Header: 'Analysis Method',
                accessor: 'analmeth',
            },
            {
                Header: 'Contaminant',
                accessor: 'contam_description',
            },
            {
                Header: 'Contaminant ID',
                accessor: 'contamid',
            },
            {
                Header: 'EPA Method',
                accessor: 'epamethod',
            },
            {
                Header: 'Lab ID',
                accessor: 'labid',
            },
            {
                Header: 'Lab Sample ID',
                accessor: 'labsmpid',
            },
            {
                Header: 'LOC EP ID',
                accessor: 'loc_ep_id',
            },
            {
                Header: 'LOC Name',
                accessor: 'loc_name',
            },
            {
                Header: 'PA PWSID',
                accessor: 'pa_pwsid',
            },
            {
                Header: 'Population Served',
                accessor: 'population_served',
            },
            {
                Header: 'Contaminant',
                accessor: 'Purchased Treated Water Average',
            },
            {
                Header: 'PWSID',
                accessor: 'pwsid',
            },
            {
                Header: 'Date Received',
                accessor: 'rcvdate',
                Cell: ({ cell }: any) => {
                    return date.format(new Date(cell.value), 'MMMM DD YYYY')
                }
            },
            {
                Header: 'Result',
                accessor: 'result',
            },
            {
                Header: 'Sample Date',
                accessor: 'sampdate',
                Cell: ({ cell }: any) => {
                    return date.format(new Date(cell.value), 'MMMM DD YYYY')
                }
            },
            {
                Header: 'Sample Type',
                accessor: 'sample_type',
            },
            {
                Header: 'Sample Time',
                accessor: 'samptime',
            },
            {
                Header: 'System Name',
                accessor: 'system_name',
            },
            {
                Header: 'System Type',
                accessor: 'system_type',
            },
            {
                Header: 'Units',
                accessor: 'units',
            },
        ],
        []
    );

    const tableColumnsFL = useMemo(
        () => [
            {
                Header: 'Analysis Date',
                accessor: 'analysis_date',
            },
            {
                Header: 'Chlorine Residual',
                accessor: 'chlorine_residual',
            },
            {
                Header: 'Contaminant Code',
                accessor: 'contam_code',
            },
            {
                Header: 'Contaminant',
                accessor: 'contam_desc',
            },
            {
                Header: 'Classification',
                accessor: 'Classification',
            },
            {
                Header: 'Contaminant Group',
                accessor: 'contam_group',
            },
            {
                Header: 'Design Capacity',
                accessor: 'design_capacity',
            },
            {
                Header: 'Entry Point',
                accessor: 'entry_point_',
            },
            {
                Header: 'insp inits',
                accessor: 'insp_inits',
            },
            {
                Header: 'MCL State',
                accessor: 'Mcl State',
            },
            {
                Header: 'Lab Id',
                accessor: 'lab_id',
            },
            {
                Header: 'Location Code',
                accessor: 'location_code',
            },
            {
                Header: 'Mailing Name',
                accessor: 'mailing_name',
            },
            {
                Header: 'Max Quarter',
                accessor: 'max_quarter',
            },
            {
                Header: 'Mcl',
                accessor: 'mcl',
            },
            {
                Header: 'Mdl',
                accessor: 'mdl',
            },
            {
                Header: 'Value Range',
                accessor: 'Val Range',
            },
            {
                Header: 'Method',
                accessor: 'method',
            },
            {
                Header: 'Office',
                accessor: 'office',
            },
            {
                Header: 'Population Served',
                accessor: 'population_served',
            },
            {
                Header: 'PWSID',
                accessor: 'pwsid',
            },
            {
                Header: 'RDL',
                accessor: 'rdl',
            },
            {
                Header: 'Remarks',
                accessor: 'remarks',
            },
            {
                Header: 'Results',
                accessor: 'results',
            },
            {
                Header: 'Results Num',
                accessor: 'results_num',
            },
            {
                Header: 'Sample Date',
                accessor: 'sample_date',
            },
            {
                Header: 'Sample Number',
                accessor: 'sample_number',
            },
            {
                Header: 'Sample Type',
                accessor: 'sample_type',
            },
            {
                Header: 'Subpart',
                accessor: 'subpart_h_',
            },
            {
                Header: 'System Status',
                accessor: 'system_status',
            },
            {
                Header: 'System Type',
                accessor: 'system_type',
            },
            {
                Header: 'Timestamp',
                accessor: 'timestamp',
            },
            {
                Header: 'Units',
                accessor: 'uom',
            },
            {
                Header: 'User Name',
                accessor: 'username',
            },
        ],
        []
    );

    const tableColumnsWI = useMemo(
        () => [
            {
                Header: 'Contaminant',
                accessor: 'contaminant',
            },
            {
                Header: 'Availability',
                accessor: 'availability',
            },
            {
                Header: 'Analysis Method',
                accessor: 'analysismethod',
            },
            {
                Header: 'County',
                accessor: 'county',
            },
            {
                Header: 'DNR Sample Group',
                accessor: 'dnrsamplegroup',
            },
            {
                Header: 'Lab ID',
                accessor: 'labid',
            },
            {
                Header: 'Lab Name',
                accessor: 'labname',
            },
            {
                Header: 'Limit of Detection',
                accessor: 'limitofdetection',
            },
            {
                Header: 'Limit of Quantification',
                accessor: 'limitofquantification',
            },
            {
                Header: 'Maximum Contaminant Limit',
                accessor: 'maximumcontaminantlimit',
            },
            {
                Header: 'MCL units',
                accessor: 'mclunits',
            },
            {
                Header: 'Measured Amount',
                accessor: 'measuredamount',
            },
            {
                Header: 'Population',
                accessor: 'population',
            },
            {
                Header: 'PWSID',
                accessor: 'pwsid',
            },
            {
                Header: 'PWS Name',
                accessor: 'pwsname',
            },
            {
                Header: 'PWS Status',
                accessor: 'pwsstatus',
            },
            {
                Header: 'PWS Type',
                accessor: 'pwstype',
            },
            {
                Header: 'Region',
                accessor: 'region',
            },
            {
                Header: 'Reporting Limit',
                accessor: 'reportinglimit',
            },
            {
                Header: 'Sample Date',
                accessor: 'sampledate',
                Cell: ({ cell }: any) => {
                    return date.format(new Date(cell.value), 'MMMM DD YYYY')
                }
            },
            {
                Header: 'Sampled Well Status',
                accessor: 'sampledwellstatus',
            },
            {
                Header: 'Sample ID',
                accessor: 'sampleid',
            },
            {
                Header: 'Sample Source',
                accessor: 'samplesource',
            },
            {
                Header: 'Sample Type',
                accessor: 'sampletype',
            },
            {
                Header: 'Service Types',
                accessor: 'servicetypes',
            },
            {
                Header: 'Source ID',
                accessor: 'sourceid',
            },
            {
                Header: 'Store T Code',
                accessor: 'storetcode',
            },
            {
                Header: 'Units',
                accessor: 'units',
            },
            {
                Header: 'WI Unique Well Number',
                accessor: 'wiuniquewellno',
            },
            {
                Header: 'Zip Code',
                accessor: 'zip_code',
            },
        ],
        []
    );

    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;



    const handleData = (input: any) => {
        const filters = global.checkFilters
        let toggled: any[] = input

        filters.forEach((filter: any, i: any) => {
            if (filter === input['Chemical Parameter']) {
                toggled.push(input[i])
            }
        })

        setToggledReportData(toggled)
    }


    useEffect(() => {
        if (global.checkFilters && reportData) {
            handleData(reportData)
        }
    }, [
        global.checkFilters,
        reportData
    ])

    const handleTableType = (input: string) => {
        switch (input) {
            case "pa_pwsid_detail_data":
                return tableColumnsPA;
            case "fl_pwsid_detail_data":
                return tableColumnsFL;
            case "wi_pwsid_detail_datavalue1":
                return tableColumnsWI;
            default:
                return tableColumns;
        }
    }

    return (
        <FullScreenComp handle={fullscreenhandle}>
            <div className="report-container" id='DrinkingWaterTrueQIReport-report'>
                <ReportHeader
                    global={global}
                    data={reportData}
                    param={id}
                    reportID={"DrinkingWaterTrueQIReport-report"}
                    fullScreenClickHandle={fullscreenhandle.enter}
                />
                <section className="section-1">
                    <div className="report-map-container">
                        <Map
                            // reuseMaps
                            ref={mapRef}
                            preserveDrawingBuffer={true}
                            mapboxAccessToken={settings.maboxKey}
                            initialViewState={global.viewport}
                            mapStyle={global.mapStyle}
                            projection={global.globeView ? 'globe' : 'mercator' as any}
                            onClick={(e) => {
                                global.onMapClick(e)
                            }}
                            onMove={(e) => {
                                global.setViewport({
                                    longitude: e.viewState.longitude,
                                    latitude: e.viewState.latitude,
                                    zoom: e.viewState.zoom,
                                })
                            }}
                        >
                            <MapAddition global={global} mapRef={mapRef}
                                     position={'low'} zipOff={true} 
                                     MapSliderAdd={true}
                                     statusMessage={statusMessage}/>
                            {id && (
                                <Marker
                                    latitude={currentPoint.latitude}
                                    longitude={currentPoint.longitude}
                                >
                                    <img
                                        width="48px"
                                        style={{ cursor: "pointer" }}
                                        src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${currentPoint.score}.svg`}
                                        alt="score_icon"
                                    />
                                </Marker>
                            )}
                            {filteredReports && renderReportMarkers()}
                            <div className='map-legend-container'>
                                <MapLegend
                                    global={global}
                                    legendWidth={320}
                                />
                            </div>
                        </Map>
                        <LoadingSpinner active={loading} />

                    </div>
                </section>
                {/* @ts-ignore */}
                {filteredReports && (
                    <section className="section-2">
                        <FontAwesomeIcon className="paginate-btn" onClick={paginateBack} icon={faChevronLeft} />
                        {renderReportSelect()}
                        <FontAwesomeIcon className="paginate-btn" onClick={paginateNext} icon={faChevronRight} />
                    </section>
                )}
                {reportData && (
                    // @ts-ignore
                    <section style={open ? expandTable : undefined} className="section-3" >
                        {
                            open ?

                                <button onClick={() => setOpen(false)} className="table-button">
                                    <FontAwesomeIcon className="paginate-btn" icon={faMinimize} />
                                </button>

                                :

                                <button onClick={() => setOpen(true)} className="table-button">
                                    <FontAwesomeIcon className="paginate-btn" icon={faMaximize} />
                                </button>

                        }

                        <div style={open ? { height: '100vh' } : undefined} className="ccr-graph-container">
                            <ReportTable
                                data={toggledReportData.length > 0 ? toggledReportData : reportData}
                                // @ts-ignore
                                columns={handleTableType(currentPoint.detailsTable)}
                            />
                        </div>

                    </section>
                )}

            </div>
            
        </FullScreenComp>
    )
}

export default DrinkingWaterTrueQIReport