import { useState, useContext, useEffect } from 'react';
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { Layer, Source, Popup } from 'react-map-gl';
import { adjustHexBrightness } from "../../features/utils";
import { FillLayer, LineLayer } from 'mapbox-gl';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ActivePopupContext } from '../../ActivePopupContext';

const HydrobasinsCombined = ({ id, global, opacity, tileSet, source, brightness, action }: any) => {
  const [popupData, setPopupData] = useState<any>();
  const { currentRef } = useContext<any>(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const ref = currentRef && currentRef?.current;

  const lngLat = action && action.lngLat;
  const navigate = useNavigate();

  const getFeaturesAtPoint = async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0];
    setPopupData(features.properties);
  };

  const goTo = (input: any[], lat: any, lng: any) => {
    setCurrentPoint({ ...input, lat, lng });
    setTimeout(() => navigate('/HydrobasinsReport'), 1000);
  };

  const handleClose = () => {
    setActivePopup({ id: null, content: null });
    setPopupData(undefined);
  };

  useEffect(() => {
    if (popupData && lngLat) {
      const { lat, lng }: any = lngLat;
      const popupElement = (
        <Popup longitude={lng} latitude={lat} onClose={handleClose} style={{ width: '150px' }}>
          <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
          <div className="popup-content">
            <strong>Feature ID: {popupData.id}</strong>
            <br />
            <button onClick={() => goTo(popupData, lat, lng)}>Go to Report</button>
          </div>
        </Popup>
      );
      setActivePopup({ id, content: popupElement });
    }
  }, [popupData, lngLat]);

  useEffect(() => {
    if (ref) {
      ref.on('click', (e: any) => getFeaturesAtPoint(ref, e));
    }
  }, [ref]);

  const HydrobasinsCombinedFill: FillLayer = {
    id,
    type: "fill",
    "source-layer": source,
    minzoom: 0.38,
    maxzoom: 6.24,
    paint: {
      "fill-opacity": opacity,
      'fill-color': `${adjustHexBrightness("#567A9F", brightness)}`,
    },
  };

  const HydrobasinsCombinedLine: LineLayer = {
    id: `${id}-outline`,
    type: "line",
    "source-layer": source,
    minzoom: 0.38,
    maxzoom: 6.24,
    paint: {
      "line-color": "#000000",
      "line-width": 3,
    },
  };

  return (
    <>
      <Source id={id} type="vector" url={tileSet}>
        {/* <Layer beforeId={`${id}-outline`} {...HydrobasinsCombinedFill} /> */}
        <Layer {...HydrobasinsCombinedLine} />
      </Source>
      {activePopup && activePopup.id === id && activePopup.content}
    </>
  );
};

export default HydrobasinsCombined;
