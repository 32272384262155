import React, { useState, useMemo, useEffect } from 'react';
import { Form, Button, Row, Col, Nav, Modal, Pagination, Dropdown, FormCheck } from 'react-bootstrap';
import ReportTableExpanded from '../reports/shared/ReportTableExpanded';
import { parse } from 'papaparse';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import localForage from "localforage";
import InputForm from '../../components/input-form';
import companyIcon from '../../assets/company-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faGreaterThan, faGear, faCancel, faRectangleXmark, faRotate, faTrashCan, faList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';
import SettingsBreadcrumb from '../account/SettingsBreadcrumb';
import LoadingDataAnimation from "../../components/LoadingDataAnimation";
import agent from '../../api/agent';
import {DateTime} from 'luxon';
import './users.scss';

import Profiles from '../account/Profiles';
import Organizations from './organizations';
import UserProfiles from './userprofiles';
import Logins from './logins';

export default function Users(props: any){
	const { global } = props;
	global.setUserLogged(true);

    const so = localStorage.getItem('settings_org');
    let lastorg = '1';
    if(so && so.length > 0){
        lastorg = so;
    }
 
    

    const [reportData, setReportData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [currentUser, setCurrentUser] = useState<object>({});
    const [currentOrg, setCurrentOrg]  = useState(''+lastorg);
    const [searchText, setSearchText] = useState<string>('');

    const [usersData, setUsersData] = useState<any[]>([]);
    const [organizationsData, setOrganizationsData] = useState<any[]>([]);
    const [organizationsDataAddition, setOrganizationsDataAddition] = useState<any[]>([]);
    const [loginsData, setLoginsData] = useState<any[]>([]);

    const [profilesData, setProfilesData] = useState<any[]>([]);
    const [reportsData, setReportsData] = useState<any[]>([]);

	const [ firstName, setFirstName ] = useState('');
	const [ lastName, setLastName ] = useState('');
	const [ emailName, setEmailName ] = useState('');
	const [ phoneNumber, setPhoneNumber ] = useState('');

    const [ navtab, setnavtab ] = useState('Users');
    const [inactive, setInactive] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteMode, setDeleteMode] = useState<string>('');
    const [isDataReportLoaded, setIsDataReportLoaded] = useState(true);

    const [editUser, setEditUser] = useState<number>(-1);
    const [deleteUser, setDeleteUser] = useState<number>(-1);

    const { pathname } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();

	const user = {
        currentUser,
        setCurrentUser,
		firstName,
		setFirstName,
		lastName,
		setLastName,
		emailName,
		phoneNumber,
		setEmailName,
		setPhoneNumber
	};

    const userdata = [];

    //Org get
    //User get
    //Profile get

    //User Change
    //Org Change
    //Profile Change

    if(location && location.state){
        //@ts-ignore
        if(location.state.org){
            //@ts-ignore
            location.state.org = false; 
   
        }
    }


    let Paginationactive = 1;
    let Paginationitems = [];
    for (let number = 1; number <= 5; number++) {
        Paginationitems.push(
            <Pagination.Item key={number} active={number === Paginationactive}>
            {number}
            </Pagination.Item>,
        );
    }

    useEffect(() => {
        if((global.userRole) && (global.userRole.toLowerCase()!=='te-admin')&&(global.userRole.toLowerCase()!=='admin')) {
            navigate('/Settings');
        }
    }, []);

    useEffect(() => {
        agent.UserManagement.GetProfile(true).then((res: any) => {
            if(res && res.body  && res.body.length > 0 && res.statusCode === 200) {
                setProfilesData(res.body);
            }
        })
        agent.UserManagement.GetProfile(false).then((res: any) => {
            if(res && res.body  && res.body.length > 0 && res.statusCode === 200) {
                setReportsData(res.body);
            }
        })

        //Login data
        agent.UserKeycloak.GetLogin().then((res: any) => { //++
            console.log('GetLogin', res)
              if(res && res.length > 0){
                let newData = [...res]      
                for (let dataItem of newData) {
                    if(dataItem.time){
                        dataItem.timelocal = DateTime.fromMillis(dataItem.time).toLocaleString(DateTime.DATETIME_MED);
                    } 
                    if(dataItem.details && dataItem.details.username){                  
                        dataItem.username = dataItem.details.username;
                    } 
                    if(dataItem.details && !dataItem.details.grant_type && dataItem.details.auth_type){                  
                        dataItem.details.grant_type = dataItem.details.auth_type;
                    } 
                }
                setLoginsData(newData)
              } else {
                setLoginsData([])    
              }
        })    
    }, []);

                         
    useEffect(() => {
        localForage.getItem('OrganizationsData', function(err, value) {
            if(value){
                const orgs = value; //@ts-ignore
                setOrganizationsData(orgs);
            } 
        })    
    }, []);

    useEffect(() => {
		agent.UserManagement.OrgGet('').then((res: any) => {
            const orgdata = [] as any;
            if(res && res.body && res.statusCode === 200) {
                const data = res.body                
                let orgid = 111111111;
                let orgname = '';
                if (global.userInfo && global.userInfo.data && global.userInfo.data.org_id){
                    orgid = global.userInfo.data.org_id;
                }

                data.forEach((dataItem:any, index:number) => {
                    dataItem.id = index;
                    if(+dataItem.org_id === +orgid){
                        orgname = dataItem.name;
                    }
                    if((global.userRole!=='te-admin')) {
                        if(+dataItem.org_id === +orgid){
                            orgdata.push(dataItem);
                        }
                    } else { 
                        orgdata.push(dataItem);
                    }
                    if(typeof dataItem.is_active==='undefined'){                      
                        dataItem.is_active = false;    
                    }
                }); 
                orgdata.sort((x: any, y: any) => x['name'].localeCompare(y['name']));
             
                if(!organizationsData||organizationsData.length===0){
                    setOrganizationsData(orgdata); 
                }
                //setOrganizationsData(orgdata);        
                if (global.userInfo && global.userInfo.data && global.userInfo.data.org_id){
                    const newEvent = { target: { value: ''+global.userInfo.data.org_id,  orgname: orgname}};
                    onChangeOrg(newEvent);
                }

                data.forEach((dataItem:any, index:number) => {
                    if(dataItem.org_id){
                        agent.UserManagement.OrgGet(dataItem.org_id).then((resO: any) => {
                            if(resO && resO.body && resO.body[0] && resO.statusCode === 200) {
                                const orgnewdata = [] as any;
                                orgnewdata.push(resO.body[0])
                                setOrganizationsDataAddition(orgnewdata)

                            }
                        })                         
                    } 
                }); 

            }
		})   
    }, []);

    useEffect(() => {
        if(organizationsDataAddition && organizationsDataAddition.length>0){
            updateOrg(organizationsDataAddition[0])
        }
    }, [organizationsDataAddition]);

    const updateOrg = (updateData: any) => {
        let newData = [...organizationsData];
        let fData = newData.filter((o: any) => +o.org_id === +updateData.org_id);
        setOrganizationsDataAddition([]);
        if(fData && fData.length > 0){
            fData[0].is_active = updateData.is_active;
            fData[0].description= updateData.description;
            fData[0].epa_org_id = updateData.epa_org_id;
            fData[0].email = updateData.email;
            setOrganizationsData(newData);
            localForage.setItem('OrganizationsData', newData)
        } 
    };

    const addEditOrg = (org_id: any, orgaction: any) => {
        if(org_id){
            agent.UserManagement.OrgGet(org_id).then((resO: any) => {
                if(resO && resO.body && resO.body[0] && resO.statusCode === 200) {
                    let newData = [...organizationsData];
                    if(orgaction === 'edit'){
                        let fData = newData.filter((o: any) => +o.org_id === +org_id); 
                        if(fData && fData.length > 0){
                            fData[0] = resO.body[0];    
                        } 
                    } else if(orgaction === 'add'){
                        newData.push(resO.body[0])
                    } 
                    setOrganizationsData(newData);   
                }
            })                                         
        } 
    };


    useEffect(() => {
		agent.UserManagement.UserGet(currentOrg, '').then((res: any) => {
            const orgdata = [] as any;
        
            if(res && res.body && res.statusCode === 200){
                const data = res.body            
                let orgName = '';
                if(organizationsData){
                    let fData = [...organizationsData].filter((o: any) => +o.org_id === +currentOrg);
                    if(fData && fData.length > 0){
                        orgName = fData[0].name;
                    } 
                }
                data.forEach((dataItem:any, index:number) => {
                    dataItem.id = index;
                    dataItem.name = dataItem.username;
                    dataItem.company_name = orgName;

                });  
                setUsersData(orgdata.sort((x: any, y: any) => x['name'].localeCompare(y['name'])));
            }
		})   
    }, []);

    const fillUserData = (updateData: any) => {
        //let updateData = [...data];
        let updateCounter = updateData.length;
        for (var i = 0; i < updateData.length; i++) {
           
            let dataItem = updateData[i];
            let uD = updateData;
            agent.UserManagement.UserGet(currentOrg, updateData[i].username).then((res: any) => {
                if(res && res.body && res.statusCode === 200){   
                    //updateData.filter((o: any) => +o.username === +updateData[i].username);
                    Object.assign(dataItem, res.body[0]);
                    updateCounter = updateCounter - 1;
                    if(dataItem.role && (dataItem.role.toLowerCase() !== 'admin'&&dataItem.role.toLowerCase() !== 'te-admin')){
                        dataItem.role = 'User';
                    }
                    if(updateCounter === 0){
                        setUsersData(uD.sort((x: any, y: any) => x['name'].localeCompare(y['name'])));
                    }
                }  
            }); 
        }
    };

    useEffect(() => {
        setReportData(usersData);
        setFilteredData(usersData);
    }, [usersData]);

    useEffect(() => {
        const nt = localStorage.getItem('usersnavtab');
        if(nt && nt.length > 0){
            setnavtab(nt);
        }
    }, []);      
              
    useEffect(() => {
        if(editUser > -1){
            let fData = [...filteredData].filter((o: any) => +o.id === +editUser);
            if(fData && fData.length > 0){
                setCurrentUser(fData[0])
                //navigate('/add-user', { replace: false, state:{mode: 'edit', addEditOrg: addEditOrg,  user : fData[0],  currentOrg: currentOrg, organizationsData : organizationsData}});
                navigate('/add-user', { replace: false, state:{mode: 'edit',  currentOrg: currentOrg, organizationsData : organizationsData, user : fData[0], reportsData: reportsData, profilesData: profilesData}});
            } 
        }
    }, [editUser]);

    useEffect(() => {
        if(deleteUser > -1){
            let fData = [...filteredData].filter((o: any) => +o.id === +deleteUser);
            if(fData && fData.length > 0){
                setShowDeleteModal(true);
                setDeleteMode('user'); 
                setCurrentUser(fData[0])
            }
        }
    }, [deleteUser]);

    const tableColumns = useMemo(
        () => [
          { Header: 'Name', accessor: 'name' },
          { Header: 'Email', accessor: 'email' },
          { Header: 'Organization', accessor: 'company_name' },
          { Header: 'Profiles', accessor: 'profiles' },
          { Header: 'Role', accessor: 'role' },
          { Header: 'Is Active', accessor: 'is_active',
            Cell: (props: any) => ((props.value) ? ' ' + props.value : ' '),
          },
          {
            Header: 'Actions',
            accessor: 'id',
            Cell: (props: any) => (
              <div className='ct-table-container-button'>
                <Button
                    type='button'
                    className='ct-table-button'
                    onClick={clickEditUserHandler}
                    value={props.value}
                    id={'edituser_' + props.value}>
                       
                    <FontAwesomeIcon icon={faPenToSquare} size='xs' />
                </Button>
                <Button
                    type='button'
                    className='ct-table-button'
                    onClick={clickDeleteHandler}
                    value={props.value}
                    id={'deleteuser_' + props.value}>
                    <FontAwesomeIcon icon={faTrashCan} size='xs' />
                </Button>
              </div>
                ),
          }
        ],
        []
    );
    
    const clickRowHandler = (event: any) => {

    };
    
    const clickAddUserHandler = (event: any) => {   
        navigate('/add-user', { replace: false, state:{mode: 'add',  currentOrg: currentOrg, organizationsData : organizationsData, reportsData: reportsData, profilesData: profilesData}});
    };

    const clickEditUserHandler = (event: any) => {
        if (event && event.target && event.target.value) {
            setEditUser(event.target.value);
        }
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
    };
    
    const handleDeleteConfirm = (event:any) => {  
        setShowDeleteModal(false); 

        let cuid = '11111111';        
        if(currentUser) {
            // @ts-ignore
            cuid = '' + currentUser.username;
        }
        setDeleteMode('');
        if(cuid !== '11111111'){
            agent.UserManagement.UserDelete(currentOrg, cuid).then((res: any) => {      
                console.log(currentOrg, cuid, res)
            })  
        }

        let rData = [...reportData].filter((o: any) => +o.id !== +cuid);
        if(rData && rData.length > 0){
            setReportData(rData)
            setCurrentUser([])
        }   

        let fData = [...filteredData].filter((o: any) => +o.id !== +cuid);
        if(fData && fData.length > 0){
            setFilteredData(fData)
        } 
    };
    
    const clickDeleteHandler = (event: any) => {      
        if (event && event.target && event.target.value) {
            setDeleteUser(event.target.value);
        }
    };

    const onChangeOrg = (event: any) => {

        if (event && event.target && event.target.value) {
            setCurrentOrg(event.target.value);
            localStorage.setItem('settings_org', event.target.value);
            let orgName = '';

            if(event.target.orgname){
                orgName = event.target.orgname;
            } else {
                let fData = [...organizationsData].filter((o: any) => +o.org_id === +event.target.value);
                if(fData && fData.length > 0){
                    orgName = fData[0].name;
                } 
            }

            agent.UserManagement.UserGet(event.target.value, '').then((res: any) => {
                const orgdata = [] as any;
                if(res && res.body){
                    const data = [...res.body]
                    data.forEach((dataItem:any, index:number) => {
                        dataItem.id = index;
                        dataItem.name = dataItem.name;
                        dataItem.email = dataItem.username;
                        dataItem.company_name = orgName;
                        //dataItem.role = 'Regular';
                        //dataItem.profiles = 'Researcher';
                        orgdata.push(dataItem);
                    }); 
                    setUsersData(orgdata)
                    fillUserData([...data]);
                }
            })   
        }
    };

    const onChangeSearch = (event: any) => {
        if (event && event.target && event.target.value && event.target.value.length > 2) {
            let fData = [...reportData].filter((o: any) => o.name.indexOf(event.target.value) > -1);
            setFilteredData(fData);
            setSearchText(event.target.value);
        } else {
            setFilteredData(reportData);
            setSearchText('');
        }       
    };

    useEffect(() => {
        let fData = [...reportData]
        if(!inactive){
            fData = [...filteredData].filter((o: any) => o.is_active===true);
        } else {
            if (searchText && searchText.length > 2) {
                fData = [...reportData].filter((o: any) => o.name.indexOf(searchText) > -1);
            }   
        }
        setFilteredData(fData); 
    }, [inactive]);

    const onSelectNav = (event: any) => {
        setnavtab(event);
        localStorage.setItem('usersnavtab', event);
    };

	return (
		<div className='users'>
            <SettingsBreadcrumb title={'User Management'}/>
            <div className='users-title-bar'>
                <h2>User Management</h2>
                {/* @ts-ignore */}
                <Form.Select id='users-organizations' key={'users-org-'+currentOrg} onChange={onChangeOrg} value={currentOrg} className='users-organizations-select' size="sm" placeholder='Organizations'>
                    {organizationsData.map((dash, index) =>
                        (dash.is_active && <option key={'users-org-options'+index} value={dash.org_id}>{dash.name}</option>)
                    )}
                </Form.Select>  
            </div>

            <div className='users-nav-bar'>
                <Nav
                    activeKey={navtab}
                    onSelect={onSelectNav}
                    className={'users-nav-container'}>
                    <Nav.Item className={(navtab === 'Users') ? 'users-nav-item users-nav-item-active' : 'users-nav-item'}>
                        <Nav.Link eventKey="Users" className={'users-nav-link'}>Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item  className={(navtab === 'Profiles') ? 'users-nav-item users-nav-item-active' : 'users-nav-item'}>
                        <Nav.Link eventKey="Profiles" className={'users-nav-link'}>Profiles</Nav.Link>
                    </Nav.Item>
                    <Nav.Item  className={(navtab === 'Organizations') ? 'users-nav-item users-nav-item-active' : 'users-nav-item'}>
                        <Nav.Link eventKey="Organizations"  className={'users-nav-link'}>Organizations</Nav.Link>
                    </Nav.Item>
                    <Nav.Item  className={(navtab === 'Logins') ? 'users-nav-item users-nav-item-active' : 'users-nav-item'}>
                        <Nav.Link eventKey="Logins"  className={'users-nav-link'}>Logins</Nav.Link>
                    </Nav.Item>
                </Nav>


            </div>
            <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm deletion</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="secondary"  onClick={handleDeleteCancel}>
                  Cancel
                </Button>
                <Button variant="primary" id={'confirm' + 'dashtimsestamp'} onClick={handleDeleteConfirm}>
                  Delete
                </Button>
              </Modal.Footer>  
            </Modal>   

            <div className='users-container' style={(navtab === 'Users' ? {display: 'block'} : {display: 'none'})}>                
                <div className='users-tool-container'>
                    <Form.Group  className='users-search' controlId="formSearch">
                        <Form.Label></Form.Label>
                        <Form.Control className='users-search-users' onChange={onChangeSearch} type="text" placeholder=" Search" />
                    </Form.Group>
                    <div className='org-table-header'>
                        <FormCheck                               
                            onChange={() => setInactive(!inactive)}
                            label={"Include inactive"}
                            checked={inactive}
                       
                        />
                        <Button className='users-button' onClick={clickAddUserHandler}>
                            Add User    
                        </Button>
                    </div>

                </div>

                <div className='table-parent-container'>
                    <LoadingDataAnimation dataLoading={!isDataReportLoaded} />

                        <ReportTableExpanded
                            data={filteredData}
                            columns={tableColumns}
                            clickRowHandler={clickRowHandler}
                            clickLoadStop={true}
                        />
                        <div className='users-pagination'>
                            <Pagination size="sm">{Paginationitems}</Pagination>
                        </div>
                </div>
            </div>

            <div className='users-profiles-container' style={(navtab === 'Profiles' ? {display: 'block'} : {display: 'none'})}> 
                <UserProfiles global={global} />
            </div>

            <div className='users-organizations-container' style={(navtab === 'Organizations' ? {display: 'block'} : {display: 'none'})}> 
                <Organizations global={global} organizationaldata={organizationsData}/>
            </div>

            <div className='users-logins-container' style={(navtab === 'Logins' ? {display: 'block'} : {display: 'none'})}> 
                <Logins global={global} loginsdata={loginsData}/>
            </div>
		</div>
	);
}


