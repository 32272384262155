import React, { useEffect, useState, useContext } from 'react';
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { Layer, Source, Popup } from 'react-map-gl';
import { adjustHexBrightness } from "../../features/utils";
import { FillLayer, LineLayer } from 'mapbox-gl';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Col, Nav } from 'react-bootstrap';
import { ActivePopupContext } from '../../ActivePopupContext';

const Hydrobasins = ({ id, global, HydrobasinsLevel, opacity, tileSet, HydrobasinsKind, source, brightness, action }: any) => {
  
  const [popupData, setPopupData] = useState<any>();
  const { currentRef } = useContext<any>(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const ref = currentRef && currentRef?.current;

  const lngLat = action && action.lngLat;
  const navigate = useNavigate();

  const getFeaturesAtPoint = async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0];
    setPopupData(features.properties);
  };

  const goTo = (input: any[], lat: any, lng: any) => {
    setCurrentPoint({ ...input, lat, lng });
    setTimeout(() => navigate('/HydrobasinsReport'), 1000);
  };

  useEffect(() => global.notify('Zoom in to see layer'), []);

  const kind = HydrobasinsKind === "standard"
    ? ["standard", adjustHexBrightness("#dd660d", brightness)]
    : ["custom", adjustHexBrightness("#31aa9a", brightness)];

  const HydrobasinsFill: FillLayer = {
    id,
    type: "fill",
    "source-layer": source,
    minzoom: 6.25,
    paint: {
      "fill-opacity": opacity,
      'fill-color': [
        "match",
        ["get", "kind"],
        ...kind,
        'transparent',
      ],
    },
    filter: ['in', "level", parseInt(HydrobasinsLevel)],
  };

  const HydrobasinsLine: LineLayer = {
    id: `${id}-outline`,
    type: "line",
    "source-layer": source,
    minzoom: 6.25,
    paint: {
      "line-color": "#000000",
      "line-width": 3,
    },
    filter: ['in', "level", parseInt(HydrobasinsLevel)],
  };

  const handleClose = () => {
    setActivePopup({ id: null, content: null });
    setPopupData(undefined);
  };

  useEffect(() => {
    if (popupData && lngLat) {
      const { lat, lng }: any = lngLat;
      const popupElement = (
        <Popup longitude={lng} latitude={lat} onClose={handleClose} style={{ width: '150px' }}>
          <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
          <Col className="col-12 d-flex justify-center flex-column">
            <Col style={{ marginBottom: '4px', justifyContent: 'center' }} className="col-12 d-flex justify-center"></Col>
            <Col style={{ justifyContent: 'center' }} className="col-12 d-flex justify-center">
              <Nav.Link style={{ color: "#4fc2d9", textDecoration: "underline" }} onClick={() => goTo(popupData, lat, lng)}>
                Go to Report
              </Nav.Link>
            </Col>
          </Col>
        </Popup>
      );
      setActivePopup({ id, content: popupElement });
    }
  }, [popupData, lngLat]);

  useEffect(() => {
    if (ref) {
      ref.on('click', (e: any) => getFeaturesAtPoint(ref, e));
    }
  }, [ref]);

  return (
    <>
      <Source id={id} type="vector" url={tileSet}>
        {/* <Layer beforeId={`${id}-outline`} {...HydrobasinsFill} /> */}
        <Layer {...HydrobasinsLine} />
      </Source>
      {activePopup && activePopup.id === id && activePopup.content}
    </>
  );
};

export default Hydrobasins;
