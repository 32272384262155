import { useContext, useState } from "react";
import { RefContext } from "../../../RefContext";
import { CatalogsContext } from "../../../CatalogsProvider";
import { AppContext } from "../../../AppContext";
import { Button, OverlayTrigger, Popover, Tooltip, Dropdown, Form, FloatingLabel } from 'react-bootstrap';
import settings from "../../../settings.json";

export const MCSettings = ({ global, top, reverse }: any) => {
  const { currentRef } = useContext(RefContext);
  const catalogsContext = useContext(CatalogsContext);
  const appContext = useContext(AppContext);

  const [selectedStyle, setSelectedStyle] = useState('True Elements Satellite View'); 

  const mapStyles = ['True Elements Satellite View', 'Satellite - Highways and Bridges', 'Light', 'Dark', 'Outdoors', 'Streets']


  const handleStyle = (event: any) => {
    let newStyle = 'mapbox://styles/trueelementsmapping/cle7ge23j000501nt0u0wjnk5';
    if(event==='Satellite - Highways and Bridges'){
      newStyle = 'mapbox://styles/trueelementsmapping/cln3k2tm206ld01mahzpu91xf';
    } else if(event==='Light'){
      newStyle = 'mapbox://styles/mapbox/light-v11';
    } else if(event==='Dark'){
      newStyle = 'mapbox://styles/mapbox/dark-v11';
    } else if(event==='Outdoors'){
      newStyle = 'mapbox://styles/mapbox/outdoors-v12';
    } else if(event==='Streets'){
      newStyle = 'mapbox://styles/mapbox/streets-v12';
    } else if(event==='Navigation Night'){
      newStyle = 'mapbox://styles/mapbox/navigation-night-v1';
    }
    global.setMapStyle(newStyle)
    setSelectedStyle(event)
    localStorage.setItem('settings_mapstyle', newStyle);
  }  //

  const handleReset = (event: any) => {
    let newStyle = 'mapbox://styles/trueelementsmapping/cle7ge23j000501nt0u0wjnk5';

    global.setMapStyle(newStyle)
    setSelectedStyle(event)
    localStorage.setItem('settings_mapstyle', newStyle);
  }  


  const popover = (
    <Popover id="popover-settings" style={{minWidth: 300}}>
      <Popover.Header as="h3">Map Settings</Popover.Header>
      <Popover.Body>
                  <Form.Group className="mb-3" controlId="floatingActivityType">
                      <FloatingLabel controlId="floatingActivityType" label="Map Style">
                          <Form.Select   onChange={(e: any) => handleStyle(e.target.value)}
                          size="lg" aria-label="Map Style" value={selectedStyle}>
                              {mapStyles  && mapStyles.map((obj:any, index:any) =>
                                  (<option key={'wm-options'+index} value={obj}>{obj}</option>)
                              )} 
                          </Form.Select>
                      </FloatingLabel>
                  </Form.Group>
                  <button onClick={handleReset}  className={"popover-settings-button"} style={{backgroundColor: 'transparent', color: 'white', marginLeft: '70%'}}>
                    <span className="" aria-hidden="true" title="Reset">Reset</span>
                  </button>
      </Popover.Body>
    </Popover>
  );

    return (
        <>           
          <div style={(reverse) ? {position: 'absolute', bottom: top, left: 10} : {position: 'absolute', top: top, left: 10}} className="mapboxgl-ctrl mapboxgl-ctrl-group">
          	<div className="tools-box">
              <OverlayTrigger trigger="click" rootClose placement="right-end" overlay={popover}>
                <button className={"map-gear-button map-button"}>
                  <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Map Settings" />
                </button>
              </OverlayTrigger>
              </div>
          </div>
        </>
    )
}
                        
export default MCSettings