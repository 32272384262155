// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gemstat-popup-main .mapboxgl-popup-content {
  width: 100px !important; }
  .gemstat-popup-main .mapboxgl-popup-content .gemstat-popup-body {
    overflow: auto;
    padding: 2px; }
    .gemstat-popup-main .mapboxgl-popup-content .gemstat-popup-body p {
      font-size: 14px; }
    .gemstat-popup-main .mapboxgl-popup-content .gemstat-popup-body a {
      color: #4fc2d9;
      text-decoration: underline;
      cursor: pointer; }

.gemstat-popup-main .mapboxgl-popup-content > div {
  text-align: center;
  padding: 4px; }
`, "",{"version":3,"sources":["webpack://./src/components/GemstatLayer/gemstat-layer.scss"],"names":[],"mappings":"AAAA;EAEM,uBAAuB,EAAA;EAF7B;IAIQ,cAAc;IACd,YAAY,EAAA;IALpB;MAQU,eAAe,EAAA;IARzB;MAYU,cAAc;MACd,0BAA0B;MAC1B,eAAe,EAAA;;AAdzB;EAoBM,kBAAkB;EAClB,YAAY,EAAA","sourcesContent":[".gemstat-popup-main {\r\n    .mapboxgl-popup-content {\r\n      width: 100px !important;\r\n      .gemstat-popup-body {\r\n        overflow: auto;\r\n        padding: 2px;\r\n        \r\n        p {\r\n          font-size: 14px;\r\n        }\r\n  \r\n        a {\r\n          color: #4fc2d9;\r\n          text-decoration: underline;\r\n          cursor: pointer;\r\n        }\r\n      }\r\n    }\r\n  \r\n    .mapboxgl-popup-content > div {\r\n      text-align: center;\r\n      padding: 4px;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
