import { Accordion, FormCheck } from "react-bootstrap";
import { qualityReferences } from '../shared/qualityReferences';
import { Scrollbars } from 'react-custom-scrollbars-2';
import "./MapLegend.scss";

const MapLegend = ({
  mapLegendData,
  title,
  subtitle,
  legendWidth,
  layerSwitch,
  layerCallback,
  layerValue,
  layerCheckbox,
  pointSwitch,
  pointCallback,
  pointValue,
  pointTitle,
  pointSubtitle,
  pointCheckbox,
  pointCheckboxHide,
  pointLegendData,
  defaultActiveKey,
  htmlPassThrough,
  pointhtmlPassThrough,
  global
}: {
  mapLegendData?: {}[];
  title?: string;
  subtitle?: string;
  legendWidth?: number;
  layerSwitch?: boolean;
  layerCallback?: any;
  layerValue?: boolean;
  layerCheckbox?: string;
  pointSwitch?: boolean;
  pointCallback?: any;
  pointValue?: boolean;
  pointTitle?: string;
  pointSubtitle?: string;
  pointCheckbox?: string;
  pointCheckboxHide?: boolean;
  pointLegendData?: {}[];
  defaultActiveKey?: string;
  htmlPassThrough?: any;
  pointhtmlPassThrough?: any;
  global?: any;
}) => {

  const definedWidth = legendWidth || 220;

  const renderThumb = (style: any) => {
    const thumbStyle = {
      backgroundColor: 'white'
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
      />
    );
  }

  return (
    <>
        <Accordion id="mapLegendAccordion" className="te-map-legend-accordion te-map-legend-class" defaultActiveKey={defaultActiveKey || "0"} >
          {mapLegendData && (<Accordion.Item className="te-map-legend-accordion" eventKey="0">
            <div className="te-map-legend" style={{ width: legendWidth }}>
              <Accordion.Header className="te-map-legend-accordion-header">{title}</Accordion.Header>
              <Accordion.Body className="te-map-legend-accordion-body">

                {layerSwitch && (
                  <FormCheck
                    onChange={() => layerCallback(!layerValue)}
                    label={layerCheckbox || "Show"}
                    checked={layerValue}
                  />)}
                {subtitle && (<p>{subtitle}</p>)}
                {mapLegendData.map((item: any) => (
                  <div key={'te-map-legend-dot-container' + item.color + (Math.random() * 10)}>
                    <div className="te-map-legend-dot" style={{ backgroundColor: item.color }}></div>
                    <span>{item.typecode}</span>
                  </div>
                ))}

                {htmlPassThrough && (htmlPassThrough)}
              </Accordion.Body>
            </div>
          </Accordion.Item>
        )}

        {pointLegendData && (<Accordion.Item className="te-map-legend-accordion" eventKey="1" style={(pointSwitch) ? { display: 'inline-block' } : { display: 'none' }}>
            <div className="te-map-legend" style={{ width: legendWidth }}>
              <Accordion.Header className="te-map-legend-accordion-header">{pointTitle}</Accordion.Header>
              <Accordion.Body className="te-map-legend-accordion-body">

                <FormCheck
                  onChange={() => pointCallback(!pointValue)}
                  label={pointCheckbox || 'Show'}
                  checked={pointValue}
                  style={(pointCheckboxHide) ? { display: 'none' } : { display: 'inline-block' }}
                />
                {pointSubtitle && (<p>{pointSubtitle}</p>)}
                {pointLegendData && pointLegendData.map((item: any) => (
                  <div key={'te-map-legend-dot-container' + item.color + (Math.random() * 10)}>
                    <div className="te-map-legend-dot" style={{ backgroundColor: item.color }}></div>
                    <span>{item.typecode}</span>
                  </div>
                ))}
                {pointhtmlPassThrough && (pointhtmlPassThrough)}
              </Accordion.Body>
            </div>
          </Accordion.Item>
        )}

        {(global.nhdSelected
          || global.wrfcRisk
          || global.eddi
          || global.NOAAMix
          || global.nwshazard
          || global.waterQualityPortal
          || global.phFloodHazard
          || global.ausGemstat
          || global.gemstatPH
          || global.philRegions
          || global.ausPrinHydro
          || global.indoWatersheds
          || global.indoHucs
          || global.gemstat
          || global.biaLayer
          || global.ANGISLower
          || global.ANGISMid
          || global.persistentContaminants
          || global.modPersistentContaminants
          || global.volPersistentContaminants
          || global.hucsSelected
          || global.outflowsSelected
          || global.industrySelected
          || global.h303DTrueQI
          || global.pollutionSelected
          || global.wastewaterSelected
          || global.domesticWellsSelected
          || global.principalAquifersSelected
          || global.droughtMonitoringSelected
          || global.gwNitrateAssessmentSelected
          || global.terraClimateLayerSelected
          || global.evapotranspirationSelected
          || global.EJDemoSelected
          || global.EJLowIncomeSelected
          || global.EJPOCSelected
          || global.EJUnemploymentSelected
          || global.EJLingIsoSelected
          || global.EJLessHSSelected
          || global.EJFiveAndUnderAgeSelected
          || global.EJSixtyFourAndOverSelected
          || global.HUCsMexico
          || global.EJHazardousWaste
          || global.EJWasteWaterDischarge
          || global.EJRMP
          || global.EJUndergroundStorageTanks
          || global.EJSuperfund
          || global.waterSystemBoundaryTier1
          || global.waterSystemBoundaryTier2
          || global.waterSystemBoundaryTier3
          || global.aqueductAtlasCoastalFlood
          || global.aqueductAtlasWaterStress
          || global.aqueductAtlasRiverine
          || global.AAV4RiverineFloodRisk
          || global.femaBoundaries
          || global.femaZones
          || global.drinkingWaterQualitySelected
          || global.wastewaterSelected
          || global.industrySelected
          || global.allFireData
          || global.NOAALayer
          || global.rainfallForecast
          || global.NOAASeaLevelRise
          || global.EPASuperfundSites
          || global.AAV4CoastalFloodRisk
          || global.persistentContaminants
          || global.modPersistentContaminants
          || global.volPersistentContaminants
          || global.EPOLayer
          || global.toggleHighwayStyle
          || global.waterWatchSites
          || global.impairedLakes
          || global.WDPA
          || global.AAV4WaterStress
          || global.wildFireBoundaries
          || global.cvi
          || global.MoBiPSR
          || global.MoBiSRAll
          || global.MoBiPI
          || global.MoBiV
          || global.GBIF
          || global.OABID
          || global.OABUS
          || global.OGWTD
          || global.OABRPH
          || global.OABRID
          || global.OABPH
          || global.OABMX
          || global.OABRPK
          || global.OABRMX
          || global.OABRCA
          || global.OABRSG
          || global.OABSPH
          || global.OABSID
          || global.OABSMX
          || global.OABSCA
          || global.OABSPK
          || global.USGSHuc12
          || global.OABSSG
          || global.AppleLocationData
          || global.USGSWUC
          || global.NationalWaterImpairements
          || global.MoBiSRA
          || global.WaterConflict
          || global.CEJST
          || global.SkyFiWhiting
          || global.CWALayer
          || global.FHBP
          || global.OWQPDemo
          || global.FBFIB
          || global.SkyFiAustin
          || global.MBFIBDT
          || global.NSG
          || global.MEC
          || global.GGDE
          || global.HBA
          || global.Wise
          || global.MECReportMounted
          || global.FIBReportMounted
          || global.drinkingWaterQualitySelected) && (
            <Accordion.Item className="te-map-legend-accordion" eventKey="2">
              <div className="te-map-legend" style={{ width: legendWidth }}>
                <Accordion.Header className="te-map-legend-accordion-header">{'Global Live Water Layers'}</Accordion.Header>
                <Accordion.Body className="te-map-legend-accordion-body">
                  <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: definedWidth - 21, height: '24vh' }}>
                    {global.drinkingWaterQualitySelected
                      || global.wastewaterSelected
                      || global.h303DTrueQI
                      || global.industrySelected ? qualityReferences("water quality", global) : ''}
                    {global.NationalWaterImpairements ? qualityReferences("NationalWaterImpairements", global) : ''}
                    {global.GGDE ? qualityReferences("GGDE", global) : ''}
                    {global.Wise ? qualityReferences("Wise", global) : ''}
                    {global.HBA ? qualityReferences("HBA", global) : ''}
                    {global.FIBReportMounted ? qualityReferences("FIBReportMounted", global) : ''}
                    {global.MECReportMounted ? qualityReferences("MECReportMounted", global) : ''}
                    {global.NSG ? qualityReferences("NSG", global) : ''}
                    {global.MEC ? qualityReferences("MEC", global) : ''}
                    {global.MBFIBDT ? qualityReferences("MBFIBDT", global) : ''}
                    {global.CWALayer ? qualityReferences("CWALayer", global) : ''}
                    {global.FHBP ? qualityReferences("FHBP", global) : ''}
                    {global.OWQPDemo ? qualityReferences("OWQPDemo", global) : ''}
                    {global.FBFIB ? qualityReferences("FBFIB report", global) : ''}
                    {global.USGSWUC ? qualityReferences("USGSWUC", global) : ''}
                    {global.CEJST ? qualityReferences("CEJST", global) : ''}
                    {global.USGSHuc12 ? qualityReferences("USGSHuc12", global) : ''}
                    {global.AppleLocationData ? qualityReferences("apple", global) : ''}
                    {global.SkyFiWhiting ? qualityReferences("SkyFiWhiting", global) : ''}
                    {global.SkyFiAustin ? qualityReferences("SkyFiAustin", global) : ''}
                    {global.OABSCA ? qualityReferences("OABSCA", global) : ''}
                    {global.OABSSG ? qualityReferences("OABSSG", global) : ''}
                    {global.OABSPK ? qualityReferences("OABSPK", global) : ''}
                    {global.OABSPH ? qualityReferences("OABSPH", global) : ''}
                    {global.OABSID ? qualityReferences("OABSID", global) : ''}
                    {global.OABSMX ? qualityReferences("OABSMX", global) : ''}
                    {global.OABRPK ? qualityReferences("OABRPK", global) : ''}
                    {global.OABRSG ? qualityReferences("OABRSG", global) : ''}
                    {global.OABRMX ? qualityReferences("OABRMX", global) : ''}
                    {global.OABRCA ? qualityReferences("OABRCA", global) : ''}
                    {global.OABRID ? qualityReferences("OABRID", global) : ''}
                    {global.OABRPH ? qualityReferences("OABRPH", global) : ''}
                    {global.OGWTD ? qualityReferences("OGWTD", global) : ''}
                    {global.OABID ? qualityReferences("OABID", global) : ''}
                    {global.OABUS ? qualityReferences("OABUS", global) : ''}
                    {global.OABPH ? qualityReferences("OABPH", global) : ''}
                    {global.OABMX ? qualityReferences("OABMX", global) : ''}
                    {global.WaterConflict ? qualityReferences("WaterConflict", global) : ''}
                    {global.MoBiPSR ? qualityReferences("MoBiPSR", global) : ''}
                    {global.GBIF ? qualityReferences("GBIF", global) : ''}
                    {global.MoBiV ? qualityReferences("MoBiV", global) : ''}
                    {global.MoBiPI ? qualityReferences("MoBiPI", global) : ''}
                    {global.MoBiSRAll ? qualityReferences("MoBiSRAll", global) : ''}
                    {global.MoBiSRA ? qualityReferences("MoBiSRA", global) : ''}
                    {global.persistentContaminants ? qualityReferences("contam", global) : ''}
                    {global.cvi ? qualityReferences("cvi", global) : ''}
                    {global.indoWatersheds ? qualityReferences("indoWatersheds", global) : ''}
                    {global.indoHucs ? qualityReferences("indoHucs", global) : ''}
                    {global.WDPA ? qualityReferences("WDPA", global) : ''}
                    {global.toggleHighwayStyle ? qualityReferences("toggleHighwayStyle", global) : ''}
                    {global.EPOLayer ? qualityReferences("EPOLayer", global) : ''}
                    {global.impairedLakes ? qualityReferences("impairedLakes", global) : ''}
                    {global.wildFireBoundaries ? qualityReferences("wildFireBoundaries", global) : ''}
                    {global.rainfallForecast ? qualityReferences("rainfallForecast", global) : ''}
                    {global.waterWatchSites ? qualityReferences("waterWatchSites", global) : ''}
                    {global.modPersistentContaminants ? qualityReferences("contam", global) : ''}
                    {global.EPASuperfundSites ? qualityReferences("EPASuperfundSites", global) : ''}
                    {global.NOAASeaLevelRise ? qualityReferences("NOAA", global) : ''}
                    {global.persistentContaminants ? qualityReferences("contam", global) : ''}
                    {global.modPersistentContaminants ? qualityReferences("contam", global) : ''}
                    {global.volPersistentContaminants ? qualityReferences("contam", global) : ''}
                    {global.ANGISLower
                      || global.ANGISMid
                      || global.ANGISUpper ? qualityReferences("ANGIS", global) : ''}
                    {global.evapotranspirationSelected ? qualityReferences("evapotranspiration", global) : ''}
                    {global.wrfcRisk ? qualityReferences("wrfcRisk", global) : ''}
                    {global.allFireData ? qualityReferences("allFireData", global) : ''}
                    {global.eddi ? qualityReferences("eddi", global) : ''}
                    {global.NOAAMix ? qualityReferences("NOAAMix", global) : ''}
                    {global.eddi ? qualityReferences("nwshazard", global) : ''}
                    {global.waterQualityPortal ? qualityReferences("waterQualityPortal", global) : ''}
                    {global.phFloodHazard ? qualityReferences("phFloodHazard", global) : ''}
                    {global.philRegions ? qualityReferences("philRegions", global) : ''}
                    {global.gemstat | global.ausGemstat | global.gemstatPH ? qualityReferences("gemstat", global) : ''}
                    {global.ABWDLayer ? qualityReferences("ABWDLayer", global) : ''}
                    {global.biaLayer ? qualityReferences("biaLayer", global) : ''}
                    {global.ausPrinHydro ? qualityReferences("ausPrinHydro", global) : ''}
                    {global.philippinesRiverBasins ? qualityReferences("PHBasins", global) : ''}
                    {global.philippinesRivers ? qualityReferences("PHRivers", global) : ''}
                    {global.evapotranspirationSelected ? qualityReferences("evapotranspiration", global) : ''}
                    {global.terraClimateLayerSelected ? qualityReferences("terraclimate", global) : ''}
                    {global.gwNitrateAssessmentSelected ? qualityReferences("nitrates", global) : ''}
                    {global.NOAALayer ? qualityReferences("NOAALayer", global) : ''}
                    {global.principalAquifersSelected ? qualityReferences("aquifers", global) : ''}
                    {global.droughtMonitoringSelected ? qualityReferences("drought", global) : ''}
                    {global.domesticWellsSelected ? qualityReferences("wells", global) : ''}
                    {global.pollutionSelected ? qualityReferences("pollution", global) : ''}
                    {global.outflowsSelected ? qualityReferences("outflows", global) : ''}
                    {global.hucsSelected ? qualityReferences("hucs", global) : ''}
                    {global.nhdSelected ? qualityReferences("nhd", global) : ''}
                    {global.aqueductAtlasCoastalFlood ? qualityReferences("aqueductAtlasCoastalFlood", global) : ''}
                    {global.aqueductAtlasWaterStress ? qualityReferences("aqueductAtlasWaterStress", global) : ''}
                    {global.AAV4CoastalFloodRisk ? qualityReferences("AAV4CoastalFloodRisk", global) : ''}
                    {global.AAV4WaterStress ? qualityReferences("AAV4WaterStress", global) : ''}
                    {global.AAV4RiverineFloodRisk ? qualityReferences("AAV4RiverineFloodRisk", global) : ''}
                    {global.aqueductAtlasRiverine ? qualityReferences("aqueductAtlasRiverine", global) : ''}
                    {global.EJDemoSelected ? qualityReferences("EJDemoSelected", global) : ''}
                    {global.EJLowIncomeSelected ? qualityReferences("EJLowIncomeSelected", global) : ''}
                    {global.EJPOCSelected ? qualityReferences("EJPOCSelected", global) : ''}
                    {global.EJUnemploymentSelected ? qualityReferences("EJUnemploymentSelected", global) : ''}
                    {global.EJLingIsoSelected ? qualityReferences("EJLingIsoSelected", global) : ''}
                    {global.EJLessHSSelected ? qualityReferences("EJLessHSSelected", global) : ''}
                    {global.EJFiveAndUnderAgeSelected ? qualityReferences("EJFiveAndUnderAgeSelected", global) : ''}
                    {global.EJSixtyFourAndOverSelected ? qualityReferences("EJSixtyFourAndOverSelected", global) : ''}
                    {global.HUCsMexico ? qualityReferences("HUCsMexico", global) : ''}
                    {global.femaBoundaries ? qualityReferences("femaBoundaries", global) : ''}
                    {global.EJWasteWaterDischarge ? qualityReferences("EJWasteWaterDischarge", global) : ''}
                    {global.EJUndergroundStorageTanks ? qualityReferences("EJUndergroundStorageTanks", global) : ''}
                    {global.EJHazardousWaste ? qualityReferences("EJHazardousWaste", global) : ''}
                    {global.EJRMP ? qualityReferences("EJRMP", global) : ''}
                    {global.EJSuperfund ? qualityReferences("EJSuperfund", global) : ''}
                    {global.femaZones ? qualityReferences("femaZones", global) : ''}
                    {global.waterSystemBoundaryTier1 ||
                      global.waterSystemBoundaryTier2 ||
                      global.waterSystemBoundaryTier3 ? qualityReferences("Water Service Boundary", global) : ''}
                  </Scrollbars>
                </Accordion.Body>
              </div>
            </Accordion.Item>)}
      </Accordion>
    </>
  );
};

export default MapLegend;